import { Box, Input, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

const PromptInput = forwardRef((props: InputProps, ref) => (
  <Box padding="18px 0 20px 0">
    <Input
      bg="#F5F5F5"
      border="none"
      borderRadius="11px"
      h="41px"
      ref={ref}
      spellCheck="true"
      {...props}
    />
  </Box>
));

export default PromptInput;
