import { Button } from '@chakra-ui/react';

const ButtonAddListItem = (props) => (
  <Button
    bg="rgba(222, 222, 222, 0.08)"
    border="1px solid #E0E5F2"
    borderRadius="20px"
    color="#63719F"
    fontSize="sm"
    fontWeight={500}
    {...props}
  />
);

export default ButtonAddListItem;
