import { useHistory } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';

import SignUpForm from './SignUpForm';

export default function SignUpPage() {
  const history = useHistory();

  return (
    <AuthContainer title="Create an account" subtitle="Start your journey!">
      <SignUpForm
        onGoToSignIn={() => history.push('/auth/signin')}
        onSignedIn={() => {
          window.location.href = '/';
        }}
      />
    </AuthContainer>
  );
}
