import Button from '../../../components/Button';
import { IconHeartEmpty } from '../../../components/icons/IconHeartEmpty';
import { IconHeartRed } from '../../../components/icons/IconHeartRed';
import { Favorite } from '@/lib';

type FavoriteButtonProps = {
  favorites: Favorite[];
  imageId: string;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (id: string) => void;
};

const FavoriteButton = ({
  favorites,
  imageId,
  addFavorite,
  removeFavorite,
}: FavoriteButtonProps) => {
  const isFavorite = (imageId: string) => {
    return favorites?.some((favorite) => favorite.imageId === imageId);
  };

  const onFavorite = (imageId: string) => {
    if (isFavorite(imageId)) {
      removeFavorite(favorites?.find((favorite) => favorite.imageId === imageId).id);
    } else {
      addFavorite({ imageId });
    }
  };

  const isFav = isFavorite(imageId);

  return (
    favorites && (
      <Button
        bg={isFav ? '#FFF2F2' : 'transparent'}
        icon={
          isFav ? (
            <IconHeartRed height="24px" width="24px" mr="-6px" />
          ) : (
            <IconHeartEmpty height="24px" width="24px" mr="-6px" />
          )
        }
        onClick={() => onFavorite(imageId)}
        variant="outlined"
        title=""
      ></Button>
    )
  );
};

export default FavoriteButton;
