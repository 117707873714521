import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Box, VStack, useToast } from '@chakra-ui/react';

import {
  createTemplate,
  createTemplateColorPreviewImage,
  getTemplate,
  updateTemplate,
} from '@/api/templates';
import { TemplateColor, TemplateSide, Template } from '@/components/types';

import StepsProgressBar from './components/StepsProgressBar';
import GeneralInformation from './steps/GeneralInformation';
import TemplateSides from './steps/TemplateSides';
import DesignArea from './steps/DesignArea';
import DesignElements from './steps/DesignElements';
import ColorVariants from './steps/ColorVariants';
import PreviewImages from './steps/PreviewImages';
import Preview from './steps/Preview';

import STEPS from './steps/CreateTemplateSteps';

import { getTemplateWithImagesForSides } from './utils';
import { isArray, isEmpty } from 'lodash';
import CreateTemplateStep from './steps/CreateTemplateStep';

const {
  GENERAL_INFORMATION,
  SIDES,
  COLOR_VARIANTS,
  DESIGN_AREA,
  DESIGN_ELEMENTS,
  PREVIEW_IMAGES,
  PREVIEW,
} = CreateTemplateStep;

const CreateTemplate = () => {
  const [activeStep, setActiveStep] = useState<CreateTemplateStep>(GENERAL_INFORMATION);

  const [templateInfo, setTemplateInfo] = useState<Partial<Template>>({});
  const [sides, setSides] = useState<TemplateSide[]>([]);
  const [colorVariants, setColorVariants] = useState<TemplateColor[]>([]);

  const [waiting, setWaiting] = useState(false);

  const history = useHistory();

  const toast = useToast();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      getTemplate(id)
        .then((template) => {
          const { sides, colors = [], ...rest } = getTemplateWithImagesForSides(template);

          setTemplateInfo({ ...rest });
          setSides(sides);
          setColorVariants(colors);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast({
              title: 'Template does not exist',
              description: 'Perhaps it was deleted?',
              status: 'error',
            });

            history.push('/template-library');
          }
        });
    }
  }, [id]);

  const handleGeneralInformationUpdate = (updates) => {
    setActiveStep(SIDES);

    setTemplateInfo(updates);
  };

  const handleSidesUpdate = (sides) => {
    setSides(sides);
    setActiveStep(COLOR_VARIANTS);
  };

  const handleColorVariantsUpdate = (updates) => {
    setColorVariants(updates);

    setActiveStep(DESIGN_AREA);
  };

  const handleInitialCanvasStateUpdate = (templateSideId: string, initialCanvasState: string) => {
    const newSides = sides.map((side) => {
      if (templateSideId !== (side.id || side.name)) {
        return side;
      }

      return {
        ...side,
        initialCanvasState,
      };
    });

    setSides(newSides);
  };

  const handleSave = () => {
    const { ...rest } = templateInfo;

    const params: Template = {
      ...rest,
      sides,
      colors: colorVariants,
    };

    const promise = templateInfo.id ? updateTemplate : createTemplate;

    setWaiting(true);

    promise(params)
      .then(({ id, colors }) =>
        Promise.all(
          colors.map((color, index) => {
            const { previewImages = [] } = colorVariants[index];

            return previewImages.map((image) =>
              // If we have a template id, we don't need to create any new images
              // since they are already created
              templateInfo.id ? () => {} : createTemplateColorPreviewImage(id, color.id, image)
            );
          })
        )
      )
      .then(() => history.push('/template-library'))
      .catch((e) => {
        const { message } = e.response?.data || {};

        const errorMessage = isArray(message) ? message.join(', ') : message;

        console.error(errorMessage);
        console.error(e);

        toast({
          title: 'Error saving template',
          description: errorMessage,
          status: 'error',
        });
      })
      .finally(() => setWaiting(false));
  };

  return (
    <Box>
      <VStack
        position="relative"
        px={activeStep === DESIGN_ELEMENTS ? 0 : '45px'}
        pt="78px"
        width="100%"
      >
        <Box
          bg="linear-gradient(180deg, #064AC4 0%, #00338F 100%)"
          borderRadius="19px"
          height="530px"
          position="absolute"
          top={0}
          w="100%"
        />
        <StepsProgressBar
          activeStep={activeStep}
          onStepClick={(step) => setActiveStep(step)}
          steps={STEPS}
        />
        <Box mb="10px" />
        {activeStep === GENERAL_INFORMATION && (
          <GeneralInformation
            onNext={handleGeneralInformationUpdate}
            onUpdate={handleGeneralInformationUpdate}
            templateInfo={templateInfo}
          />
        )}
        {activeStep === SIDES && (
          <TemplateSides
            onNext={handleSidesUpdate}
            onUpdate={setSides}
            sides={sides}
            template={templateInfo}
          />
        )}
        {activeStep === COLOR_VARIANTS && (
          <ColorVariants
            onNext={handleColorVariantsUpdate}
            onUpdate={setColorVariants}
            sides={sides}
            variants={colorVariants}
            template={templateInfo}
          />
        )}
        {activeStep === DESIGN_AREA && (
          <DesignArea
            onNext={handleSidesUpdate}
            onUpdate={setSides}
            templateColors={colorVariants}
            templateSides={sides}
            template={templateInfo}
          />
        )}
        {activeStep === DESIGN_ELEMENTS && !isEmpty(sides) && (
          <DesignElements
            onNext={() => setActiveStep(PREVIEW_IMAGES)}
            onUpdate={handleInitialCanvasStateUpdate}
            template={{
              ...templateInfo,
              colors: colorVariants,
              sides: sides.map((side) => ({
                ...side,
                id: side.id || side.name,
              })),
            }}
          />
        )}
        {activeStep === PREVIEW_IMAGES && (
          <PreviewImages
            onNext={() => setActiveStep(PREVIEW)}
            onUpdate={(newColors) => setColorVariants(newColors)}
            template={{ ...templateInfo, colors: colorVariants }}
          />
        )}
        {activeStep === PREVIEW && (
          <Preview
            onNext={handleSave}
            colorVariants={colorVariants}
            sides={sides}
            template={templateInfo}
            waiting={waiting}
          />
        )}
      </VStack>
    </Box>
  );
};

export default CreateTemplate;
