import { DesignSide } from '@/lib';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';

const THUMBNAIL_SIZE = 82;

interface SidePickerProps {
  onSelectedSide: (resolution: string) => void;
  selectedSide: string;
  sides: DesignSide[];
}

const SidePicker = ({ onSelectedSide, selectedSide, sides }: SidePickerProps) => (
  <HStack mb="10px" spacing="10px">
    {sides.map((side, index) => {
      const { id, designImage: imageUrl, templateSide } = side;

      const isSelected = selectedSide === id;

      return (
        <VStack as="button" key={index} onClick={() => onSelectedSide(id)} spacing="4px">
          <Image
            src={imageUrl}
            border={isSelected ? '1px solid' : 'none'}
            borderColor="brand.600"
            borderRadius="14px"
            id={`image-${side.id}`}
            width={`${THUMBNAIL_SIZE}px`}
          />
          <Text as="b" color="#2D3748" fontSize="10px">
            {templateSide.name}
          </Text>
        </VStack>
      );
    })}
  </HStack>
);

export default SidePicker;
