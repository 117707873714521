import { Icon } from '@chakra-ui/react';

const IconPerson = () => (
  <Icon
    width="25px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5454 5.15391C10.4001 5.15391 8.66101 6.89301 8.66101 9.0383C8.66101 11.1836 10.4001 12.9227 12.5454 12.9227C14.6907 12.9227 16.4298 11.1836 16.4298 9.0383C16.4298 6.89301 14.6907 5.15391 12.5454 5.15391ZM10.461 9.0383C10.461 7.88712 11.3942 6.95391 12.5454 6.95391C13.6966 6.95391 14.6298 7.88712 14.6298 9.0383C14.6298 10.1895 13.6966 11.1227 12.5454 11.1227C11.3942 11.1227 10.461 10.1895 10.461 9.0383Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.5C6.70101 1.5 2 6.20101 2 12C2 17.799 6.70101 22.5 12.5 22.5C18.299 22.5 23 17.799 23 12C23 6.20101 18.299 1.5 12.5 1.5ZM3.8 12C3.8 7.19512 7.69512 3.3 12.5 3.3C17.3049 3.3 21.2 7.19512 21.2 12C21.2 14.4486 20.1884 16.661 18.5604 18.2419C17.8232 15.5939 15.3958 13.6532 12.5113 13.6532C9.62056 13.6532 7.18885 15.6023 6.45746 18.2592C4.81897 16.6771 3.8 14.4575 3.8 12ZM8.05502 19.4803C9.3559 20.255 10.876 20.7 12.5 20.7C14.1329 20.7 15.6607 20.2501 16.9663 19.4676C16.7345 17.2103 14.8305 15.4532 12.5113 15.4532C10.1878 15.4532 8.28101 17.2169 8.05502 19.4803Z"
      fill="black"
    />
  </Icon>
);

export default IconPerson;
