import { Button, HStack, VStack, Text, FormLabel } from '@chakra-ui/react';

import { Size } from '@/components/types';
import FormInput from '@/components/form/FormInput';
import { useState } from 'react';
import IconChevronDown from '@/components/icons/IconChevronDown';
import { TemplateColorSize } from '@/lib';

const buttonActiveStyle = {
  boxShadow: 'none',
};

type SizeVariantPickerProps = {
  allSizes: Size[];
  selectedValues: TemplateColorSize[];
  onUpdateSelectedValues: (values: TemplateColorSize[]) => void;
};

const SizeVariantPicker = ({
  allSizes,
  selectedValues = [],
  onUpdateSelectedValues,
}: SizeVariantPickerProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSelected = (sizeId: number) => {
    let newSelected;

    const colorSize = selectedValues.find((val) => val.sizeId === sizeId);
    if (colorSize) {
      newSelected = selectedValues.filter((val) => val !== colorSize);
    } else {
      const newSize = {
        sizeId,
        manufacturingVariantId: '',
        storefrontVariantId: '',
      };
      newSelected = [...selectedValues, newSize];
    }

    onUpdateSelectedValues(newSelected);
  };

  return (
    <VStack align="start" mt={4}>
      <FormLabel onClick={() => setCollapsed(!collapsed)}>
        Available Sizes <IconChevronDown />
      </FormLabel>
      <VStack align="start" display={collapsed ? 'none' : 'flex'}>
        <HStack>
          <Text fontSize="sm" fontWeight={500} w="40px">
            Size
          </Text>
          <Text fontSize="sm" fontWeight={500} w="200px">
            Manufacturing Id
          </Text>
          <Text fontSize="sm" fontWeight={500} w="200px">
            Storefront Id
          </Text>
        </HStack>
        {allSizes.map(({ id, name }) => {
          const colorSize = selectedValues.find((val) => val.sizeId === id);
          const isSelected = !!colorSize;
          const manufacturingVariantId = colorSize?.manufacturingVariantId || '';
          const storefrontVariantId = colorSize?.storefrontVariantId || '';

          return (
            <HStack key={id}>
              <Button
                bg={isSelected ? 'brand.500' : 'transparent'}
                borderColor={isSelected ? 'brand.500' : 'secondaryGray.500'}
                borderRadius="16px"
                borderWidth="1px"
                color={isSelected ? '#FFFFFF' : 'black'}
                fontSize="sm"
                fontWeight={500}
                h="40px"
                w="40px"
                p="18px 12px"
                textAlign="center"
                _hover={buttonActiveStyle}
                _focus={buttonActiveStyle}
                _active={buttonActiveStyle}
                onClick={() => toggleSelected(id)}
              >
                {name}
              </Button>
              <FormInput
                h="40px"
                w="200px"
                placeholder="Printful Id..."
                isDisabled={!isSelected}
                value={manufacturingVariantId}
                onChange={(e) => {
                  const newSelected = selectedValues.map((val) => {
                    if (val.sizeId === id) {
                      return {
                        ...val,
                        manufacturingVariantId: e.target.value,
                      };
                    }
                    return val;
                  });
                  onUpdateSelectedValues(newSelected);
                }}
              />
              <FormInput
                h="40px"
                w="200px"
                placeholder="Shopify Id..."
                isDisabled={!isSelected}
                value={storefrontVariantId}
                onChange={(e) => {
                  const newSelected = selectedValues.map((val) => {
                    if (val.sizeId === id) {
                      return {
                        ...val,
                        storefrontVariantId: e.target.value,
                      };
                    }
                    return val;
                  });
                  onUpdateSelectedValues(newSelected);
                }}
              />
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default SizeVariantPicker;
