import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import '@fontsource/inter/400.css'; // Defaults to weight 400
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

export const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Inter', sans-serif`,
};
