import {
  Box,
  Center,
  Hide,
  Stack,
  Show,
  HStack,
  IconButton,
  Text,
  Button,
  Image,
} from '@chakra-ui/react';

import IconBack from '@/lib/components/icons/IconBack';

import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import StoryBadge from '@/assets/StoryBadge.png';

import AppContainer from '@/layouts/AppContainer';
import { useHistory, useLocation } from 'react-router-dom';

import SubmitRemixForm from '../../components/remix/SubmitRemixForm';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { H3, H4 } from '@/components/typography/Headings';

import IconExternalLink from '@/components/icons/IconExternalLink';
import SubmitDesignPreviewGallery from './components/SubmitDesignPreviewGallery';
import IconChevronDownBreadCrumb from '@/components/icons/IconChevronBreadCrumb';
import IpPublishedModal from './components/IpPublishedModal';
import StoryIcon from './icons/StoryIcon';

const TITLE = 'Publish your design';

export default function SubmitRemix() {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const [isIpPublishedModalVisible, setIsIpPublishedModalVisible] = useState(false);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const designId = searchParams.get('designId');

  const history = useHistory();

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  const handleGoBackToDesignStudio = () => history.push(`/designs/${design.id}`);

  const handleSubmitted = (updates: Partial<Design>) => {
    if (updates) {
      setDesign({ ...design, ...updates });
    }

    setIsIpPublishedModalVisible(true);
  };

  return (
    <AppContainer contentContainerProps={{ p: 0 }}>
      {isLoading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Stack h="100%" direction={{ base: 'column', md: 'row' }} spacing={0}>
          <Box
            bg="#FFFFFF"
            borderRight={{ base: 'none', md: '1px solid #E2E8F0' }}
            h="100%"
            overflow="auto"
            p={{ base: '12px 16px 80px 16px', md: '24px 32px' }}
            pb={{ base: '60px', md: 0 }}
            w={{ base: '100%', md: 404 }}
          >
            <HStack gap="4px" mb={{ base: '11px', md: '27px' }}>
              <Text color="brand.600" onClick={handleGoBackToDesignStudio} textStyle="breadcrumb">
                Design Studio
              </Text>
              <IconChevronDownBreadCrumb />
              <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
                Review
              </Text>
            </HStack>
            <Hide above="md">
              <SubmitDesignPreviewGallery design={design} />
              <H3 mb="6px" mt="20px">
                {TITLE}
              </H3>
            </Hide>
            <Show above="md">
              <HStack gap="18px" mb="12px">
                <IconButton
                  aria-label="back"
                  size="xs"
                  icon={<IconBack />}
                  onClick={() => history.push(`/designs/${design.id}`)}
                  variant="icon"
                >
                  <IconBack />
                </IconButton>
                <H3>{TITLE}</H3>
              </HStack>
            </Show>
            <SubmitRemixForm design={design} onSubmitted={handleSubmitted} />
            <H4 mb="8px" mt="16px">
              How it works
            </H4>
            <Text color="secondaryDarkGray.600" mb="16px" textStyle="bodySmall">
              Publish your merch design with one click (free) and start accruing 15% in royalties
              per sale, payable in the near future. Earn an exclusive Story Badge with your first
              sale!
            </Text>
            <HStack alignSelf="flex-start" mb="16px" spacing="3px">
              <Text as="b" textTransform="uppercase" fontSize="xs">
                Powered by
              </Text>
              <StoryIcon />
            </HStack>
            <Center bg="#F0F5FF" borderRadius="8px" h="120px" mb="12px" w="100%">
              <Image src={StoryBadge} h="96px" w="94px" />
            </Center>
            <Button
              mb={{ base: '12px', md: 0 }}
              pl={0}
              rightIcon={<IconExternalLink />}
              onClick={() => window.open('https://learn.story.foundation', '_blank')}
              variant="link"
            >
              More details
            </Button>
          </Box>
          <Show above="md">
            <Center flex={1}>
              <SubmitDesignPreviewGallery design={design} />
            </Center>
          </Show>
          {isIpPublishedModalVisible ? (
            <IpPublishedModal design={design} onClose={() => setIsIpPublishedModalVisible(false)} />
          ) : null}
        </Stack>
      )}
    </AppContainer>
  );
}
