import { Template } from '@/components/types';

import { Box, Heading, HStack, Image, VStack } from '@chakra-ui/react';
import CollectionStatBox from './CollectionStatBox';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Brands from './Brands';
import ShowMore from '@/components/ui/show-more/ShowMore';

const BORDER_RADIUS = 12;

const getStats = (template: Template) => [
  {
    name: 'Total designs',
    value: template.numRemixes,
  },
];

interface CollectionCardProps {
  template: Template;
}

const CollectionCard = ({ template }: CollectionCardProps) => {
  const [isHover, setIsHover] = useState(false);

  const history = useHistory();

  const { brands, description, name, previewImageUrl } = template;

  const handleViewProducts = () => history.push(`/collection/${template.id}`);

  return (
    <Box
      border="1px solid"
      borderColor={isHover ? 'brand.600' : 'borderSecondary'}
      borderRadius={BORDER_RADIUS}
      color="#000000"
      cursor="pointer"
      flex={1}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleViewProducts}
      overflow="hidden"
    >
      <Box bg="secondaryLightGray" position="relative">
        <Image
          borderTopLeftRadius={BORDER_RADIUS}
          borderTopRightRadius={BORDER_RADIUS}
          objectFit="cover"
          src={previewImageUrl}
          alt="Collection image"
          h={{ base: 196, md: 297 }}
          w="100%"
        />
        {isHover ? (
          <Box bg="rgba(6, 74, 196, 0.20)" position="absolute" left={0} top={0} h="100%" w="100%" />
        ) : null}
      </Box>
      <VStack
        align="flex-start"
        padding={{ base: '9px 16px 16px 16px', md: '12px 36px 24px 24px' }}
        spacing={0}
      >
        <Brands brands={brands} height={{ base: '38px', md: '48px' }} isDark />
        <Heading fontSize="24px" mb={{ base: '6px', md: '5px' }} mt="14px">
          {name}
        </Heading>
        <ShowMore>{description}</ShowMore>
        <HStack mt="19px" spacing="12px">
          {getStats(template).map(({ name, value }, index) => (
            <CollectionStatBox title={name} value={value} key={index} />
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

export default CollectionCard;
