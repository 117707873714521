import { AnalyticsBrowser } from '@segment/analytics-next';
import { Event } from './events';
import Config from '../config';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { capitalize } from 'lodash';

const analytics = AnalyticsBrowser.load({ writeKey: Config.SEGMENT_WRITE_KEY });

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    const pageName = capitalize(location.pathname.split('/')[1]);

    analytics.page('Ablo Page', pageName || 'Home');
  }, [location.pathname]);
}
export function handleLogout() {
  trackEvent(Event.LOGGED_OUT);
}

export function identifyUser(user) {
  delete user.password;

  analytics.identify(user.id, user);
}

export const trackEvent = (eventName: string, eventProperties?) => {
  analytics.track(eventName, eventProperties);
};
