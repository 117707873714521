import { Route, Switch } from 'react-router-dom';

import CreateCustomStyle from './create-custom-style';
import CustomStylesList from './CustomStylesList';
import AppContainer from '@/layouts/AppContainer';

export default function TemplateLibrary() {
  return (
    <AppContainer>
      <Switch>
        <Route path={`/custom-art-styles/new`} component={CreateCustomStyle} />
        <Route path={`/custom-art-styles`} component={CustomStylesList} />
      </Switch>
    </AppContainer>
  );
}
