import { Checkbox as CheckboxBase } from '@chakra-ui/react';
import IconCheckbox from './IconCheckbox';

const Checkbox = ({ children, isChecked, ...rest }) => (
  <CheckboxBase
    icon={<IconCheckbox isChecked={isChecked} />}
    isChecked={isChecked}
    colorScheme="cyan"
    {...rest}
  >
    {children}
  </CheckboxBase>
);

export default Checkbox;
