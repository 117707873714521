import { Flex, VStack } from '@chakra-ui/react';

import { useDesignsBasic } from '@/api/designs';

import Stats from './components/Stats';
import CreditUsage from './components/credits-usage/CreditUsage';
import PopularTemplates from './components/PopularTemplates';
import InfoBoxes from './components/InfoBoxes';
import AppContainer from '@/layouts/AppContainer';
import { useMe } from '../../../api/auth';

export default function Dashboard() {
  const { data: me } = useMe();
  const { data: designs } = useDesignsBasic(me.client);

  return (
    <AppContainer>
      <Flex w="100%">
        <VStack mr="21px" pb="30px" spacing="30px">
          <Stats designs={designs} />
          <CreditUsage />

          <InfoBoxes />
        </VStack>
        <PopularTemplates designs={designs} />
      </Flex>
    </AppContainer>
  );
}
