import { Icon } from '@chakra-ui/react';

const IconMidnightStudios = () => (
  <Icon
    width={{ base: '99px', md: '150px' }}
    height={{ base: '32px', md: '48px' }}
    viewBox="0 0 150 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="48" fill="white" />
    <path
      d="M15.8745 17.3827V23.2437H14V14.3796C14.8366 14.3673 15.6731 14.3326 16.5097 14.3571C16.6556 14.3571 16.8694 14.584 16.9207 14.7496C17.5065 16.4137 18.0677 18.09 18.637 19.7602C18.6904 19.9176 18.7562 20.0709 18.8775 20.3837C19.1858 19.4515 19.4509 18.6624 19.6996 17.8692C20.0408 16.8205 20.3677 15.7656 20.7274 14.7231C20.7808 14.5697 20.9761 14.3653 21.1138 14.3592C21.9359 14.3244 22.7581 14.3428 23.6008 14.3428V23.2314H21.7797C21.7797 23.2314 21.7797 22.9636 21.7797 22.7571C21.7797 21.1442 21.7797 18.9956 21.7797 17.3827L19.6996 23.2314H18.0923"
      fill="#1B1819"
    />
    <path
      d="M40.1946 23.2437L38.3242 23.2294V14.3428H40.4207L43.991 19.9994V14.3428C44.5953 14.3428 45.9025 14.3633 45.9025 14.3633L45.9128 19.4904C45.9128 20.5943 45.9128 22.148 45.9128 23.2519H43.9252L40.1946 17.3888V23.2437Z"
      fill="#1B1819"
    />
    <path
      d="M32.8091 14.3387C35.216 14.3387 36.7 15.6532 36.9549 18.0185C37.0968 19.0501 36.9547 20.1007 36.5438 21.0583C35.9744 22.2992 34.9899 23.0802 33.6272 23.1722C32.1329 23.2723 30.6263 23.1946 29.0703 23.1946V14.3653L32.8091 14.3387ZM31.0312 21.6062C31.0312 21.6062 32.3076 21.6062 32.7351 21.5756C33.9684 21.5142 34.7494 20.905 34.9036 19.752C34.9897 19.0644 34.9654 18.3674 34.8316 17.6873C34.6261 16.6529 33.9087 16.0989 32.842 16.0232C32.4165 15.9946 31.0312 15.9987 31.0312 15.9987V21.6062Z"
      fill="#1B1819"
    />
    <path
      d="M59.2386 23.2253L59.2201 18.4335H55.5019V19.8931H57.4134C57.3024 20.9153 56.8235 21.5163 56.0137 21.7003C54.9222 21.9517 53.9582 21.6226 53.428 20.8151C52.7086 19.7275 52.725 17.7486 53.4752 16.6958C53.7018 16.3599 54.0221 16.0971 54.3966 15.9395C54.7712 15.7819 55.1839 15.7364 55.5841 15.8086C56.7598 15.9599 57.2284 17.2703 57.2284 17.2703H59.0146C59.0189 16.8723 58.9383 16.478 58.7782 16.1133C58.618 15.7486 58.3819 15.4218 58.0855 15.1544C56.3754 13.5701 53.1751 13.7234 51.8391 15.643C50.6716 17.3234 50.649 19.3023 51.428 21.1442C52.4989 23.6526 56.3507 24.274 57.6724 22.4792C57.697 22.4444 57.769 22.334 57.769 22.334C57.8286 22.61 57.9005 22.9269 57.9745 23.2335H59.2386"
      fill="#1B1819"
    />
    <path
      d="M127.663 18.8607C127.604 20.6065 127.047 22.1111 125.374 22.9493C123.768 23.7486 122.149 23.6628 120.646 22.657C119.559 21.9272 119.024 20.8171 118.897 19.5599C118.691 17.5769 119.148 15.8106 120.987 14.7108C123.91 12.969 127.458 14.9295 127.663 18.3517C127.678 18.5193 127.663 18.6931 127.663 18.8607ZM125.213 16.9186C125.01 16.5902 124.73 16.3163 124.396 16.1206C124.062 15.925 123.685 15.8137 123.298 15.7963C122.418 15.7861 121.719 16.1091 121.304 16.8921C121.027 17.4116 120.866 17.9848 120.833 18.572C120.8 19.1593 120.895 19.7467 121.113 20.2938C121.271 20.7298 121.56 21.107 121.941 21.3744C122.322 21.6417 122.776 21.7864 123.243 21.7889C123.709 21.7913 124.164 21.6514 124.548 21.3881C124.932 21.1247 125.225 20.7506 125.388 20.3163C125.567 19.8461 125.813 18.0961 125.219 16.9186H125.213Z"
      fill="#1B1819"
    />
    <path
      d="M62.7976 23.2294V19.4372H66.3802V20.0342C66.3802 20.9664 66.3802 22.2972 66.3905 23.2294L66.6371 23.213C66.6371 23.213 67.7553 23.2294 68.3472 23.2294V14.3694H66.3494V17.6934H62.7647V14.3387H60.7812C60.7997 15.78 60.7812 17.7016 60.7812 19.1449C60.7812 20.349 60.7813 22.0253 60.7915 23.2335"
      fill="#1B1819"
    />
    <path
      d="M135.681 17.0147C135.097 17.0147 133.804 17.0004 133.804 17.0004C133.804 17.0004 133.759 16.8144 133.714 16.6856C133.372 15.7003 131.763 15.2873 130.974 15.9946C130.483 16.4362 130.55 17.1272 131.167 17.35C131.952 17.6321 132.776 17.8059 133.576 18.0492C133.988 18.1546 134.386 18.3083 134.762 18.5071C135.131 18.7235 135.438 19.03 135.655 19.3976C135.871 19.7651 135.99 20.1815 136 20.6074C136.009 21.0334 135.91 21.4547 135.71 21.8316C135.51 22.2085 135.217 22.5284 134.859 22.7613C133.42 23.7221 130.875 23.673 129.514 22.6304C128.764 22.058 128.433 21.271 128.446 20.2958H130.296C130.462 21.7902 132.018 22.1745 133.282 21.7596C133.707 21.6001 134.036 21.3507 134.057 20.8478C134.077 20.3449 133.773 20.0832 133.364 19.9442C132.62 19.6948 131.86 19.4986 131.103 19.2696C130.771 19.1778 130.446 19.0623 130.131 18.9241C129.759 18.7606 129.435 18.5045 129.191 18.1805C128.947 17.8564 128.791 17.4754 128.738 17.074C128.686 16.6727 128.738 16.2645 128.889 15.8889C129.041 15.5133 129.287 15.1829 129.605 14.9296C130.966 13.7848 133.662 13.8604 134.93 15.0788C135.475 15.5919 135.664 16.24 135.681 17.0147Z"
      fill="#1B1819"
    />
    <path
      d="M74.0733 23.2253H72.0466V15.9988H69.4219V14.3735H76.6898V15.9804H74.065L74.0733 23.2253Z"
      fill="#1B1819"
    />
    <path d="M27.3151 23.2028H25.3789V14.3694H27.3151V23.2028Z" fill="#1B1819" />
    <path d="M115.578 23.1906V14.3756H117.504V23.1906H115.578Z" fill="#1B1819" />
    <path
      d="M84.951 33.8209C83.8493 34.011 83.8328 33.9906 83.2943 33.1013C82.7733 32.2224 82.1959 31.3777 81.5657 30.5725V33.2792C81.5718 33.3962 81.5452 33.5126 81.4888 33.6155C81.4323 33.7183 81.3484 33.8036 81.2461 33.8619C81.1439 33.9201 81.0275 33.949 80.9097 33.9454C80.792 33.9418 80.6775 33.9058 80.5791 33.8414C80.5402 33.8137 80.5078 33.778 80.4841 33.7367C80.4603 33.6954 80.4457 33.6495 80.4414 33.6022C80.4414 31.9299 80.4414 30.2577 80.4414 28.5977C81.4691 28.379 81.576 28.424 82.0652 29.211C82.6181 30.1023 83.1813 30.9875 83.8267 32.0117V28.5671H84.9551L84.951 33.8209Z"
      fill="#1B1819"
    />
    <path
      d="M73.6354 31.0039V33.8393H72.9592L72.7537 33.4018C71.691 34.1419 70.6592 34.1766 69.7425 33.5245C68.8258 32.8724 68.4188 31.4802 68.797 30.2536C69.2656 28.7388 70.7948 27.9804 72.2521 28.5548C72.6148 28.6784 72.933 28.905 73.1675 29.2065C73.4021 29.5081 73.5426 29.8714 73.5717 30.2516H72.3385C72.2926 30.0944 72.2306 29.9423 72.1535 29.7977C71.7917 29.1538 70.6962 29.2785 70.2152 29.9286C69.7343 30.5787 69.7507 31.8134 70.2152 32.441C70.3812 32.6599 70.6095 32.824 70.8704 32.9122C71.1314 33.0005 71.413 33.0086 71.6787 32.9357C72.2172 32.8008 72.4412 32.5269 72.5152 31.9136H71.4444V30.9937L73.6354 31.0039Z"
      fill="#1B1819"
    />
    <path
      d="M74.3645 31.4495C74.3521 30.1228 74.7262 29.2396 75.6861 28.7142C76.1023 28.4708 76.5783 28.3472 77.0611 28.3573C77.5438 28.3674 78.0142 28.5108 78.4198 28.7715C79.542 29.4461 79.9613 31.0407 79.4187 32.4512C78.983 33.5797 77.7559 34.193 76.4281 33.9456C75.8385 33.8488 75.3042 33.5424 74.9246 33.0833C74.5451 32.6242 74.346 32.0436 74.3645 31.4495ZM78.5123 31.2451C78.4371 30.8672 78.3312 30.4959 78.1957 30.135C78.0934 29.9345 77.9422 29.7627 77.7558 29.6353C77.5695 29.5079 77.354 29.429 77.1291 29.4058C76.9042 29.3826 76.677 29.4159 76.4684 29.5026C76.2597 29.5892 76.0763 29.7266 75.9348 29.902C75.4415 30.5275 75.4209 31.8604 75.9348 32.4778C76.1094 32.682 76.3369 32.8347 76.5925 32.9194C77.6058 33.2301 78.4383 32.4737 78.5123 31.239V31.2451Z"
      fill="#1B1819"
    />
    <path
      d="M50.1619 33.8087C49.0253 34.0131 48.9924 33.9886 48.9924 32.9235V31.6172H46.8589V33.8393H45.6523V28.5569H46.8321V30.5501H48.9698V28.563H50.1537L50.1619 33.8087Z"
      fill="#1B1819"
    />
    <path
      d="M86.0304 16.9207H87.9605C87.9605 16.9207 88.1783 14.4246 85.2103 14.0587C81.5229 13.5926 81.0152 16.056 81.0152 16.056C81.0152 16.056 80.3431 18.3885 82.5568 18.967C83.4858 19.2083 85.8968 19.335 86.1866 20.2284C86.3243 20.6577 86.2689 22.0028 83.864 21.8393C83.864 21.8393 82.752 21.6839 82.5773 20.2038H80.6453C80.6453 20.2038 80.5055 23.1906 84.1209 23.395C87.7364 23.5994 88.2482 20.997 88.2482 20.997C88.2482 20.997 88.649 18.5193 85.7016 17.9592C84.8362 17.7957 84.2196 17.7241 83.8085 17.5667C82.8178 17.1905 82.9165 16.7858 82.9165 16.7858C82.9165 16.7858 82.7253 15.5081 84.456 15.5592C86.1866 15.6103 86.0304 16.9207 86.0304 16.9207Z"
      fill="#1B1819"
    />
    <path
      d="M63.2119 31.5865C63.1523 31.6847 63.1359 31.699 63.1359 31.7133C63.0166 32.8581 63.0166 32.8581 64.1636 32.856H65.9723C65.9958 32.9674 66.0129 33.08 66.0237 33.1933C66.0484 33.87 66.0463 33.87 65.3804 33.8721C64.3732 33.8721 63.364 33.8721 62.3548 33.8721C62.0855 33.8721 61.8944 33.8455 61.8985 33.5C61.9108 31.9054 61.8985 30.3109 61.9108 28.7184C61.9212 28.6584 61.9391 28.6001 61.9643 28.5446H65.923V29.5279H63.2099C63.1687 29.6117 63.1317 29.6547 63.1297 29.6996C63.0536 30.6421 63.0536 30.6421 64.0094 30.64H65.7298C65.7298 30.8792 65.7175 31.0489 65.7298 31.2104C65.7565 31.5048 65.6167 31.5906 65.3372 31.5845C64.624 31.5722 63.9087 31.5865 63.2119 31.5865Z"
      fill="#1B1819"
    />
    <path
      d="M101.71 29.5443V30.6318H104.232V31.5722H101.766V32.8519H104.553V33.8352H100.469C100.469 33.8352 100.469 30.2577 100.469 28.563H104.456V29.5402L101.71 29.5443Z"
      fill="#1B1819"
    />
    <path
      d="M63.1673 29.5443V30.6298H65.6892V31.5722H63.2227V32.8499H66.0099V33.8352H61.9258C61.9258 33.8352 61.9258 30.2577 61.9258 28.563H65.9133V29.5402L63.1673 29.5443Z"
      fill="#1B1819"
    />
    <path
      d="M85.9219 33.8515V32.8764L88.4788 29.5687H85.9219V28.5466H89.8888V29.5524C89.8888 29.5524 87.5045 32.6025 87.3175 32.8457H89.8888V33.8352L85.9219 33.8515Z"
      fill="#1B1819"
    />
    <path
      d="M55.9298 33.8393C55.7243 33.8557 55.6051 33.87 55.4879 33.872C54.6863 33.8863 54.6657 33.8925 54.4355 33.1361C54.3245 32.7661 54.1313 32.6516 53.7737 32.6925C53.6032 32.7027 53.4323 32.7027 53.2619 32.6925C52.3226 32.6925 52.339 32.6925 51.9649 33.5347C51.911 33.6168 51.8413 33.6874 51.7598 33.7424C51.6783 33.7975 51.5867 33.836 51.4901 33.8557C51.2171 33.8865 50.9418 33.8907 50.668 33.8679C50.9742 33.0502 51.2435 32.2979 51.5251 31.5558C51.8622 30.6666 52.2362 29.7916 52.5363 28.8983C52.7275 28.3238 53.153 28.5814 53.5085 28.5242C53.8641 28.4669 53.9772 28.6161 54.0902 28.933C54.6596 30.5501 55.2824 32.1569 55.9298 33.8393ZM53.2845 29.8734L52.6227 31.7603H53.9381L53.2845 29.8734Z"
      fill="#1B1819"
    />
    <path
      d="M93.6308 28.5425L95.5752 33.8393H94.3193L93.9082 32.6925H92.0111L91.5486 33.8352H90.3359L92.2064 28.5385L93.6308 28.5425ZM93.5794 31.7623C93.3738 31.149 93.1827 30.5889 92.9915 30.0267H92.8661C92.6606 30.5848 92.4715 31.1654 92.2598 31.7623H93.5794Z"
      fill="#1B1819"
    />
    <path d="M96.0078 33.8393V28.5569H97.2246V32.854H99.7651V33.8393H96.0078Z" fill="#1B1819" />
    <path
      d="M110.108 14.3571C112.515 14.3571 113.999 15.6736 114.254 18.0369C114.397 19.0678 114.255 20.1181 113.843 21.0747C113.275 22.3176 112.289 23.0985 110.928 23.1885C109.434 23.2886 107.927 23.211 106.371 23.211V14.3837L110.108 14.3571ZM108.33 21.6246C108.33 21.6246 109.606 21.6246 110.032 21.596C111.265 21.5326 112.048 20.9254 112.202 19.7704C112.286 19.0826 112.262 18.386 112.13 17.7057C111.925 16.6733 111.205 16.1193 110.141 16.0437C109.715 16.013 108.33 16.0171 108.33 16.0171V21.6246Z"
      fill="#1B1819"
    />
    <path d="M49.5435 23.1906H47.6094V14.3613H49.5435V23.1906Z" fill="#1B1819" />
    <path
      d="M93.3115 23.1906H91.2849V15.9538H88.6602V14.3286H95.9363V15.9354H93.3033L93.3115 23.1906Z"
      fill="#1B1819"
    />
    <path
      d="M46.8474 33.8781V31.6008H48.9994V31.9585C48.9994 32.5187 48.9994 33.318 48.9994 33.8781H49.1474C49.1474 33.8781 49.8174 33.8781 50.1751 33.8781V28.563H48.9747V30.55H46.8289V28.5405H45.6367C45.647 29.4052 45.6367 30.5582 45.6367 31.425C45.6367 32.1466 45.6367 33.1544 45.6367 33.8781"
      fill="#1B1819"
    />
    <path
      d="M81.5322 33.8393L80.4141 33.8311L80.4243 28.5507H81.6761L83.8096 31.9299V28.5507L84.9504 28.563V33.8434L83.7644 33.8373L81.5425 30.3701L81.5322 33.8393Z"
      fill="#1B1819"
    />
    <path
      d="M53.9568 28.5486L55.9012 33.8454H54.6454L54.2343 32.7006H52.3392L51.8747 33.8433H50.6641L52.5324 28.5466L53.9568 28.5486ZM53.9075 31.7766L53.3176 30.041H53.1922C52.9867 30.599 52.7976 31.1796 52.5756 31.7766H53.9075Z"
      fill="#1B1819"
    />
    <path
      d="M43.6526 30.1146H44.8057C44.8057 30.1146 44.9352 28.6365 43.1614 28.4158C40.9621 28.1398 40.6579 29.5974 40.6579 29.5974C40.6579 29.5974 40.2468 30.9773 41.5787 31.3187C42.1316 31.4618 43.5704 31.5374 43.743 32.0648C43.8253 32.3183 43.7924 33.1135 42.3577 33.0175C42.3577 33.0175 41.6938 32.9255 41.589 32.0526H40.4379C40.4379 32.0526 40.3537 33.8168 42.5118 33.9435C44.67 34.0703 44.9783 32.5248 44.9783 32.5248C44.9783 32.5248 45.2188 31.059 43.4614 30.7279C43.0799 30.6755 42.7023 30.5984 42.331 30.4968C41.739 30.274 41.7986 30.0348 41.7986 30.0348C41.7986 30.0348 41.6856 29.2784 42.7174 29.3091C43.7492 29.3398 43.6526 30.1146 43.6526 30.1146Z"
      fill="#1B1819"
    />
    <path
      d="M108.229 30.1412H109.372C109.372 30.1412 109.504 28.6652 107.728 28.4444C105.528 28.1685 105.226 29.626 105.226 29.626C105.226 29.626 104.815 31.0059 106.145 31.3473C106.7 31.4904 108.139 31.564 108.309 32.0935C108.392 32.347 108.359 33.1422 106.924 33.0462C106.924 33.0462 106.262 32.9542 106.157 32.0812H105.004C105.004 32.0812 104.92 33.8455 107.078 33.9702C109.236 34.0949 109.545 32.5535 109.545 32.5535C109.545 32.5535 109.783 31.0918 108.034 30.7545C107.652 30.7023 107.275 30.6244 106.904 30.5214C106.312 30.2986 106.371 30.0615 106.371 30.0615C106.371 30.0615 106.258 29.303 107.29 29.3358C108.322 29.3685 108.229 30.1412 108.229 30.1412Z"
      fill="#1B1819"
    />
    <path
      d="M57.5439 33.8536L56.4258 33.8454L56.4381 28.565H57.6899L59.8213 31.9442V28.565L60.9641 28.5793V33.8577L59.7761 33.8536L57.5542 30.3844L57.5439 33.8536Z"
      fill="#1B1819"
    />
    <path
      d="M59.2368 18.5561V23.3786H58.0879L57.722 22.6345C55.9112 23.8938 54.1579 23.9531 52.5979 22.8389C51.0378 21.7248 50.3472 19.3636 50.9885 17.2805C51.786 14.7108 54.3881 13.4209 56.8669 14.4001C58.1536 14.9071 58.9367 15.8311 59.1094 17.2887H57.0232C56.9463 17.021 56.8416 16.762 56.7107 16.5159C56.0941 15.4202 54.2299 15.6308 53.4221 16.7388C52.6328 17.8079 52.6287 19.9442 53.4221 21.0113C53.7038 21.3843 54.0919 21.6642 54.536 21.8144C54.98 21.9647 55.4592 21.9783 55.9112 21.8536C56.8279 21.6226 57.2081 21.1667 57.3356 20.1221H55.5124V18.5561H59.2368Z"
      fill="#1B1819"
    />
    <path
      d="M123.229 14.0587C120.777 14.0587 118.789 16.1684 118.789 18.7606C118.789 21.3528 120.777 23.4625 123.229 23.4625C125.681 23.4625 127.664 21.3691 127.664 18.7688C127.664 16.1684 125.683 14.0587 123.229 14.0587ZM123.214 21.7902C121.901 21.7902 120.836 20.4492 120.836 18.7933C120.836 17.1374 121.909 15.7964 123.214 15.7964C124.52 15.7964 125.595 17.1374 125.595 18.7933C125.595 20.4492 124.53 21.7902 123.214 21.7902Z"
      fill="#1B1819"
    />
    <path
      d="M77.0125 28.3667C75.5511 28.3667 74.3672 29.6239 74.3672 31.1715C74.3672 32.719 75.5511 33.9762 77.0125 33.9762C78.4739 33.9762 79.6558 32.7211 79.6558 31.1633C79.6558 29.6055 78.4718 28.3667 77.0125 28.3667ZM77.0022 32.9705C76.2212 32.9705 75.586 32.1732 75.586 31.1858C75.586 30.1984 76.2212 29.4011 77.0022 29.4011C77.7833 29.4011 78.4205 30.2004 78.4205 31.1858C78.4205 32.1711 77.7853 32.9705 77.0022 32.9705Z"
      fill="#1B1819"
    />
    <path
      d="M104.596 14.3674H102.757V19.887C102.74 20.3941 102.526 20.8749 102.159 21.2279C101.793 21.5808 101.303 21.7781 100.793 21.7781C100.282 21.7781 99.7923 21.5808 99.4258 21.2279C99.0593 20.8749 98.8451 20.3941 98.8286 19.887V14.3674H96.9788C96.9788 14.3674 96.9253 18.4887 97.0034 20.486C97.063 22.0212 97.9818 22.9984 99.5069 23.2969C100.127 23.4176 100.76 23.452 101.39 23.3991C103.511 23.213 104.578 22.0314 104.59 19.9238C104.604 18.2229 104.596 14.3674 104.596 14.3674Z"
      fill="#1B1819"
    />
  </Icon>
);

export default IconMidnightStudios;
