import { Button } from '@chakra-ui/react';

import IconClose from './IconCloseModal';

type ButtonCloseProps = {
  onClose: () => void;
};

const SIZE = 39;

const ButtonClose = ({ onClose }: ButtonCloseProps) => (
  <Button
    border="1px solid #8D8D8D"
    borderRadius="50%"
    height={`${SIZE}px`}
    bg="#FFFFFF"
    onClick={onClose}
    padding="8px"
    width={`${SIZE}px`}
  >
    <IconClose />
  </Button>
);

export default ButtonClose;
