import { Icon } from '@chakra-ui/react';

const IconUploadImage = () => (
  <Icon
    width="51px"
    height="51px"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="49" height="49" rx="10" fill="white" />
    <rect x="7" y="8" width="37" height="35" rx="10" fill="#F8F9FC" />
    <path
      d="M36.625 26H34.375V29.375H31V31.625H34.375V35H36.625V31.625H40V29.375H36.625V26Z"
      fill="#C8CDD9"
    />
    <path d="M21.3636 22L17 28H33L27.1818 19L22.8182 25L21.3636 22Z" fill="#C8CDD9" />
    <rect
      x="1"
      y="1"
      width="49"
      height="49"
      rx="10"
      stroke="#E0E5F2"
      strokeWidth="2"
      strokeDasharray="4 4"
    />
  </Icon>
);

export default IconUploadImage;
