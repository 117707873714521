import {
  HStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react';

import ButtonBase from '@/components/button';

import IconInfo from './components/IconInfo';

const Button = (props) => (
  <ButtonBase borderRadius="14px" fontSize="md" h="50px" p="0 36px" {...props} />
);

type Props = {
  onClose: () => void;
  onConfirm: () => void;
};

const ApiIntegationConfirmationModal = ({ onClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        borderRadius="14px"
        maxWidth="546px"
        maxHeight="323px"
        padding={0}
      >
        <ModalBody padding="40px 80px">
          <VStack>
            <IconInfo />
            <Text fontWeight={600} fontSize="24px">
              API Integration Confirmation
            </Text>
            <Text color="#4A5568" fontSize="md" textAlign="center">
              To integrate with the Ablo APIs, users are required share 5% of the revenue generated
              through the API usage. Please confirm your agreement to these terms to access your API
              key.
            </Text>
          </VStack>
          <Flex align="center" direction="column" mt="32px">
            <HStack spacing="10px">
              <Button secondary onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onConfirm}>Confirm</Button>
            </HStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApiIntegationConfirmationModal;
