import { MenuItem as ChakraMenuItem } from '@chakra-ui/react';

export const MenuItem = (props) => (
  <ChakraMenuItem
    color="black.700"
    fontSize="sm"
    fontWeight={700}
    padding="8px 18px"
    _focus={{
      bg: '#F4F4F4',
    }}
    _active={{
      bg: 'none',
    }}
    {...props}
  />
);
