import { updateDesignSide } from '@/api/designs';
import { uploadBase64ToStorage } from '@/api/storage';
import { Design } from '@/lib';
import { getFullPrintableAreaImage } from '@/lib/utils/canvas-manufacturing-image-export';

export const createMissingManufacturingImages = async (design: Design) => {
  const { sides = [] } = design;

  const sidesWithMissingManufacturingImages = sides.filter(
    (side) => side.designImage && !side.manufacturingImageUrl
  );

  const sidesWithGeneratedManufacturingImages = await Promise.all(
    sidesWithMissingManufacturingImages.map(async (side) => {
      const { designImage } = side;

      const manufacturingImage = await getFullPrintableAreaImage(designImage, side.templateSide);

      const manufacturingImageUrl = await uploadBase64ToStorage(manufacturingImage, 'image/png');

      return {
        ...side,
        manufacturingImageUrl,
      };
    })
  );

  for (const side of sidesWithGeneratedManufacturingImages.filter((side) => !!side)) {
    const { id, manufacturingImageUrl } = side;

    await updateDesignSide(design.id, {
      id,
      manufacturingImageUrl,
    });
  }
};
