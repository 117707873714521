import {
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
} from '@chakra-ui/react';

import SketchPicker from 'react-color/es/Sketch.js';

import IconCloseModal from '@/components/icons/IconCloseModal';
import ButtonCTA from '@/components/button';
import { useEffect, useState } from 'react';

type Props = {
  onClose: () => void;
  onSelectedColor: (color: string) => void;
  selectedColor: string;
};

const ColorPickerModal = ({ onClose, onSelectedColor, selectedColor }: Props) => {
  const [color, setColor] = useState('');

  useEffect(() => {
    setColor(selectedColor);
  }, [selectedColor]);

  const handleSave = () => {
    onSelectedColor(color);

    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="10px" w="328px">
        <ModalBody padding="15px 12px">
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <Text as="b" fontSize="sm">
              Choose Color
            </Text>
            <Button bg="transparent" minWidth="none" onClick={onClose} padding={0}>
              <IconCloseModal />
            </Button>
          </Flex>
          <SketchPicker
            color={color}
            disableAlpha
            onChange={({ hex }) => setColor(hex)}
            width="283px"
          />
          <Flex justify="space-between" mt="40px">
            <ButtonCTA onClick={onClose} outlined>
              Cancel
            </ButtonCTA>
            <ButtonCTA onClick={handleSave}>Save</ButtonCTA>
          </Flex>
          <Box mb="20px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ColorPickerModal;
