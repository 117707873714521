import { H3 } from '@/components/typography/Headings';
import { Box, Text } from '@chakra-ui/react';

interface StatBoxProps {
  name: string;
  value: number;
  nameColor?: string;
  valueColor?: string;
}

const StatBox = ({ name, value }: StatBoxProps) => (
  <Box
    border="1px solid"
    borderColor="borderSecondary"
    borderRadius={6}
    padding="11px 18px"
    w="185px"
  >
    <Text color="secondaryDarkGray.600" mb="18px" textStyle="bodySmall">
      {name}
    </Text>
    <H3 color="black.600">{value ? value.toLocaleString() : 0}</H3>
  </Box>
);

export default StatBox;
