import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import IconSearch from '@/components/icons/IconSearch';

type InputSearchProps = {
  search: string;
  onSearchChange: (search: string) => void;
};

const InputSearch = ({ search, onSearchChange }: InputSearchProps) => (
  <InputGroup>
    <InputLeftElement pointerEvents="none">
      <IconSearch />
    </InputLeftElement>
    <Input
      borderRadius="49px"
      borderColor="gray.400"
      fontSize="sm"
      placeholder="Search"
      value={search}
      onChange={(e) => onSearchChange(e.target.value)}
    />
  </InputGroup>
);

export default InputSearch;
