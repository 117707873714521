import { Flex, HStack, VStack } from '@chakra-ui/react';

import { useMe } from '@/api/auth';

import Header from './components/Header';
import AccountSettings from './components/AccountSettings';
import ChangePassword from './components/ChangePassword';
import CompanyInfo from './components/CompanyInfo';
import AppContainer from '@/layouts/AppContainer';

export default function ProfileSettings() {
  const { data: me } = useMe();

  return (
    <AppContainer>
      <Flex w="100%">
        <VStack flex={1} mr="21px" pb="30px" spacing="30px">
          <Header user={me} />
          <HStack spacing="20px" w="100%">
            <AccountSettings me={me} />
            <ChangePassword />
          </HStack>
          <CompanyInfo me={me} />
        </VStack>
      </Flex>
    </AppContainer>
  );
}
