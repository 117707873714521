import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box, useToast } from '@chakra-ui/react';

import Config from '@/config';

import Button from '../components/buttons/ButtonCTA';

const { CLIENT_URL } = Config;

const CheckoutForm = ({ type }: { type: string }) => {
  const stripe = useStripe();
  const elements = useElements();

  const toast = useToast();

  const handleSubmit = async () => {
    const method = type === 'setup' ? 'confirmSetup' : 'confirmPayment';

    const result = await stripe[method]({
      elements,
      confirmParams: {
        return_url: `${CLIENT_URL}/subscription`,
      },
    });

    if (result.error) {
      toast({
        title: 'Error with payment',
        description: result.error.message,
        status: 'error',
      });
    }
  };

  return (
    <Box>
      <PaymentElement />
      <Button isDisabled={!stripe || !elements} mt="40px" onClick={handleSubmit} w="195px">
        Change my plan
      </Button>
    </Box>
  );
};

export default CheckoutForm;
