import { Icon } from '@chakra-ui/react';

const IconHojeon = () => (
  <Icon
    width={{ base: '99px', md: '150px' }}
    height={{ base: '32px', md: '48px' }}
    viewBox="0 0 150 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="48" fill="white" />
    <g clipPath="url(#clip0_205_1582)">
      <path
        d="M13 28.9391L13.5626 28.2088C13.5626 28.1319 22.7313 19.5083 23.4346 18.9829C23.8092 18.7182 24.1679 18.4316 24.5087 18.1244C24.6742 17.9803 24.7792 17.779 24.8028 17.5606C24.8028 16.8174 24.8028 16.087 24.8028 15.3438V14.37C26.2734 14.37 28.7158 14.37 30.1863 14.37C30.8346 14.432 31.4862 14.2934 32.0533 13.9728C33.872 13.1595 35.7332 12.4452 37.6286 11.8329C38.2552 11.6663 41.4393 11.0769 42.4112 11C42.4112 11 42.4879 11.0769 42.539 11.141C42.1682 11.346 39.5212 12.9605 39.1759 13.1527C38.8307 13.3449 33.2937 16.4842 33.2937 17.0737C33.2937 18.7266 33.2937 22.6861 33.2937 22.6861H24.8796V21.5456C24.6056 21.6538 24.344 21.7913 24.0995 21.9557C23.5241 22.3401 22.9614 22.7629 22.4116 23.173L21.7722 23.6215H33.7157V35H21.7722L21.6699 23.6727C21.4352 23.7969 21.2126 23.9428 21.005 24.1084C21.005 24.1084 14.8286 28.3241 14.6112 28.4778L13.3325 29.1185L13 28.9391Z"
        fill="black"
      />
      <path d="M42.9348 32.8473H34.2266V23.6215L42.9348 23.6599V32.8473Z" fill="black" />
      <path d="M40.8766 22.6861H34.6875V16.369L40.8766 16.4202V22.6861Z" fill="black" />
      <path
        d="M53.5604 30.6177V26.9017H51.1564C50.0566 26.9017 50.0566 26.9017 50.0183 27.9781C49.9991 28.2768 49.9991 28.5764 50.0183 28.875C50.0224 29.0765 49.9693 29.2751 49.8651 29.4475C49.761 29.6199 49.6101 29.7591 49.4301 29.8489C49.0031 30.1084 48.5485 30.319 48.0746 30.4768C47.8444 29.3363 48.0746 28.1319 47.9467 26.9402L46.668 26.812C47.5247 26.0048 48.3303 25.2872 47.8316 24.0059L46.9365 23.9162C47.269 23.6086 49.251 22.3401 49.4301 22.2376C49.6804 22.5576 49.8563 22.9296 49.9448 23.3264C50.0333 23.7232 50.0322 24.1349 49.9416 24.5312C49.9416 25.236 49.9416 25.3 50.696 25.3C51.6072 25.286 52.5167 25.2175 53.4197 25.095C53.4677 24.7017 53.4239 24.3025 53.2919 23.929C53.1896 23.7111 53.0233 23.5574 53.0489 23.3908C53.4819 22.9773 53.9481 22.6002 54.4427 22.2632C54.5149 22.2284 54.594 22.2106 54.674 22.2113C54.7541 22.2119 54.8329 22.2308 54.9045 22.2667C54.9761 22.3026 55.0386 22.3544 55.0871 22.4182C55.1356 22.482 55.1689 22.5561 55.1844 22.6348C55.2035 22.8779 55.2035 23.1221 55.1844 23.3652C55.1844 25.0438 55.1844 26.7224 55.1844 28.4009C55.1844 29.4004 54.6601 29.8233 53.9824 30.2589L53.5604 30.6177Z"
        fill="black"
      />
      <path
        d="M105.874 26.7736C106.526 26.2355 107.012 25.7229 107.728 25.2232C107.887 25.0495 108.09 24.923 108.315 24.8575C108.541 24.7919 108.78 24.7899 109.007 24.8516L109.646 26.9018C109.864 26.7608 111.245 25.4666 111.743 24.9925C111.877 24.8849 112.033 24.809 112.201 24.7705C112.368 24.732 112.541 24.7318 112.709 24.77C112.876 24.8082 113.032 24.8838 113.166 24.9911C113.3 25.0984 113.408 25.2347 113.482 25.3898C113.482 25.4666 113.853 28.952 113.943 29.3748C113.649 29.6183 113.239 29.9642 112.805 30.2718C112.562 30.4512 112.05 30.0283 112.025 29.6055C112.003 28.6899 111.812 27.7863 111.462 26.9402C111.232 26.8121 109.237 28.6701 109.071 29.1185C108.965 29.3646 108.784 29.5712 108.555 29.7091C108.325 29.847 108.059 29.9093 107.792 29.8874C107.792 29.1442 107.792 28.401 107.792 27.6578C107.752 27.3922 107.61 27.153 107.396 26.9915C107.29 26.9385 107.174 26.9109 107.057 26.9109C106.939 26.9109 106.823 26.9385 106.718 26.9915C106.354 27.3054 106.02 27.6529 105.72 28.0294C105.214 28.7148 104.778 29.4488 104.416 30.2205H103.687C103.687 29.3748 103.687 28.5419 103.687 27.7218C103.683 27.4019 103.609 27.0868 103.47 26.7989C103.331 26.511 103.13 26.2574 102.882 26.0561C102.847 26.0263 102.821 25.9884 102.806 25.9459C102.79 25.9033 102.785 25.8575 102.792 25.8126C102.852 25.7319 102.925 25.6624 103.009 25.6076C103.251 25.4107 103.513 25.239 103.79 25.095C104.101 24.9232 104.445 24.8185 104.8 24.7875C105.004 24.7875 105.26 25.0694 105.375 25.2873C105.554 25.8254 105.708 26.3764 105.874 26.7736Z"
        fill="black"
      />
      <path
        d="M132.434 26.3764C131.588 25.8141 130.713 25.2966 129.813 24.826C129.787 24.7765 129.773 24.7216 129.773 24.6658C129.773 24.61 129.787 24.5551 129.813 24.5056C130.318 24.1191 130.853 23.7721 131.411 23.4677L132.217 23.0833C132.224 23.1942 132.224 23.3055 132.217 23.4165C132.14 23.5574 131.923 23.8137 131.923 23.8137C132.101 24.0223 132.313 24.1998 132.549 24.3391C133.457 24.826 135.989 25.6204 136.27 26.0176C138.406 28.7341 134.992 29.5158 134.224 29.9386C133.457 30.3615 129.723 30.5024 130.286 28.4779C130.644 27.4015 131.181 26.543 132.434 26.3764ZM134.992 28.1191C134.902 27.6322 134.224 25.4667 132.818 26.2355C132.589 26.5743 132.425 26.9521 132.332 27.3503C132.153 28.0166 132.562 28.4138 133.061 28.7726C133.559 29.1314 134.339 29.2595 134.646 28.9904C134.953 28.7213 134.851 28.401 134.966 28.1063L134.992 28.1191Z"
        fill="black"
      />
      <path
        d="M81.6184 30.2846H80.8895V26.5686L79.6875 26.3764V25.7742C80.199 25.5692 80.8639 25.5051 81.1836 25.1335C81.5033 24.7619 82.0532 24.8516 82.5775 24.8388V26.8377C82.846 26.8377 84.5467 23.583 86.4137 24.8772C86.4651 25.6535 86.6155 26.42 86.8613 27.158C87.0767 27.6638 87.1814 28.21 87.1682 28.7598C87.1682 29.3492 86.2219 29.9514 86.1707 30.0411C85.1989 30.5537 85.1222 30.5024 85.135 29.3492C85.0877 28.5788 84.9149 27.8213 84.6235 27.1068C84.3549 26.8249 83.1401 27.8244 82.8204 28.26C82.5632 28.5795 82.3241 28.9131 82.1043 29.2595C81.9509 29.5158 81.8102 29.8745 81.6184 30.2846Z"
        fill="black"
      />
      <path
        d="M91.8859 30.2974C91.8859 28.4779 91.8859 26.7865 91.8859 25.0822C91.8859 24.1084 91.8859 24.1084 90.9141 23.8009C91.6249 23.2549 92.3633 22.746 93.1263 22.2761C93.4332 22.0967 93.8935 22.5964 93.8935 23.0961V28.0166C93.8935 28.7341 95.6071 28.7982 96.1058 28.7726C96.6045 28.747 96.937 28.5932 97.3845 28.5291C97.5092 28.5282 97.6306 28.5687 97.7298 28.6444C97.751 28.6838 97.7622 28.7278 97.7622 28.7726C97.7622 28.8173 97.751 28.8614 97.7298 28.9007C97.5799 29.083 97.4129 29.2503 97.2311 29.4005C96.9881 29.6439 96.7451 29.8746 96.4894 30.0924C96.4132 30.1685 96.3146 30.218 96.2081 30.2333L91.8859 30.2974Z"
        fill="black"
      />
      <path
        d="M57.9471 26.2227C57.6146 25.5948 57.7042 25.5948 58.4586 25.2232C59.0299 24.9243 59.6687 24.7786 60.3128 24.8003C60.6129 24.808 60.9079 24.8794 61.1784 25.0098C61.449 25.1401 61.6888 25.3265 61.8822 25.5566C62.0755 25.7867 62.218 26.0552 62.3001 26.3445C62.3823 26.6338 62.4023 26.9373 62.3588 27.2349C62.2698 27.9807 61.9471 28.679 61.437 29.2293C60.9268 29.7796 60.2557 30.1536 59.52 30.2974C59.3665 30.2974 56.3998 30.8612 56.6812 28.5163C56.6812 28.2216 56.6812 27.9012 56.7451 27.5937L56.4766 27.504L57.9471 26.2227ZM59.3665 28.9392C60.0315 28.9392 61.3358 27.6578 60.8371 26.5686C60.5558 25.9408 59.2514 25.8126 58.8167 26.0945C58.3819 26.3764 57.41 29.1314 59.3665 28.9392Z"
        fill="black"
      />
      <path
        d="M129.227 28.6444C129.38 29.157 127.948 30.2589 126.771 30.3102C126.308 30.3267 125.852 30.1954 125.468 29.9352C125.084 29.6749 124.793 29.2992 124.636 28.8622C124.505 28.5841 124.394 28.2972 124.303 28.0037C124.086 27.2221 124.521 23.0705 128.383 24.8516C128.71 25.0188 129.002 25.2497 129.239 25.5307C129.61 25.9792 129.022 26.9018 128.383 27.1708C127.823 27.335 127.292 27.5858 126.81 27.914C126.758 27.9676 126.721 28.0332 126.702 28.1048C126.682 28.1763 126.68 28.2517 126.697 28.3241C126.713 28.3965 126.747 28.4637 126.796 28.5196C126.845 28.5755 126.907 28.6184 126.976 28.6444C127.491 28.8803 128.078 28.9079 128.613 28.7213C128.843 28.6829 129.022 28.67 129.227 28.6444ZM126.017 25.992V27.4912C126.414 27.4299 126.798 27.3044 127.155 27.1196C127.224 26.9941 127.261 26.853 127.261 26.7095C127.261 26.5661 127.224 26.425 127.155 26.2995C126.798 26.1278 126.412 26.0235 126.017 25.992Z"
        fill="black"
      />
      <path
        d="M123.879 25.1591L122.332 26.1714C121.769 26.7993 121.782 29.0288 122.076 29.0801C122.076 29.3107 122.971 29.5542 122.958 29.7592C122.153 29.9386 121.488 30.6434 120.618 30.2205C119.966 29.2595 120.324 26.4661 120.299 26.1586C120.158 26.1586 119.416 26.0433 119.25 25.992C119.352 25.8639 120.26 25.5691 120.529 25.1207C120.563 24.7504 120.618 24.3824 120.695 24.0187L122.409 23.2371C122.409 23.5702 122.102 24.9797 122.575 24.9413C123.019 24.9409 123.459 25.0145 123.879 25.1591Z"
        fill="black"
      />
      <path
        d="M65.6977 24.8259V32.3476L63.997 32.8729V26.1842C63.7158 26.1456 63.4433 26.0589 63.1914 25.9279C63.1914 25.8382 64.6364 24.8387 64.6364 24.8387L65.6977 24.8259Z"
        fill="black"
      />
      <path
        d="M115.059 26.4405V25.6973C115.928 25.6973 116.235 25.0438 116.695 24.6466L115.302 23.8137C115.941 23.4194 116.612 23.0808 117.309 22.8014C117.573 23.1565 117.812 23.5289 118.025 23.9162L117.194 24.0828C116.977 24.7363 117.386 24.8644 117.91 24.9285C117.91 26.3508 117.91 27.7603 117.91 29.1698C117.91 29.3492 116.849 30.1308 116.491 30.3102C116.358 30.2106 116.259 30.0716 116.209 29.913C116.209 29.0673 116.209 28.2216 116.209 27.3502C116.209 26.4789 116.209 26.4533 115.302 26.4661L115.059 26.4405Z"
        fill="black"
      />
      <path
        d="M65.0305 22.6476L66.1174 23.8265C65.2735 23.9162 64.6852 24.9028 63.6878 24.2365C63.4704 23.6856 63.5983 23.4165 64.2377 23.1346C64.5132 22.9914 64.7782 22.8287 65.0305 22.6476Z"
        fill="black"
      />
      <path
        d="M98.2656 26.6968V25.9664C99.1224 25.9664 99.4293 25.3001 99.9024 24.9157L98.547 24.0443C99.1871 23.6555 99.8586 23.3211 100.555 23.0449C100.83 23.3917 101.069 23.7652 101.271 24.1597L100.452 24.3262C100.222 24.9797 100.631 25.095 101.168 25.1719C101.168 26.5814 101.168 27.9909 101.168 29.4004C101.168 29.5798 100.107 30.3615 99.749 30.5537C99.6166 30.4561 99.5218 30.3158 99.4804 30.1564C99.4804 29.3107 99.4804 28.465 99.4804 27.5937C99.4804 26.7224 99.4804 26.7096 98.5725 26.7224L98.2656 26.6968Z"
        fill="black"
      />
      <path
        d="M72.0157 28.7213C72.1691 29.2338 70.737 30.3358 69.5605 30.3999C69.0962 30.4135 68.6395 30.2796 68.2559 30.0172C67.8723 29.7548 67.5814 29.3774 67.425 28.9391C67.2967 28.6557 67.1856 28.3647 67.0925 28.0678C66.8751 27.2862 67.3099 23.1345 71.1589 24.9285C71.4894 25.0916 71.7847 25.318 72.0285 25.5948C72.3993 26.0432 71.8111 26.9786 71.1717 27.2349C70.6293 27.4085 70.1163 27.6634 69.65 27.9909C69.5984 28.0437 69.5606 28.1083 69.54 28.1792C69.5193 28.2501 69.5165 28.325 69.5317 28.3973C69.5468 28.4695 69.5796 28.5369 69.627 28.5934C69.6744 28.65 69.735 28.6939 69.8035 28.7213C70.3251 28.9525 70.9151 28.9755 71.453 28.7854C71.6321 28.7597 71.8239 28.7469 72.0157 28.7213ZM68.806 26.0817V27.5681C69.2043 27.5126 69.5897 27.3868 69.9441 27.1965C70.0135 27.071 70.05 26.9299 70.05 26.7864C70.05 26.643 70.0135 26.5019 69.9441 26.3764C69.5858 26.2096 69.2001 26.1097 68.806 26.0817Z"
        fill="black"
      />
      <path
        d="M74.7618 26.0817C74.4293 25.4538 74.5188 25.4538 75.2733 25.0822C75.8293 24.7918 76.4494 24.6464 77.0763 24.6594C77.3764 24.6671 77.6714 24.7385 77.9419 24.8688C78.2125 24.9992 78.4523 25.1855 78.6457 25.4156C78.839 25.6457 78.9815 25.9143 79.0636 26.2036C79.1458 26.4929 79.1658 26.7963 79.1223 27.094C79.0462 27.8512 78.7291 28.5639 78.218 29.1269C77.7068 29.6898 77.0286 30.0732 76.2835 30.2205C76.13 30.2205 73.1633 30.7843 73.4446 28.4394C73.4446 28.1447 73.4446 27.8243 73.4446 27.5168L73.2656 27.4271L74.7618 26.0817ZM76.1812 28.8366C76.8461 28.8366 78.1504 27.5553 77.6517 26.4661C77.3704 25.8382 76.0661 25.7101 75.6313 25.992C75.1965 26.2739 74.2247 28.9391 76.1812 28.8366Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_205_1582">
        <rect width="124" height="24" fill="white" transform="translate(13 11)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconHojeon;
