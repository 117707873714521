import SignIn from '@/views/auth/signin';
import SignUp from '@/views/auth/signup';
import ForgotPassword from '@/views/auth/forgot-password';
import VerifyEmail from '@/views/auth/verify-email';

const routes = [
  {
    name: 'Sign in',
    path: '/signin',
    component: SignIn,
  },
  {
    name: 'Sign up',
    path: '/signup',
    component: SignUp,
  },
  {
    name: 'Forgot password',
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    name: 'Verify email',
    path: '/verify-email',
    component: VerifyEmail,
  },
];

export default routes;
