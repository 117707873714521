import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  Image,
} from '@chakra-ui/react';

import Spinner from '@/assets/spinner.gif';

interface LoadingOverlayProps {
  title?: string;
}

const LoadingOverlay = ({ title }: LoadingOverlayProps) => (
  <Modal isOpen={true} onClose={null} motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent alignSelf="center" boxShadow="0px 0px 14px 0px #BBBBBB59" h="86px" w="152px">
      <ModalBody bg="#FFFFFF" borderRadius="9px" padding="15px 12px">
        <VStack spacing={0}>
          <Image src={Spinner} w="46px" />
          <Text color="black.600" fontSize="15px">
            {title || 'Updating'}
          </Text>
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default LoadingOverlay;
