import { Icon } from '@chakra-ui/react';

const IconClear = () => {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2505 9.73598L13.0572 11.9293L12.9865 12L13.0572 12.0707L15.2505 14.264L14.2639 15.2506L12.0706 13.0573L11.9999 12.9866L11.9292 13.0573L9.73592 15.2506L8.74934 14.264L10.9426 12.0707L11.0133 12L10.9426 11.9293L8.74934 9.73598L9.73592 8.7494L11.9292 10.9427L11.9999 11.0134L12.0706 10.9427L14.2639 8.7494L15.2505 9.73598ZM6.41339 17.584C5.65886 16.8553 5.05702 15.9836 4.64299 15.0197C4.22896 14.0559 4.01102 13.0193 4.00191 11.9703C3.99279 10.9214 4.19268 9.88109 4.5899 8.91021C4.98712 7.93933 5.57372 7.05728 6.31547 6.31553C7.05722 5.57378 7.93927 4.98718 8.91015 4.58996C9.88103 4.19274 10.9213 3.99286 11.9703 4.00197C13.0192 4.01109 14.0559 4.22902 15.0197 4.64305C15.9835 5.05708 16.8552 5.65892 17.584 6.41345C19.023 7.90341 19.8193 9.89896 19.8013 11.9703C19.7833 14.0417 18.9525 16.0231 17.4878 17.4878C16.023 18.9525 14.0416 19.7834 11.9703 19.8014C9.8989 19.8194 7.90335 19.0231 6.41339 17.584ZM7.40121 16.5987C8.62086 17.8183 10.2751 18.5035 11.9999 18.5035C13.7248 18.5035 15.379 17.8183 16.5986 16.5987C17.8183 15.379 18.5035 13.7248 18.5035 12C18.5035 10.2751 17.8183 8.62092 16.5986 7.40127C15.379 6.18161 13.7248 5.49642 11.9999 5.49642C10.2751 5.49642 8.62086 6.18161 7.40121 7.40127C6.18155 8.62092 5.49636 10.2751 5.49636 12C5.49636 13.7248 6.18155 15.379 7.40121 16.5987Z"
        fill="#212529"
        stroke="white"
        strokeWidth="0.2"
      />
    </Icon>
  );
};

export default IconClear;
