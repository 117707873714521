import { Box, Center, HStack, Text } from '@chakra-ui/react';

const BUTTON_SIZE = 50;

const getDescription = (upscaledWidth, upscaledHeight) => {
  const sizeInMegaPixels = (upscaledWidth * upscaledHeight) / 1e6;

  if (sizeInMegaPixels < 4) {
    return 'Best for Instagram posts, Twitter posts, Facebook posts.';
  } else if (sizeInMegaPixels < 9) {
    return 'Best for Small stickers, iPhone cases, blog posts, Website banners etc.';
  } else if (sizeInMegaPixels < 16) {
    return 'Best for larger banners, Post cards, tote bags etc.';
  }

  return 'Best for posters, canvas prints, T-shirts, skateboards.';
};

interface DownloadResolutionPickerProps {
  imageWidth: number;
  imageHeight: number;
  onSelectedValue: (resolution: number) => void;
  selectedValue: number;
  resolutionMultipliers: number[];
}

const DownloadResolutionPicker = ({
  imageWidth,
  imageHeight,
  onSelectedValue,
  selectedValue,
  resolutionMultipliers,
}: DownloadResolutionPickerProps) => {
  const upscaledWidth = selectedValue * imageWidth;
  const upscaledHeight = selectedValue * imageHeight;

  return (
    <Box>
      <HStack mb="10px" spacing="6px">
        {resolutionMultipliers.map((resolution) => {
          const isSelected = selectedValue === resolution;

          return (
            <Center
              as="button"
              bg={isSelected ? 'rgba(6, 74, 196, 0.1)' : 'transparent'}
              border="1px solid"
              borderColor={isSelected ? 'brand.600' : 'gray.400'}
              borderRadius="14px"
              height={`${BUTTON_SIZE}px`}
              key={resolution}
              onClick={() => onSelectedValue(resolution)}
              width={`${BUTTON_SIZE}px`}
            >
              <Text as="b" color={isSelected ? 'brand.600' : '#000000'}>
                {resolution}x
              </Text>
            </Center>
          );
        })}
      </HStack>
      <Text fontWeight={700}>
        {upscaledWidth} x {upscaledHeight} px
      </Text>
      <Text>{getDescription(upscaledWidth, upscaledHeight)}.</Text>
    </Box>
  );
};

export default DownloadResolutionPicker;
