import axios from 'axios';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Client } from '@/components/types';

const entity = 'clients';

const URL = `/${entity}`;

const patchClient = (client: Partial<Client>) => {
  return axios.patch<Client>(`${URL}/${client.id}`, client).then(({ data }) => data);
};

export const useUpdateClient = () => {
  const client = useQueryClient();

  const { mutate: updateClient } = useMutation((user: Partial<Client>) => patchClient(user), {
    onSuccess: () => {
      client.invalidateQueries([entity]);
      client.invalidateQueries(['me']);
    },
  });

  return {
    updateClient,
  };
};
