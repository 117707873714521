import { Icon } from '@chakra-ui/react';

const IconSubscriptionPlan = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0378 4.79492C8.2501 4.79492 6.80084 6.24417 6.80084 8.03192C6.80084 9.81966 8.2501 11.2689 10.0378 11.2689C11.8256 11.2689 13.2748 9.81966 13.2748 8.03192C13.2748 6.24417 11.8256 4.79492 10.0378 4.79492ZM8.30084 8.03192C8.30084 7.0726 9.07852 6.29492 10.0378 6.29492C10.9972 6.29492 11.7748 7.0726 11.7748 8.03192C11.7748 8.99124 10.9972 9.76892 10.0378 9.76892C9.07852 9.76892 8.30084 8.99124 8.30084 8.03192Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25C14.8325 19.25 18.75 15.3325 18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75ZM2.75 10.5C2.75 6.49594 5.99594 3.25 10 3.25C14.0041 3.25 17.25 6.49594 17.25 10.5C17.25 12.5405 16.407 14.3841 15.0503 15.7016C14.436 13.4949 12.4132 11.8777 10.0094 11.8777C7.60046 11.8777 5.57404 13.5019 4.96455 15.716C3.59914 14.3976 2.75 12.548 2.75 10.5ZM6.29585 16.7336C7.37992 17.3792 8.64666 17.75 10 17.75C11.3608 17.75 12.6339 17.3751 13.7219 16.723C13.5287 14.8419 11.9421 13.3777 10.0094 13.3777C8.07314 13.3777 6.48418 14.8474 6.29585 16.7336Z"
      fill="black"
    />
  </Icon>
);

export default IconSubscriptionPlan;
