import { useHistory } from 'react-router-dom';

import { VStack, Text, Image } from '@chakra-ui/react';

import Button from '@/components/button';

import TrainingStarted from './assets/TrainingStarted.png';

const TrainingStartedMessage = () => {
  const history = useHistory();

  const handleGoBackToList = () => history.push('/custom-art-styles');

  return (
    <VStack
      bg="#FFFFFF"
      borderRadius="20px"
      mt="12px"
      padding="75px 66px 123px 66px"
      spacing="17px"
      width="785px"
      textAlign="center"
    >
      <Image src={TrainingStarted} w={237} />
      <Text color="black.700" fontSize="26px" fontWeight={700}>
        Awesome! <br /> Training in progress
      </Text>
      <Text color="black.600" fontSize="13px">
        Your custom art style training is in progress! It may take up to 10 hours. <br /> We'll
        notify you once it's complete. Thank you for your patience.
      </Text>
      <Button mt="20px" onClick={handleGoBackToList} w="128px">
        Back to List
      </Button>
    </VStack>
  );
};

export default TrainingStartedMessage;
