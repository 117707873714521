import { ReactNode, useState } from 'react';

import {
  Card,
  CardBody,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  HStack,
} from '@chakra-ui/react';

import { sortBy } from 'lodash';

import IconSort from './icons/IconSort';

import { TableActionMenu } from './TableActionMenu';

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

type Sort = {
  property?: string;
  order: SortOrder;
};

export interface TableColumn<T> {
  getActionComponent?: (rowId: string) => ReactNode;
  formatter?: (row: T) => string;
  name: string;
  property?: string;
  customContent?: (row: T) => ReactNode;
}

const getSortedRows = (rows, sort) => {
  const { order, property } = sort;

  const rowsAscending = sortBy(rows, (row) => row[property]);

  return order === SortOrder.ASC ? rowsAscending : rowsAscending.reverse();
};

interface TableProps<T> {
  columns: TableColumn<T>[];
  rows: T[];
  title?: string;
  onDeleteItem?: (item: T) => void;
  onEditItem?: (item: T) => void;
}

const AdminTable = function <T>({ columns, onDeleteItem, onEditItem, rows, title }: TableProps<T>) {
  const [sort, setSort] = useState<Sort>(null);

  const handleSort = (property) => {
    let order = sort?.order;

    if (sort?.property === property) {
      order = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    } else {
      order = order || SortOrder.ASC;
    }

    setSort({
      property,
      order,
    });
  };

  const sortedRows = sort ? getSortedRows(rows, sort) : rows;

  return (
    <Card w="100%" bgColor="#FFFFFF" borderRadius={20}>
      <CardBody p="20px 36px 40px 33px">
        {title && (
          <Text color="black.700" fontSize="2xl" fontWeight={700} mb="24px">
            {title}
          </Text>
        )}
        <TableContainer>
          <Table variant="unstyled">
            <Thead>
              <Tr>
                {columns.map(({ name, property }) => {
                  const isSortColumn = sort?.property === property;

                  return (
                    <Th
                      cursor="pointer"
                      onClick={() => handleSort(property)}
                      key={name}
                      p="6px 0"
                      textTransform="none"
                    >
                      <HStack>
                        <Text
                          color="secondaryGray.600"
                          fontSize="sm"
                          fontWeight={500}
                          lineHeight="24px"
                        >
                          {name}
                        </Text>
                        {name === 'Action' ? null : (
                          <IconSort
                            ml="7px"
                            transform={
                              isSortColumn && sort?.order === 'ASC' ? 'rotate(180deg)' : 'none'
                            }
                          />
                        )}
                      </HStack>
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {sortedRows.map((row, index) => (
                <Tr key={index}>
                  {columns.map(
                    ({ name, property, getActionComponent, formatter, customContent }) => (
                      <Td p="8px 0" key={name}>
                        {name === 'Action' ? (
                          getActionComponent ? (
                            getActionComponent(row)
                          ) : (
                            <TableActionMenu
                              onEdit={onEditItem ? () => onEditItem(row) : null}
                              onDelete={onDeleteItem ? () => onDeleteItem(row) : null}
                            />
                          )
                        ) : customContent ? (
                          customContent(row)
                        ) : (
                          <Text color="black.700" fontSize="sm" fontWeight={700} lineHeight="24px">
                            {formatter ? formatter(row) : row[property]}
                          </Text>
                        )}
                      </Td>
                    )
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default AdminTable;
