import { Icon } from '@chakra-ui/react';

const IconCloseBanner = () => (
  <Icon
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.81984L12.4302 0.25L7.25 5.43016L2.06984 0.25L0.5 1.81984L5.68016 7L0.5 12.1802L2.06984 13.75L7.25 8.56984L12.4302 13.75L14 12.1802L8.81984 7L14 1.81984Z"
      fill="black"
    />
  </Icon>
);

export default IconCloseBanner;
