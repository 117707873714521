import { ReactElement } from 'react';

import {
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Text,
  HStack,
} from '@chakra-ui/react';

type Props = {
  onClose: () => void;
  children: ReactElement;
  title: string;
};

const AuthContainerModal = ({ onClose, children, title }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="24px" w="454px">
        <ModalBody padding="30px 60px">
          <VStack mb="10px">
            <Text color="black.600" fontSize="28px" fontWeight={700}>
              {title}
            </Text>
          </VStack>
          {children}
          <VStack>
            <HStack spacing="2px">
              <Link color="brand.600" href="https://www.ablo.ai/privacy-policy">
                Privacy
              </Link>
              <Text color="secondaryGray.600">·</Text>
              <Link color="brand.600" href="https://www.ablo.ai/terms">
                Terms
              </Link>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthContainerModal;
