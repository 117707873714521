import { Box, Hide, Image, Show, Stack, Text, VStack } from '@chakra-ui/react';

import ImageMerch from './images/voting.gif';
import { H2 } from '@/components/typography/Headings';

const VoteToWinMerch = () => (
  <Box mt={{ base: 0, lg: '64px' }}>
    <Stack direction={{ base: 'column', md: 'row' }} align="center" justify="center" spacing={0}>
      <VStack
        align="flex-start"
        mr={{ base: 0, md: '32px', lg: '125px' }}
        w={{ base: '100%', md: '360px', lg: '407px' }}
      >
        <H2
          ml={{ base: '16px', md: 0 }}
          mb={{ base: 0, md: '8px' }}
          w={{ base: '230px', md: '360px', lg: '493px' }}
          textAlign="left"
        >
          Vote for a chance to win merch
        </H2>
        <Hide above="2sm">
          <Image src={ImageMerch} mb="24px" mt="20px" w="100%" />
        </Hide>
        <Box padding={{ base: '0 16px', md: 0 }}>
          <Text fontWeight={600} mb="4px" textStyle="body">
            Support your favorite designs and win big!
          </Text>
          <Text color="secondaryDarkGray.600" textStyle="body">
            Cast your vote on the latest creations, whether they’re from fellow designers or
            renowned artists. Each vote you cast gives you a chance to win exclusive garments. Your
            voice matters—help decide which designs become the next must-have items!
          </Text>
        </Box>
      </VStack>
      <Show above="md">
        <Image borderRadius="24px" src={ImageMerch} mt="12px" w={{ '2sm': 379, lg: 462 }} h={280} />
      </Show>
    </Stack>
  </Box>
);

export default VoteToWinMerch;
