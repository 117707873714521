import { Icon } from '@chakra-ui/react';

export const IconExpand = ({ id }) => (
  <Icon
    width="25px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id={id}
  >
    <path
      d="M14.5662 4.10059V2.10059H22.5662V10.1006H20.5662V5.51483L14.7879 11.2931L13.3737 9.87884L19.152 4.10059H14.5662Z"
      fill="black"
    />
    <path
      d="M4.76709 13.8995H2.76709V21.8995H10.7671V19.8995H6.1813L11.9596 14.1213L10.5453 12.7071L4.76709 18.4853V13.8995Z"
      fill="black"
    />
  </Icon>
);
