import { Image, VStack, Text } from '@chakra-ui/react';

import Button from '@/components/button';

import EmptyWallet from './EmptyWallet.png';

type EmptyStateProps = {
  onAdd: () => void;
  message: string;
  action?: string;
};

const EmptyState = ({ action, message, onAdd }: EmptyStateProps) => (
  <VStack spacing={0}>
    <Image h="208px" mb="49px" src={EmptyWallet} w="337px" />
    <Text color="black.700" fontSize="28px" fontWeight={700} mb="26px">
      {message}
    </Text>
    {action ? <Button onClick={onAdd}>{action}</Button> : null}
  </VStack>
);

export default EmptyState;
