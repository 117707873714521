import { ReactElement } from 'react';

import { Flex, VStack, Text, HStack, Link as ChakraLink } from '@chakra-ui/react';
import IconAblo from '@/components/icons/IconAblo';

const Link = (props) => (
  <ChakraLink
    color="secondaryGray.600"
    fontSize="sm"
    fontWeight={500}
    isExternal
    textDecoration="underline"
    {...props}
  />
);

type Props = {
  children: ReactElement;
  title: string;
  subtitle?: string;
};

const AuthContainer = ({ children, title, subtitle }: Props) => (
  <Flex
    alignItems="center"
    bg="#FFFFFF"
    justifyContent="center"
    flexDirection="column"
    height="100vh"
    position="relative"
    textAlign="center"
    w="100%"
  >
    <Flex
      flexDirection="column"
      overflowY="auto"
      padding={{ base: '50px 14px 184px 14px', md: '50px 14px 14px 14px' }}
      w={{ base: '100%', md: 'auto' }}
    >
      <VStack align="flex-start" mb="10px" spacing="8px">
        <IconAblo />
        <Text as="b" color="black.700" fontSize="30px">
          {title}
        </Text>
        <Text color="secondaryGray.600" lineHeight="100%">
          {subtitle}
        </Text>
      </VStack>
      {children}
      <VStack pt="20px">
        <Text color="secondaryGray.600" fontSize="sm" fontWeight={500}>
          © 2024 Space Runners, Inc. All Rights Reserved.
        </Text>
        <HStack spacing="2px">
          <Link href="/privacy-policy">Privacy</Link>
          <Text color="secondaryGray.600">·</Text>
          <Link href="/terms">Terms</Link>
        </HStack>
      </VStack>
    </Flex>
  </Flex>
);

export default AuthContainer;
