const IconShare = ({ color = null, ...rest }) => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5158 12.2341L14.8491 3.56742C14.6976 3.41591 14.5046 3.31272 14.2945 3.27092C14.0843 3.22911 13.8665 3.25055 13.6686 3.33254C13.4706 3.41452 13.3014 3.55336 13.1824 3.7315C13.0634 3.90964 12.9998 4.11908 12.9998 4.33333V8.17366C10.0405 8.44758 7.28986 9.81594 5.28621 12.011C3.28255 14.206 2.17009 17.0697 2.1665 20.0417V21.6667C2.16649 21.8916 2.23652 22.1111 2.36687 22.2944C2.49722 22.4778 2.68143 22.616 2.89393 22.6899C3.10642 22.7639 3.33666 22.7698 3.55267 22.7069C3.76868 22.644 3.95976 22.5154 4.09937 22.339C5.16065 21.0768 6.46282 20.0388 7.9298 19.2856C9.39678 18.5324 10.9992 18.0791 12.6433 17.9522C12.6973 17.9453 12.8327 17.9348 12.9998 17.9242V21.6667C12.9998 21.8809 13.0634 22.0904 13.1824 22.2685C13.3014 22.4466 13.4706 22.5855 13.6686 22.6675C13.8665 22.7494 14.0843 22.7709 14.2945 22.7291C14.5046 22.6873 14.6976 22.5841 14.8491 22.4326L23.5158 13.7659C23.6164 13.6653 23.6962 13.5459 23.7506 13.4145C23.8051 13.2831 23.8331 13.1423 23.8331 13C23.8331 12.8577 23.8051 12.7169 23.7506 12.5855C23.6962 12.454 23.6164 12.3346 23.5158 12.2341ZM15.1665 19.0514V16.7917C15.1665 16.6494 15.1385 16.5085 15.0841 16.377C15.0297 16.2456 14.9499 16.1262 14.8493 16.0255C14.7487 15.9249 14.6292 15.8451 14.4978 15.7907C14.3663 15.7363 14.2254 15.7083 14.0832 15.7083C13.8071 15.7083 12.6793 15.7618 12.3915 15.8004C9.4715 16.0694 6.69198 17.1787 4.38924 18.9943C4.65047 16.6048 5.78395 14.3957 7.57262 12.7899C9.3613 11.1841 11.6795 10.2946 14.0832 10.2917C14.2254 10.2917 14.3663 10.2637 14.4978 10.2093C14.6292 10.1548 14.7487 10.0751 14.8493 9.97445C14.9499 9.87384 15.0297 9.7544 15.0841 9.62295C15.1385 9.4915 15.1665 9.35061 15.1665 9.20833V6.94856L21.2179 13L15.1665 19.0514Z"
      fill={color || '#212529'}
      {...rest}
    />
  </svg>
);

export default IconShare;
