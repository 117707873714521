import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  label: {
    color: 'black.600',
    fontSize: 'sm',
    lineHeight: '18px',
  },
  control: {
    border: '1px solid',
    borderRadius: '2px',
    borderColor: 'borderSecondary',
    height: '18px',
    width: '18px',
    padding: '3px',
    _active: {
      bg: 'brand.600',
    },
    _hover: {
      background: 'transparent',
    },
    _checked: {
      border: 'none',
      bg: 'brand.600',
      _hover: {
        bg: 'brand.600',
      },
    },
  },
  icon: {
    fontSize: '12px',
  },
});

export const checkboxStyle = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'sm',
  },
});
