import { Icon } from '@chakra-ui/react';

const IconEdit = (props) => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.53467 13.4185L14.5498 4.40337L13.2929 3.14648L4.27778 12.1616V13.4185H5.53467ZM6.27156 15.1963H2.5V11.4247L12.6644 1.26025C12.8311 1.09361 13.0572 1 13.2929 1C13.5286 1 13.7546 1.09361 13.9213 1.26025L16.436 3.77492C16.6026 3.94161 16.6963 4.16766 16.6963 4.40337C16.6963 4.63907 16.6026 4.86512 16.436 5.03181L6.27156 15.1963ZM2.5 16.974H18.5V18.7518H2.5V16.974Z"
      fill="#212529"
    />
  </Icon>
);

export default IconEdit;
