import AuthContainerModal from '../components/AuthContainerModal';

import SignUpForm from './SignUpForm';

interface SignUpModalProps {
  onClose: () => void;
  onGoToSignIn: () => void;
  onSignedIn: () => void;
}

export default function SignUpModal({ onGoToSignIn, onSignedIn, ...rest }: SignUpModalProps) {
  return (
    <AuthContainerModal title="Welcome to Ablo!" {...rest}>
      <SignUpForm onGoToSignIn={onGoToSignIn} onSignedIn={onSignedIn} />
    </AuthContainerModal>
  );
}
