import { Button } from '@chakra-ui/react';

interface ShowMoreButtonProps {
  onToggleLines: (e) => void;
  isCollapsed?: boolean;
}

const ShowMoreButton = ({ onToggleLines, isCollapsed }: ShowMoreButtonProps) => (
  <Button
    size="sm"
    variant="link"
    onClick={(e) => {
      e.stopPropagation();

      onToggleLines(e);
    }}
  >
    {isCollapsed ? 'Show more' : 'Show less'}
  </Button>
);

export default ShowMoreButton;
