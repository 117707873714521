import { HStack, Spacer, Text, VStack, useToast } from '@chakra-ui/react';

import { useUpdateUser } from '@/api/users';
import Button from '@/components/button';
import FormInput from '@/components/form/FormInput';
import { User } from '@/components/types';

import FormCard from './FormCard';
import { useEffect, useState } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';

type AccountSettingsProps = {
  me: User;
};

export default function AccountSettings({ me }: AccountSettingsProps) {
  const [user, setUser] = useState<Partial<User>>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const { updateUser } = useUpdateUser();

  const toast = useToast();

  const { email = '', firstName = '', lastName = '' } = user || {};

  const handleUpdate = (updates: Partial<User>) => {
    setUser({ ...user, ...updates });
  };

  const handleSave = () => {
    setIsSaving(true);

    const { email, firstName, lastName } = user;

    if (!email) {
      setEmailError("Email can't be empty");
    }

    updateUser(
      { id: me.id, firstName, lastName },
      {
        onSuccess: () => {
          toast({
            title: 'Changes saved',
            status: 'success',
          });

          setIsSaving(false);
        },
        onError: () => {
          toast({
            title: 'Error updating account',
            status: 'error',
          });

          setIsSaving(false);
        },
      }
    );
  };

  useEffect(() => {
    setUser(me);
  }, [me]);

  return (
    <FormCard description="Here you can change your account information" title="Account Settings">
      <VStack align="flex-start" spacing="35px">
        <FormInput
          error={emailError}
          name="Email Address"
          value={email}
          onChange={(e) => handleUpdate({ email: e.target.value })}
        />
        <HStack spacing="38px">
          <FormInput
            isOptional
            name="First Name"
            placeholder=""
            value={firstName}
            onChange={(e) => handleUpdate({ firstName: e.target.value })}
          />
          <FormInput
            isOptional
            name="Last Name"
            placeholder=""
            value={lastName}
            onChange={(e) => handleUpdate({ lastName: e.target.value })}
          />
        </HStack>
        <HStack w="100%">
          <Button isLoading={isSaving} onClick={handleSave}>
            Save changes
          </Button>

          <Spacer />

          <Text color="#979797" onClick={() => setIsDeleteConfirmationOpen(true)} cursor="pointer">
            Delete my account
          </Text>
        </HStack>
      </VStack>
      {isDeleteConfirmationOpen && (
        <DeleteConfirmationModal onClose={() => setIsDeleteConfirmationOpen(false)} />
      )}
    </FormCard>
  );
}
