import { Icon } from '@chakra-ui/react';

const IconEyeClosed = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4.0314L4.03116 3.00024L21.0001 19.9692L19.9689 21.0003L3 4.0314ZM12.1567 9.00418L14.9959 11.8434C14.9564 11.1032 14.6446 10.4037 14.1205 9.87957C13.5963 9.35542 12.8969 9.04363 12.1567 9.00418V9.00418ZM11.8435 14.9957L9.00431 12.1565C9.04376 12.8967 9.35555 13.5962 9.8797 14.1204C10.4038 14.6445 11.1033 14.9563 11.8435 14.9957Z"
      fill="#CBD5E0"
    />
    <path
      d="M12 16.5C11.3077 16.5 10.6248 16.3403 10.0043 16.0333C9.38376 15.7264 8.84246 15.2803 8.42248 14.73C8.00249 14.1797 7.71514 13.5398 7.58278 12.8603C7.45043 12.1808 7.47664 11.4799 7.65937 10.8122L4.42172 7.57406C3.09938 8.78531 1.83937 10.3659 0.75 12C1.98844 14.0625 3.6825 16.1831 5.44687 17.3991C7.47094 18.7931 9.67172 19.5 11.9887 19.5C13.255 19.5009 14.5117 19.282 15.7031 18.8531L13.1902 16.3406C12.8024 16.4468 12.4021 16.5004 12 16.5V16.5ZM12 7.5C12.6923 7.49997 13.3752 7.65966 13.9957 7.96665C14.6162 8.27365 15.1575 8.71966 15.5775 9.27C15.9975 9.82034 16.2849 10.4602 16.4172 11.1397C16.5496 11.8192 16.5234 12.5201 16.3406 13.1878L19.6528 16.5C21.0192 15.2695 22.2811 13.6144 23.25 12C22.0134 9.96422 20.3016 7.84875 18.5072 6.61781C16.4578 5.2125 14.2645 4.5 11.9887 4.5C10.7365 4.5018 9.49468 4.72869 8.32266 5.16984L10.8122 7.65937C11.1992 7.55344 11.5987 7.49984 12 7.5V7.5Z"
      fill="#CBD5E0"
    />
  </Icon>
);

export default IconEyeClosed;
