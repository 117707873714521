import { Icon } from '@chakra-ui/react';

const IconArrowRight = ({ fill = undefined, ...rest }) => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.064 19.9682L8.79199 18.6962L15.488 12.0002L8.79199 5.30423L10.064 4.03223L18.032 12.0002L10.064 19.9682Z"
      fill={fill || '#000000'}
    />
  </Icon>
);

export default IconArrowRight;
