import { Card, CardBody, Flex, Image, Text } from '@chakra-ui/react';

import { Stripe } from 'stripe';

import ApplePay from './images/ApplePay.png';
import CashApp from './images/CashApp.png';
import GooglePay from './images/ApplePay.png';
import NoPaymentMethod from './images/NoPaymentMethod.png';

import Button from '../../components/buttons/ButtonCTA';
import CardDetails from './CardDetails';
import USBankAccountDetails from './USBankAccountDetails';
import LinkDetails from './LinkDetails';

const ImageProps = { height: '52px', width: '131px' };

const getPaymentMethodUI = (paymentMethod: Stripe.PaymentMethod) => {
  const { card, link, type, us_bank_account: usBankAccount } = paymentMethod || {};

  if (type === 'card') {
    if (card.wallet?.apple_pay) {
      return <Image src={ApplePay} {...ImageProps} />;
    } else if (card.wallet?.google_pay) {
      return <Image src={GooglePay} {...ImageProps} />;
    }

    return <CardDetails card={card} />;
  } else if (type === 'cashapp') {
    return <Image src={CashApp} {...ImageProps} />;
  } else if (type === 'us_bank_account') {
    return <USBankAccountDetails account={usBankAccount} />;
  } else if (type === 'link') {
    return <LinkDetails link={link} />;
  }

  return <Image src={NoPaymentMethod} h="32px" w="53px" />;
};

type PaymentMethodProps = {
  onChange: () => void;
  paymentMethod: Stripe.PaymentMethod;
};

const PaymentMethod = ({ onChange, paymentMethod }: PaymentMethodProps) => (
  <Card bg="#FFFFFF" borderRadius={20} height="100%" minW={{ base: 'none', md: '350px' }}>
    <CardBody p="27px 26px">
      <Text color="black.700" fontSize="18px" fontWeight={700}>
        Payment method
      </Text>
      <Text color="secondaryDarkGray.600" fontSize="md" fontWeight={500} mb="20px">
        Change how you pay for plan.
      </Text>
      <Flex
        align="center"
        border="1px solid #D1D1D1"
        borderRadius="16px"
        justify={paymentMethod ? 'space-between' : 'center'}
        p="16px 16px"
      >
        {getPaymentMethodUI(paymentMethod)}
        <Button ml="40px" onClick={onChange} p="0 1">
          {paymentMethod ? 'Change' : 'Set'}
        </Button>
      </Flex>
    </CardBody>
  </Card>
);

export default PaymentMethod;
