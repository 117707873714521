import { Icon } from '@chakra-ui/react';

export const IconHeartEmpty = (props) => (
  <Icon xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.1674 12.5722L12.6674 20.0002L5.16742 12.5722C4.67272 12.0908 4.28306 11.5122 4.02296 10.8728C3.76287 10.2334 3.63798 9.54713 3.65615 8.85711C3.67433 8.16709 3.83518 7.48831 4.12857 6.86351C4.42197 6.23871 4.84155 5.68143 5.3609 5.22676C5.88025 4.77208 6.48812 4.42987 7.14623 4.22166C7.80433 4.01345 8.49842 3.94376 9.18478 4.01698C9.87115 4.0902 10.5349 4.30473 11.1343 4.64708C11.7337 4.98943 12.2557 5.45218 12.6674 6.00618C13.0809 5.4562 13.6036 4.9975 14.2025 4.65878C14.8015 4.32007 15.4639 4.10863 16.1483 4.0377C16.8328 3.96677 17.5245 4.03787 18.1802 4.24657C18.8359 4.45526 19.4414 4.79705 19.959 5.25054C20.4765 5.70403 20.8948 6.25946 21.1878 6.88207C21.4808 7.50468 21.6422 8.18106 21.6617 8.86889C21.6813 9.55671 21.5587 10.2412 21.3015 10.8794C21.0444 11.5177 20.6583 12.096 20.1674 12.5782"
      fill="none"
      stroke="#171923"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
