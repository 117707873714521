import { Icon } from '@chakra-ui/react';

const IconQRCode = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6705_114331)">
      <path
        d="M8.9375 2.83594H12.6875C12.9465 2.83594 13.1562 3.0457 13.1562 3.30469V7.05469C13.1562 7.31367 12.9465 7.52344 12.6875 7.52344H8.9375C8.67852 7.52344 8.46875 7.31367 8.46875 7.05469V3.30469C8.46875 3.0457 8.67852 2.83594 8.9375 2.83594ZM9.40625 6.58594H12.2188V3.77344H9.40625V6.58594Z"
        fill="black"
      />
      <path
        d="M7.0625 7.52344H3.3125C3.05352 7.52344 2.84375 7.31367 2.84375 7.05469V3.30469C2.84375 3.0457 3.05352 2.83594 3.3125 2.83594H7.0625C7.32148 2.83594 7.53125 3.0457 7.53125 3.30469V7.05469C7.53125 7.31367 7.32148 7.52344 7.0625 7.52344ZM6.59375 3.77344H3.78125V6.58594H6.59375V3.77344Z"
        fill="black"
      />
      <path
        d="M8.9375 8.46094H12.6875C12.9465 8.46094 13.1562 8.6707 13.1562 8.92969V12.6797C13.1562 12.9387 12.9465 13.1484 12.6875 13.1484H8.9375C8.67852 13.1484 8.46875 12.9387 8.46875 12.6797V8.92969C8.46875 8.6707 8.67852 8.46094 8.9375 8.46094ZM9.40625 12.2109H12.2188V9.39844H9.40625V12.2109Z"
        fill="black"
      />
      <path
        d="M4.71875 12.6797V11.2734H3.3125C3.05352 11.2734 2.84375 11.0637 2.84375 10.8047V8.92969C2.84375 8.6707 3.05352 8.46094 3.3125 8.46094H7.0625C7.32148 8.46094 7.53125 8.6707 7.53125 8.92969V12.6797C7.53125 12.9387 7.32148 13.1484 7.0625 13.1484H5.1875C4.92852 13.1484 4.71875 12.9387 4.71875 12.6797ZM5.65625 10.8047V12.2109H6.59375V9.39844H3.78125V10.3359H5.1875C5.44648 10.3359 5.65625 10.5457 5.65625 10.8047Z"
        fill="black"
      />
      <path
        d="M14.5625 6.58594C14.3035 6.58594 14.0938 6.37617 14.0938 6.11719V2.60156C14.0938 2.21367 13.7785 1.89844 13.3906 1.89844H9.875C9.61602 1.89844 9.40625 1.68867 9.40625 1.42969C9.40625 1.1707 9.61602 0.960938 9.875 0.960938H13.3906C14.2953 0.960938 15.0312 1.69688 15.0312 2.60156V6.11719C15.0312 6.37617 14.8215 6.58594 14.5625 6.58594Z"
        fill="black"
      />
      <path
        d="M2.60938 0.960938H6.125C6.38398 0.960938 6.59375 1.1707 6.59375 1.42969C6.59375 1.68867 6.38398 1.89844 6.125 1.89844H2.60938C2.22148 1.89844 1.90625 2.21367 1.90625 2.60156V6.11719C1.90625 6.37617 1.69648 6.58594 1.4375 6.58594C1.17852 6.58594 0.96875 6.37617 0.96875 6.11719V2.60156C0.96875 1.69688 1.70469 0.960938 2.60938 0.960938Z"
        fill="black"
      />
      <path
        d="M9.875 14.0859H13.3906C13.7785 14.0859 14.0938 13.7707 14.0938 13.3828V9.86719C14.0938 9.6082 14.3035 9.39844 14.5625 9.39844C14.8215 9.39844 15.0312 9.6082 15.0312 9.86719V13.3828C15.0312 14.2875 14.2953 15.0234 13.3906 15.0234H9.875C9.61602 15.0234 9.40625 14.8137 9.40625 14.5547C9.40625 14.2957 9.61602 14.0859 9.875 14.0859Z"
        fill="black"
      />
      <path
        d="M1.4375 9.39844C1.69648 9.39844 1.90625 9.6082 1.90625 9.86719V13.3828C1.90625 13.7707 2.22148 14.0859 2.60938 14.0859H6.125C6.38398 14.0859 6.59375 14.2957 6.59375 14.5547C6.59375 14.8137 6.38398 15.0234 6.125 15.0234H2.60938C1.70469 15.0234 0.96875 14.2875 0.96875 13.3828V9.86719C0.96875 9.6082 1.17852 9.39844 1.4375 9.39844Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_6705_114331">
        <rect width="15" height="15" fill="white" transform="matrix(-1 0 0 1 15.5 0.492188)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconQRCode;
