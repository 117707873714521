import { useParams } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';

import ProductDetails from './components/ProductDetails';
import { useState } from 'react';

export default function ProductDetailsGuest() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [addingDesignToCart, setAddingDesignToCart] = useState(false);
  const [designSizeToAddToCart, setDesignSizeToAddToCart] = useState<string>(null);

  const [isVotingForDesign, setIsVotingForDesign] = useState(false);

  const { id } = useParams<{ id: string }>();

  const handleSignedIn = () => {
    setSignInModalVisible(false);

    let destination = `/products/${id}`;

    if (addingDesignToCart) {
      destination = `?addingToCart=${addingDesignToCart}${
        designSizeToAddToCart ? `&selectedSize=${designSizeToAddToCart}` : ''
      }`;
    } else if (isVotingForDesign) {
      destination = `?votingForDesign=true`;
    }

    window.location.href = destination;
  };

  const handleSignInToAddToCart = (sizeId: string) => {
    setAddingDesignToCart(true);
    setDesignSizeToAddToCart(sizeId);

    setSignInModalVisible(true);
  };

  const handleSignInToVote = () => {
    setIsVotingForDesign(true);

    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={() => setSignInModalVisible(false)}
      onSignedIn={handleSignedIn}
    >
      <ProductDetails
        onSignInToAddToCart={handleSignInToAddToCart}
        onSignInToVote={handleSignInToVote}
      />
    </GuestPageContainer>
  );
}
