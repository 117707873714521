import { Icon } from '@chakra-ui/react';

const IconRedo = ({ isDisabled }) => (
  <Icon
    width="21px"
    height="20px"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6831 5.80806C15.9271 5.56398 16.3229 5.56398 16.5669 5.80806L19.0669 8.30806C19.311 8.55214 19.311 8.94786 19.0669 9.19194L16.5669 11.6919C16.3229 11.936 15.9271 11.936 15.6831 11.6919C15.439 11.4479 15.439 11.0521 15.6831 10.8081L17.7411 8.75L15.6831 6.69194C15.439 6.44786 15.439 6.05214 15.6831 5.80806Z"
      fill={isDisabled ? '#848484' : '#000000'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 12.9688C1.75 10.3474 3.85972 8.125 6.51562 8.125H18C18.3452 8.125 18.625 8.40482 18.625 8.75C18.625 9.09518 18.3452 9.375 18 9.375H6.51562C4.58091 9.375 3 11.0065 3 12.9688V13.75C3 14.0952 2.72018 14.375 2.375 14.375C2.02982 14.375 1.75 14.0952 1.75 13.75V12.9688Z"
      fill={isDisabled ? '#848484' : '#000000'}
    />
  </Icon>
);

export default IconRedo;
