import { CanvasObject } from 'ablo-demo-fe';

import { Canvas, ClipPath } from '../../types';

export const scaleObjectsForNewCanvas = (
  canvas: Canvas,
  originalClipPath: ClipPath,
  originalObjects: CanvasObject[]
) => {
  const { clipPath, _objects } = canvas;

  _objects.forEach((object, index) => {
    const originalObject = originalObjects[index];

    const objectLeft = originalObject.left - originalClipPath.left;
    const objectTop = originalObject.top - originalClipPath.top;

    const scaleFactor = clipPath.width / originalClipPath.width;

    const { scaleX, width } = object;

    const boundingRect = object.getBoundingRect();

    const actualWidth = width * scaleX;

    const adjustmentForRotation = boundingRect.width / actualWidth;

    object.set('left', clipPath.left + objectLeft * scaleFactor);
    object.set('top', clipPath.top + objectTop * scaleFactor);

    object.scaleToWidth(actualWidth * scaleFactor * adjustmentForRotation);
  });

  canvas.renderAll();
};

export const scaleObjectTops = (canvas: Canvas, originalClipPath: ClipPath) => {
  const { clipPath, _objects } = canvas;

  _objects.forEach((object) => {
    const topDiff = clipPath.top - originalClipPath.top;

    object.set('top', object.top + topDiff);
  });

  canvas.renderAll();
};
