import { Box, HStack, Image, Text } from '@chakra-ui/react';

import type { Stripe } from 'stripe';

import BankAccount from './images/BankAccount.png';

const IMAGE_WIDTH = '24px';

type USBankAccountDetailsProps = {
  account: Stripe.PaymentMethod.UsBankAccount;
};

const USBankAccountDetails = ({ account }: USBankAccountDetailsProps) => {
  const { bank_name: bankName, last4 } = account || {};

  return (
    <HStack spacing="9px">
      <Image src={BankAccount} h={IMAGE_WIDTH} w={IMAGE_WIDTH} />
      <Box fontSize="sm" fontWeight={700} mb="3px">
        <Text color="secondaryDarkGray.600">Account ending in {last4}</Text>
        <Text color="secondaryDarkGray.400">{bankName}</Text>
      </Box>
    </HStack>
  );
};

export default USBankAccountDetails;
