import { useHistory } from 'react-router-dom';

import { Box, Card, CardBody, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { format } from 'date-fns';

import { useCreditsUsedByMonth, useCreditsUsedThisMonth } from '@/api/ledger';
import Button from '@/components/button';
import { CardHeading } from '../CardHeading';
import Multiselect from '../../../../../components/multiselect';
import { useState } from 'react';
import { CreditUsageToolTip } from './credits-usage-tool-tip';

const BAR_BORDER_RADIUS = '4px';
const COLORS = ['#F56565', '#064AC4', '#68D391', '#FBD38D', '#FF6A00'];

const CreditUsage = () => {
  const { data: creditsByMonth = [] } = useCreditsUsedByMonth();
  const { data: creditsUsedThisMonth } = useCreditsUsedThisMonth();

  const [selectedBillableActions, setSelectedBillableActions] = useState<string[]>([]);

  const history = useHistory();

  const { totalCredit = 0, totalDebit = 0 } = creditsUsedThisMonth || {};

  // Calculate maximum debit for chart columns
  let maxDebit = 100000;
  if (creditsByMonth && creditsUsedThisMonth) {
    let merged = [creditsUsedThisMonth];
    if (creditsByMonth.length) {
      merged = merged.concat(creditsByMonth);
    }
    const sortedByUsage = merged.sort((cu1, cu2) => cu2.totalDebit - cu1.totalDebit);
    maxDebit = sortedByUsage[0].totalDebit;
  }

  const handleUpgrade = () => {
    history.push('/subscription');
  };

  const billableActionsSet = new Set<string>();
  creditsByMonth.forEach((ledgerPeriod) => {
    Object.keys(ledgerPeriod.debitsByBillableAction).forEach((billableAction) => {
      billableActionsSet.add(billableAction);
    });
  });
  const billableActionNames = Array.from(billableActionsSet);

  return (
    <Card bg="#FFFFFF" borderRadius="20px" flex={1} h="100%" w="100%">
      <CardBody h="100%" p="33px 30px">
        <Flex align="flex-start" justify="space-between" mb="50px" w="100%">
          <HStack spacing="35px">
            <CardHeading text="Monthly Credit Usage" />
            <Multiselect
              key={'billableAction'}
              name={'All'}
              options={billableActionNames.map((name) => ({ id: name, name }))}
              onUpdateSelectedValues={(values) => {
                setSelectedBillableActions(values);
              }}
              selectedValues={selectedBillableActions}
            />
          </HStack>
          <HStack spacing="22px">
            <VStack align="flex-start" spacing={0}>
              {creditsUsedThisMonth ? (
                <HStack spacing="2px">
                  <Text color="black.700" fontSize="20px" fontWeight={700}>
                    {totalDebit.toLocaleString()}
                  </Text>
                  <Text color="gray.700" fontSize="17px" fontWeight={700}>
                    /{totalCredit.toLocaleString()}
                  </Text>
                </HStack>
              ) : null}
              <Text color="secondaryGray.600" fontSize="sm">
                This month
              </Text>
            </VStack>
            <Button onClick={handleUpgrade}>Need more credits? Upgrade</Button>
          </HStack>
        </Flex>
        <HStack spacing="33px">
          {creditsByMonth.map((ledgerPeriod, index) => {
            const { startEpochSeconds, debitsByBillableAction } = ledgerPeriod;

            const month = format(new Date(startEpochSeconds * 1000), 'MMM');

            const isLastItem = index === creditsByMonth.length - 1;
            // Create a variable to keep track of the cumulative height as you stack boxes
            let cumulativeHeightPercent = 0;

            return (
              <VStack spacing="14px" position="relative" key={index}>
                <CreditUsageToolTip
                  ledgerPeriod={ledgerPeriod}
                  month={format(new Date(startEpochSeconds * 1000), 'MMMM')}
                  colors={COLORS}
                  billableActionNames={billableActionNames}
                >
                  <Box
                    bg="#F3F8FF"
                    borderTopLeftRadius={BAR_BORDER_RADIUS}
                    borderTopRightRadius={BAR_BORDER_RADIUS}
                    h="169px"
                    position="relative"
                    w="18px"
                  >
                    {billableActionNames.map((billableAction, index) => {
                      if (
                        selectedBillableActions.length &&
                        !selectedBillableActions.includes(billableAction)
                      ) {
                        return null;
                      }

                      const credits = debitsByBillableAction[billableAction] || 0;
                      const heightPercent = (100 * credits) / maxDebit;
                      const color = COLORS[index % COLORS.length];

                      // Define the box component with the calculated bottom position
                      const box = (
                        <Box
                          key={index}
                          bg={color}
                          borderTopLeftRadius={
                            index === billableActionNames.length - 1 ? BAR_BORDER_RADIUS : '0'
                          }
                          borderTopRightRadius={
                            index === billableActionNames.length - 1 ? BAR_BORDER_RADIUS : '0'
                          }
                          height={`${heightPercent}%`}
                          position="absolute"
                          bottom={`${cumulativeHeightPercent}%`} // Use the cumulative height for the bottom property
                          left="0"
                          w="100%"
                          zIndex={billableActionNames.length - index} // Ensure that boxes are stacked correctly
                        />
                      );

                      // Update the cumulative height for the next box
                      cumulativeHeightPercent += heightPercent;

                      return box;
                    })}
                  </Box>
                </CreditUsageToolTip>
                {isLastItem ? (
                  <Text color="brand.600" fontSize="sm" fontWeight={500}>
                    This Month
                  </Text>
                ) : (
                  <Text color="gray.700" fontSize="sm" fontWeight={500}>
                    {month}
                  </Text>
                )}
              </VStack>
            );
          })}
        </HStack>
      </CardBody>
    </Card>
  );
};

export default CreditUsage;
