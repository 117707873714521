import { Icon } from '@chakra-ui/react';

const IconSubscriptionPlanActive = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25C14.8325 19.25 18.75 15.3325 18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75ZM2.75 10.5C2.75 6.49594 5.99594 3.25 10 3.25C14.0041 3.25 17.25 6.49594 17.25 10.5C17.25 12.5405 16.407 14.3841 15.0503 15.7016C14.436 13.4949 12.4132 11.8777 10.0094 11.8777C7.60046 11.8777 5.57404 13.5019 4.96455 15.716C3.59914 14.3976 2.75 12.548 2.75 10.5ZM10.0378 4.79492C8.2501 4.79492 6.80084 6.24417 6.80084 8.03192C6.80084 9.81966 8.2501 11.2689 10.0378 11.2689C11.8256 11.2689 13.2748 9.81966 13.2748 8.03192C13.2748 6.24417 11.8256 4.79492 10.0378 4.79492ZM7.4717 14.9747C7.46325 14.9726 7.45825 14.9715 7.45726 14.9713L7.4717 14.9747Z"
      fill="black"
    />
  </Icon>
);

export default IconSubscriptionPlanActive;
