import { useQuery } from '@tanstack/react-query';
import { ablo } from './ablo-wrapper';

const CREDITS_USAGE_THIS_MONTH = 'credits-usage-this-month';
const CREDITS_USAGE_BY_MONTH = 'credits-usage-by-month';

export const useCreditsUsedByMonth = () =>
  useQuery([CREDITS_USAGE_BY_MONTH], async () => {
    const response = await ablo().ledger.creditsUsageByMonth();

    return response;
  });

export const useCreditsUsedThisMonth = () =>
  useQuery([CREDITS_USAGE_THIS_MONTH], async () => {
    const response = await ablo().ledger.creditsUsageThisMonth();

    return response;
  });
