import { Icon } from '@chakra-ui/react';

const IconDrawerMenu = () => (
  <Icon
    width="25px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    _hover={{ cursor: 'pointer' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.7001 6.89961H2.30005V5.09961H22.7001V6.89961Z" fill="black" />
    <path d="M22.7001 12.8996H2.30005V11.0996H22.7001V12.8996Z" fill="black" />
    <path d="M2.30005 18.8996H12.5001V17.0996H2.30005V18.8996Z" fill="black" />
  </Icon>
);

export default IconDrawerMenu;
