import AuthContainerModal from '../components/AuthContainerModal';

import SignInForm from './SignInForm';

interface SignInModalProps {
  onClose: () => void;
  onGoToSignUp: () => void;
  onSignedIn: () => void;
}

export default function SignInModal({ onGoToSignUp, onSignedIn, ...rest }: SignInModalProps) {
  return (
    <AuthContainerModal title="Sign In" {...rest}>
      <SignInForm onGoToSignUp={onGoToSignUp} onSignedIn={onSignedIn} />
    </AuthContainerModal>
  );
}
