interface KBWVideoProps {
  height?: string;
  width?: string;
}

const KBWVideo = ({ height, width }: KBWVideoProps) => (
  <video
    autoPlay
    style={{
      height: height || 'auto',
      objectFit: 'cover',
      width: width || 'auto',
    }}
    loop
    muted
    playsInline
    src="ABLO_KBW.mp4"
  />
);

export default KBWVideo;
