import LegalContainer from './components/LegalContainer';

import { Heading } from './components/Headings';
import Text from './components/Text';
import { Link } from '@chakra-ui/react';

const PrivacyPolicy = () => (
  <LegalContainer title="Privacy policy">
    <>
      <Heading>1. Introduction</Heading>
      <Text>
        1.1 This Privacy Policy details important information regarding the collection, use and
        disclosure of registered user (“User”) information collected through any features, tools
        and/or materials made available from time to time by Spacerunners Inc. and its related
        entities (<Text as="b">“Spacerunners”</Text> or the <Text as="b">“Group”</Text>), referred
        to herein as the "Tools" and (b) the website [insert website address] (the "Site") in the
        course of providing Users with the use of various features and services (the{' '}
        <Text as="b">“Services”</Text>). The aim of this Privacy Policy is to help you understand
        how your personal information is used and your choices regarding said use. By using the Site
        or the Tools, you agree that the Group can collect, use, disclose, and process your
        information as described in this Privacy Policy. This Privacy Policy only applies to the
        Site and the Tools, and not to any other websites, products or services you may be able to
        access or link to via the Site or the Tools. We encourage you to read the privacy policies
        of any other websites you visit before providing your information to them.The Site and the
        Tools may evolve over time, and this Privacy Policy will change to reflect that evolution.
        If changes are made, you will be notified by revising the date at the top of this Privacy
        Policy. In some cases, if significant changes are made, a statement may be placed in the
        homepage. We encourage you to review this Privacy Policy periodically to stay informed.Some
        third-party providers may place cookies or pixels - small data files stored on your hard
        drive or in device memory - on your browser or hard drive. Note that this Privacy Policy
        does not cover the use of cookies or pixels by such third parties. Most web browsers are set
        to accept cookies and pixels by default, but you can usually set your browser to remove or
        reject browser cookies or pixels. If you do choose to remove or reject cookies or pixels,
        however, your ability to use the Site or the Tools might be affected.
      </Text>
      <Text>
        1.2 By accessing the Tools or the Site, or utilising the Services, you are consenting to the
        information collection and use practices described in this Privacy Policy.
      </Text>
      <Text>
        1.3 Your use of the [Spacerunners website, Ablo AI] client, and any personal information you
        provide through the Tools remains subject to the terms of this Privacy Policy and the Terms
        of Use, as each may be updated from time to time.
      </Text>
      <Text>
        1.4 Any questions, comments or complaints that you might have should be emailed to{' '}
        <Link color="brand.600" href="mailto:privacy@spacerunners.com">
          privacy@spacerunners.com
        </Link>
      </Text>
      <Heading>2. Information Collected in the Site and the Tools</Heading>
      <Text>The personal information collected from you generally may include:</Text>
      <Text>
        2.1 Network information regarding transactions, including, among other things, the type of
        device you use, access times, hardware model, operating system and version, and other unique
        device identifiers.
      </Text>
      <Text>
        2.2. Information about plugins you might be using, included but not limited to those related
        to the management of cryptocurrency assets and any information provided by them.
      </Text>
      <Text>
        2.3 The Tools may require the highest level of browser permissions that could potentially
        lead to procurement of more personal information. Information on these permissions are used
        for a limited purpose, and why this is necessary, can be found in paragraph 3 below.
      </Text>
      <Heading>3. The Way your Personal Information is used</Heading>
      <Text>
        3.1 As with nearly all interactions that take place on the World Wide Web, the servers may
        receive information by virtue of your interaction with them, including but not limited to IP
        Addresses.
      </Text>
      <Text>
        3.2 Some of the Tools require full browser permissions that could potentially be used to
        access additional personal information. Such browser permissions are used for an extremely
        limited technical purpose for allowing the Tools to properly interact with your browser. No
        additional information is obtained beyond what is necessary to provide the Services. No
        information received is shared with any third-party except as required for provision of the
        Services.
      </Text>
      <Text>
        3.3 Public blockchains provide transparency into transactions and the Group is not
        responsible for preventing or managing information broadcasted on a blockchain.
      </Text>
      <Heading>4. What Is Done With Your Information</Heading>
      <Text>
        4.1 Your information may be used in the following ways:
        <br /> - To analyze trends for how the Sitevand Tools are being used;
        <br /> - To improve the Site and the Tools;
        <br /> - To help personalize yourvexperience of the Site and the Tools; and
        <br /> - If you provide your contact information, you may receive technical notices,
        updates, confirmations, security alerts, to provide support to you, to tell you about other
        products and services that might interest you, or to respond to your comments or questions.
      </Text>
      <Text>
        4.2 Your information may be shared with third parties who need to access it in order to do
        work related to the Site, Services and the Tools, including doing things like helping make
        the Site, Tools or Services available, or providing analytics services. These third parties
        only access and use your information as necessary to perform their functions.
      </Text>
      <Text>
        4.3 Aggregations and anonymizations that contain your information may be created in a way
        that does not directly identify you. Those aggregations and anonymizations may be used or
        shared for a variety of purposes related to the Site, Tools and the Services.
      </Text>
      <Text>
        4.4 Your personal information may be disclosed to agents, businesses, or service providers
        who process your personal information for providing the Site and the Tools to you. The
        agreements with these service providers limit the kinds of information they can use or
        process and ensure they use reasonable efforts to keep your personal information secure.
      </Text>
      <Text>
        4.5 The Group also reserves the right to disclose personal information that it believes, in
        good faith, is appropriate or necessary to enforce the Terms of Use, take precautions
        against liability or harm, to investigate and respond to third-party claims or allegations,
        to respond to court orders or official requests, to protect the security or integrity of the
        Site or the Tools, and to protect the rights, property, or safety of the Group, the Group’s
        community of users, or others.
      </Text>
      <Text>
        4.6 In the event that a Group entity is involved in a merger, acquisition, sale, bankruptcy,
        insolvency, reorganization, receivership, assignment for the benefit of creditors, or the
        application of laws or equitable principles affecting creditors’ rights generally, or other
        change of control, there may be a disclosure of your information to another entity related
        to such event.
      </Text>
      <Heading>5. Your Choice</Heading>
      <Text>
        Your personal information will be processed in accordance with this Privacy Policy, and as
        part of that you will have limited or no opportunity to otherwise modify how your
        information is used.
      </Text>
      <Heading>6. Cookies </Heading>
      <Text>
        Cookies are used over the course of providing the Services, through the Site and the Tools.
        These are used to understand and retain your preferences for future visits, and to compile
        aggregate data about user traffic and interaction so that we can offer a better experience
        in the future.
      </Text>
      <Heading>7. Information Not Collected</Heading>
      <Text>
        Any other personally-identifiable information about you shall not be collected, unless you
        give it to the Group directly: by filling out a form, giving written feedback, communicating
        via third party social media sites, or otherwise communicating via the Site, the Tools or
        any other means.
      </Text>
      <Heading>8. Information Security</Heading>
      <Text>
        Whilst neither the Group, nor any other organization, can guarantee the security of
        information processed online, the Group has appropriate security measures in place to
        protect your personal information. For example, the personal information you provide is
        stored on computer systems with limited access, encryption, or both.
      </Text>
      <Heading>9. Privacy Rights</Heading>
      <Text>
        9.1 Subject to applicable law, you may have some or all of the following rights in respect
        of your personal information: (i) to obtain a copy of your personal information together
        with information about how and on what basis that personal information is processed; (ii) to
        rectify inaccurate personal information (including the right to have incomplete personal
        information completed); (iii) to erase your personal information (in limited circumstances,
        where it is no longer necessary in relation to the purposes for which it was collected or
        processed); (iv) to restrict processing of your personal information where: a. the accuracy
        of the personal information is contested; b. the processing is unlawful but you object to
        the erasure of the personal information; or c. we no longer require the personal information
        but it is still required for the establishment, exercise or defense of a legal claim; (v) to
        challenge processing which we have justified on the basis of a legitimate interest (as
        opposed to your consent, or to perform a contract with you); (vi) to prevent us from sending
        you direct marketing; (vii) to withdraw your consent to our processing of your personal
        information (where that processing is based on your consent); (viii) to object to decisions
        which are based solely on automated processing or profiling; (ix) in addition to the above,
        you have the right to file a complaint with the supervisory authority.
      </Text>
      <Text>
        9.2. If you wish to investigate the exercising of any of these rights, please write to us at
        the following address{' '}
        <Link color="brand.600" href="mailto:privacy@spacerunners.com">
          privacy@spacerunners.com
        </Link>
        .
      </Text>
      <Heading>10. Changes and Updates</Heading>
      <Text>
        10.1 This Privacy Policy may be revised periodically and this will be reflected by the “Last
        update posted” date above. Please revisit this page to stay aware of any changes. Your
        continued use of the Services or the Tools constitutes your agreement to this Privacy Policy
        and any future revisions.
      </Text>
      <Text>
        10.2 Contact Information:{' '}
        <Link color="brand.600" href="mailto:privacy@spacerunners.com">
          privacy@spacerunners.com
        </Link>
      </Text>
    </>
  </LegalContainer>
);

export default PrivacyPolicy;
