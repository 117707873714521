import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { Currency } from '@/components/types';

const entity = `currencies`;

const getCurrencies = () => axios.get<Currency[]>(`/${entity}`).then(({ data }) => data);

export const useCurrencies = () => useQuery([entity], () => getCurrencies());
