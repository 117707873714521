import { ReactNode, useEffect, useState } from 'react';

import { Box, BoxProps, Flex, useBreakpointValue } from '@chakra-ui/react';

import { StorageKeys } from '@/constants';

import Sidebar from '@/components/sidebar';
import Navbar from '@/components/navbar';
import CartContent from '@/views/cart/CartContent';
import { useHistory, useLocation } from 'react-router-dom';

const { IS_SIDEBAR_COLLAPSED } = StorageKeys;

interface AppContainerProps {
  children: ReactNode;
  hideSidebar?: boolean;
  rightSideNavbarContent?: ReactNode;
  contentContainerProps?: BoxProps;
}

export default function AppContainer({
  children,
  hideSidebar,
  contentContainerProps,
  rightSideNavbarContent,
}: AppContainerProps) {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const isCartDrawerVisible = !isMobile && searchParams.get('showCart');

  const handleToggleSidebarCollapsed = (isCollapsed) => {
    localStorage.setItem(IS_SIDEBAR_COLLAPSED, isCollapsed);

    setSidebarCollapsed(isCollapsed);
  };

  const isCollapsed = JSON.parse(localStorage.getItem(IS_SIDEBAR_COLLAPSED));

  useEffect(() => {
    setSidebarCollapsed(isCollapsed);
  }, [isCollapsed]);

  return (
    <Box>
      <Navbar rightSideContent={rightSideNavbarContent} />
      <Flex w="100%" position="relative">
        {!hideSidebar ? (
          <Sidebar
            isCollapsed={isSidebarCollapsed}
            onChangeCollapsed={handleToggleSidebarCollapsed}
          />
        ) : null}
        <Box
          flex={1}
          h={{ base: 'calc(100vh - 70px)', md: 'calc(100vh - 80px)' }}
          overflow={isCartDrawerVisible ? 'hidden' : 'auto'}
          p="20px"
          position="relative"
          {...contentContainerProps}
        >
          {children}
          {isCartDrawerVisible ? (
            <Box position="absolute" w="408px" right={0} top={0} h="100%" overflow="auto">
              <CartContent />
            </Box>
          ) : null}
        </Box>
        {isCartDrawerVisible ? (
          <Box
            bg="rgba(10, 23, 47, 0.2)"
            position="absolute"
            onClick={() => {
              if (isCartDrawerVisible) {
                history.replace('?showCart');
              }
            }}
            left={0}
            top={0}
            width="calc(100% - 408px)"
            height="100%"
          />
        ) : null}
      </Flex>
    </Box>
  );
}
