import { Text, VStack, Box, HStack, Stack, Image } from '@chakra-ui/react';

import { H3, H4, H5 } from '@/components/typography/Headings';
import CreateDesign from './icons/CreateDesign';
import PublishDesign from './icons/PublishDesign';
import SellMerch from './icons/SellMerch';
import StoryBadge from '@/assets/StoryBadge.png';
import Button from '@/components/button';
import ModalContainer from '@/components/modals/ModalContainer';

const STEPS = [
  {
    icon: CreateDesign,
    title: 'Create your design',
    description:
      'Use our AI design tools or upload your own custom design to bring your merch to life.',
  },
  {
    icon: PublishDesign,
    title: 'Publish Your Design',
    description:
      'Publish your design with a quick and simple 1-click process. Full IP registration options will be available soon!',
  },
  {
    icon: SellMerch,
    title: 'Sell your merch',
    description:
      'Once your design is published, your design will be available for sale in our Merch Shop. You’ll earn 15% in royalties with every purchase and be able to claim them in the near future!',
  },
  {
    image: StoryBadge,
    title: 'Earn a Story Badge',
    description:
      'Upon your first successful sale, you’ll receive an exclusive Story Badge, celebrating your creative achievement.',
  },
];

type Props = {
  onClose?: () => void;
  onSelectTemplate: () => void;
};

const RoyaltiesAndBadgeModal = ({ onClose, onSelectTemplate }: Props) => (
  <ModalContainer onClose={onClose} width={{ base: 'auto', md: '580px' }}>
    <>
      <H3 mb="6px" w={{ base: '260px', md: '420px' }}>
        Design Merch + Earn a Story Badge & Earn Royalties On Your Sales!
      </H3>
      <Text mb={{ base: '16px', md: '24px' }} textStyle="body">
        Create unique merch on ABLO, publish your design, and start accruing royalties per sale,
        payable in the near future. Plus, earn a special Story Badge with your first successful
        purchase!
      </Text>
      <Box
        border="1px solid"
        borderColor="borderSecondary"
        borderRadius="12px"
        mb={{ base: '18px', md: '24px' }}
        p={{ base: '18px', md: '24px' }}
      >
        <H4 mb={{ base: '16px', md: '20px' }}>How it works</H4>
        <VStack spacing={{ base: '21px', md: '20px' }}>
          {STEPS.map((step, index) => {
            const { icon: Icon, image, title, description } = step;

            return (
              <HStack align="flex-start" gap={{ base: '16px', md: '20px' }} key={index}>
                {image ? (
                  <Image
                    src={image}
                    h={{ base: '40px', md: '54px' }}
                    w={{ base: '40px', md: '54px' }}
                  />
                ) : (
                  <Icon />
                )}
                <Box>
                  <H5 fontSize={{ base: '14px', md: '16px' }}>{title}</H5>
                  <Text color="secondaryDarkGray.600" textStyle="body">
                    {description}
                  </Text>
                </Box>
              </HStack>
            );
          })}
        </VStack>
      </Box>
      <Stack direction={{ base: 'column', md: 'row' }} gap="12px" w="100%">
        <Button onClick={onSelectTemplate} flex={{ base: 'auto', md: 1 }}>
          Select template
        </Button>
        <Button onClick={onClose} flex={{ base: 'auto', md: 1 }} secondary>
          Go back
        </Button>
      </Stack>
    </>
  </ModalContainer>
);

export default RoyaltiesAndBadgeModal;
