import { Box, HStack, Image, Text } from '@chakra-ui/react';

import { capitalize } from 'lodash';

import type { Stripe } from 'stripe';

import Amex from './card-brands/Amex.png';
import Diners from './card-brands/Diners.png';
import Discover from './card-brands/Discover.png';
import JCB from './card-brands/JCB.png';
import MasterCard from './card-brands/MasterCard.png';
import Visa from './card-brands/Visa.png';

const IMAGES_FOR_BRANDS = {
  amex: Amex,
  diners: Diners,
  discover: Discover,
  jsb: JCB,
  mastercard: MasterCard,
  visa: Visa,
};

type CardDetailsProps = {
  card: Stripe.PaymentMethod.Card;
};

const CardDetails = ({ card }: CardDetailsProps) => {
  const { brand, exp_month: expMonth, exp_year: expYear, last4 } = card || {};

  const brandImage = IMAGES_FOR_BRANDS[brand];

  return (
    <HStack align="flex-start" spacing="5px">
      {brandImage ? <Image src={brandImage} h="35px" w="49px" /> : null}
      <Box>
        <Text color="secondaryDarkGray.600" fontSize="sm" fontWeight={700} mb="3px">
          {capitalize(brand)} ending in {last4}
        </Text>
        <Text color="secondaryDarkGray.400" fontSize="sm" fontWeight={700} mb="3px">
          Expiry {expMonth}/{expYear}
        </Text>
      </Box>
    </HStack>
  );
};

export default CardDetails;
