import { H3 } from '@/components/typography/Headings';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';

interface Feature {
  title: string;
  subtitle: string;
}

interface FeatureCardProps {
  entity: string;
  image: string;
  features: Feature[];
}

const FeatureCard = ({ entity, image, features }: FeatureCardProps) => (
  <Box>
    <Image
      borderRadius={{ base: 0, md: '12px' }}
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      mb="24px"
      h={240}
      objectFit="cover"
      src={image}
      w={{ base: '100%', md: 'auto' }}
    />
    <H3 mb={{ base: '12px', md: '16px' }} ml={{ base: '16px', md: 0 }}>
      For {entity}
    </H3>
    <VStack align="flex-start" p={{ base: '0 16px', md: 0 }} spacing="12px">
      {features.map(({ title, subtitle }, index) => (
        <Box key={index}>
          <HStack spacing="12px">
            <Box bg="#000000" h="6px" w="6px" />
            <Text fontWeight={600} textStyle="body">
              {title}
            </Text>
          </HStack>
          <Text color="secondaryDarkGray.600" ml="18px" textStyle="body">
            {subtitle}
          </Text>
        </Box>
      ))}
    </VStack>
  </Box>
);

export default FeatureCard;
