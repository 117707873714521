import ToolType from './ToolTypes';
import IconFavorites from './icons/IconFavorites';
import IconFontToImage from './icons/IconFontToImage';
import IconImageToImage from './icons/IconImageToImage';
import IconTextToImage from './icons/IconTextToImage';

export const VIEWS = [
  {
    name: ToolType.TEXT_TO_IMAGE,
    label: 'Image Maker',
    icon: <IconTextToImage />,
    iconActive: <IconTextToImage />,
  },
  {
    name: ToolType.FONT_TO_IMAGE,
    icon: <IconFontToImage />,
    label: 'Font Maker',
    iconActive: <IconFontToImage />,
  },
  {
    name: ToolType.IMAGE_TO_IMAGE,
    icon: <IconImageToImage />,
    label: 'Upload',
    iconActive: <IconImageToImage />,
  },
  {
    name: ToolType.FAVORITES,
    icon: <IconFavorites />,
    label: 'Favorites',
    iconActive: <IconFavorites />,
  },
];
