import { useState } from 'react';

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';

import IconEye from '@/components/icons/IconEye';
import IconEyeClosed from '@/components/icons/IconEyeClosed';

const Input = (props) => (
  <ChakraInput
    padding="16px 14px"
    height="50px"
    bg="#EDF2F7"
    borderRadius="14px"
    variant="auth"
    fontSize="md"
    fontWeight="400"
    _placeholder={{
      color: '#A0AEC0',
    }}
    {...props}
  />
);

const PasswordInput = (props) => {
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input placeholder="Enter password" {...props} type={show ? 'text' : 'password'} />
      <InputRightElement top="5px">
        <Button
          bg="transparent"
          h="100%"
          minW="none"
          size="sm"
          onClick={handleClick}
          w="20px"
          tabIndex={-1}
        >
          {show ? <IconEyeClosed /> : <IconEye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

function FormInput(props) {
  const { error, isPassword, isOptional = false, label, ...rest } = props;

  return (
    <FormControl w="100%">
      {label ? (
        <FormLabel display="flex" fontSize="15px" fontWeight={400} color="#212529" mb="6px">
          {label}
          {!isOptional ? (
            <Text color="#DC3545" ml="4px">
              *
            </Text>
          ) : null}
        </FormLabel>
      ) : null}
      {isPassword ? <PasswordInput {...rest} /> : <Input {...rest} />}
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
}

export default FormInput;
