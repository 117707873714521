import { Icon } from '@chakra-ui/react';

const IconAmazon = () => (
  <Icon
    width={{ base: '99px', md: '150px' }}
    height={{ base: '32px', md: '48px' }}
    viewBox="0 0 150 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="48" fill="white" />
    <g clipPath="url(#clip0_205_1544)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.9924 36.3022C79.8793 40.1427 72.4677 42.1872 66.0849 42.1872C57.1379 42.1872 49.0837 38.8216 42.9882 33.2165C42.5093 32.7762 42.9357 32.1754 43.5134 32.5151C50.0908 36.4123 58.2254 38.7587 66.6256 38.7587C72.2916 38.7587 78.5231 37.5603 84.254 35.0849C85.1191 34.7106 85.842 35.6636 84.9986 36.3022H84.9924Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1188 33.8299C86.4639 32.9775 82.7967 33.4241 81.1469 33.6254C80.6464 33.6852 80.5692 33.2417 81.0202 32.9208C83.9459 30.8292 88.7439 31.4331 89.3 32.1314C89.8623 32.8391 89.1517 37.7333 86.4113 40.0703C85.9881 40.4289 85.5895 40.237 85.7749 39.7621C86.3928 38.1925 87.7738 34.6791 87.1188 33.8267V33.8299Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.2723 18.1375V16.1025C81.2723 15.7942 81.504 15.5866 81.779 15.5866H90.7322C91.0195 15.5866 91.2481 15.7973 91.2481 16.0993V17.8419C91.2481 18.1344 91.0041 18.515 90.5746 19.122L85.9374 25.8626C87.6582 25.8217 89.481 26.0828 91.0442 26.9792C91.3964 27.1805 91.4922 27.4793 91.52 27.7718V29.9422C91.52 30.241 91.1987 30.587 90.862 30.4077C88.1062 28.9388 84.4483 28.7784 81.4021 30.4265C81.09 30.5964 80.7656 30.2536 80.7656 29.9547V27.8914C80.7656 27.5611 80.7718 26.9981 81.0993 26.4948L86.4718 18.6471H81.7944C81.5071 18.6471 81.2785 18.4395 81.2785 18.1344L81.2723 18.1375Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.6115 30.8385H45.8866C45.6271 30.8228 45.4201 30.6215 45.3984 30.3699V16.137C45.3984 15.8508 45.6363 15.6243 45.9267 15.6243H48.4632C48.7289 15.6369 48.942 15.8413 48.9575 16.1024V17.9613H49.01C49.6712 16.1653 50.9162 15.3255 52.5938 15.3255C54.2713 15.3255 55.365 16.1653 56.1281 17.9613C56.7893 16.1653 58.2876 15.3255 59.8911 15.3255C61.0342 15.3255 62.2823 15.8036 63.0423 16.8825C63.9074 18.0809 63.7282 19.8171 63.7282 21.3458V30.3259C63.7282 30.6089 63.4903 30.8385 63.1999 30.8385H60.4781C60.2062 30.8197 59.9899 30.5995 59.9899 30.329V22.7832C59.9899 22.1856 60.0394 20.6852 59.9127 20.1191C59.7088 19.1597 59.1002 18.8924 58.3124 18.8924C57.6512 18.8924 56.9654 19.3422 56.6842 20.0593C56.4031 20.7796 56.4309 21.9748 56.4309 22.7832V30.3259C56.4309 30.6089 56.1961 30.8385 55.9057 30.8385H53.1839C52.912 30.8197 52.6957 30.5995 52.6957 30.329V22.7832C52.6957 21.1948 52.946 18.8609 51.0151 18.8609C49.0842 18.8609 49.1336 21.1382 49.1336 22.7832V30.3259C49.1336 30.6089 48.8988 30.8385 48.6084 30.8385H48.6115Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9545 15.3255C102.996 15.3255 105.183 18.8609 105.183 23.3525C105.183 27.8441 102.767 31.1405 98.9545 31.1405C95.1421 31.1405 92.8281 27.6051 92.8281 23.2047C92.8281 18.8043 95.0124 15.3255 98.9545 15.3255ZM98.9792 18.2318C96.9711 18.2318 96.8444 21.0155 96.8444 22.7549C96.8444 24.4943 96.8197 28.2059 98.9545 28.2059C101.089 28.2059 101.167 25.2115 101.167 23.384C101.167 22.1856 101.117 20.7481 100.759 19.6095C100.453 18.6219 99.8443 18.2318 98.9792 18.2318Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.423 30.8385H107.711C107.439 30.8197 107.223 30.5995 107.223 30.329V16.0898C107.241 15.8288 107.467 15.6243 107.742 15.6243H110.269C110.507 15.6369 110.701 15.8005 110.754 16.0238V18.2004H110.806C111.57 16.2534 112.635 15.3255 114.517 15.3255C115.737 15.3255 116.933 15.7753 117.696 17.002C118.406 18.1406 118.406 20.0562 118.406 21.4338V30.3888C118.376 30.6404 118.153 30.8385 117.884 30.8385H115.153C114.9 30.8228 114.699 30.6341 114.668 30.3888V22.6605C114.668 21.1036 114.847 18.8263 112.966 18.8263C112.305 18.8263 111.693 19.2761 111.39 19.9649C111.01 20.8331 110.958 21.7012 110.958 22.6605V30.3227C110.952 30.6058 110.717 30.8354 110.426 30.8354L110.423 30.8385Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1397 24.0413C74.1397 25.1233 74.1644 26.0229 73.6299 26.9823C73.1974 27.7623 72.5085 28.2436 71.7454 28.2436C70.7011 28.2436 70.0894 27.4321 70.0894 26.2337C70.0894 23.8715 72.1717 23.4437 74.1397 23.4437V24.0445V24.0413ZM76.8862 30.8008C76.707 30.9643 76.4475 30.9769 76.2436 30.8668C75.3384 30.1025 75.1777 29.7471 74.6834 29.0205C73.1881 30.5712 72.1284 31.0367 70.1914 31.0367C67.899 31.0367 66.1133 29.5961 66.1133 26.7149C66.1133 24.4628 67.312 22.9342 69.0174 22.1824C70.4941 21.5219 72.5579 21.4024 74.1397 21.2231V20.8645C74.1397 20.204 74.1922 19.4239 73.8091 18.8546C73.4785 18.345 72.8421 18.1343 72.2798 18.1343C71.2418 18.1343 70.318 18.6753 70.0894 19.7982C70.0431 20.0467 69.8639 20.2952 69.6167 20.3078L66.9783 20.0184C66.7559 19.9681 66.5087 19.7856 66.5705 19.4396C67.1761 16.1779 70.0709 15.1934 72.6598 15.1934C73.9852 15.1934 75.7184 15.5519 76.7626 16.5742C78.088 17.8355 77.9613 19.5151 77.9613 21.3457V25.6644C77.9613 26.9634 78.4927 27.5327 78.9901 28.2341C79.1631 28.4858 79.2033 28.7877 78.9809 28.9702C78.4248 29.4451 77.4361 30.3195 76.8924 30.8134L76.8862 30.8071V30.8008Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4335 24.0414C38.4335 25.1234 38.4582 26.023 37.9237 26.9823C37.4912 27.7624 36.8022 28.2436 36.0391 28.2436C34.9949 28.2436 34.3832 27.4321 34.3832 26.2337C34.3832 23.8715 36.4655 23.4438 38.4335 23.4438V24.0445V24.0414ZM41.18 30.8008C41.0008 30.9644 40.7382 30.977 40.5374 30.8669C39.6322 30.1026 39.4715 29.7471 38.9772 29.0205C37.4819 30.5712 36.4222 31.0367 34.4882 31.0367C32.1959 31.0367 30.4102 29.5962 30.4102 26.715C30.4102 24.4629 31.6089 22.9342 33.3142 22.1825C34.791 21.5219 36.8548 21.4024 38.4366 21.2231V20.8646C38.4366 20.204 38.486 19.424 38.106 18.8547C37.7754 18.3451 37.139 18.1344 36.5767 18.1344C35.5386 18.1344 34.6149 18.6754 34.3863 19.7983C34.3399 20.0468 34.1608 20.2952 33.9136 20.3078L31.2752 20.0184C31.0528 19.9681 30.8056 19.7857 30.8674 19.4397C31.4698 16.1716 34.3616 15.1871 36.9536 15.1871C38.279 15.1871 40.0122 15.5457 41.0564 16.568C42.3818 17.8293 42.2551 19.5089 42.2551 21.3395V25.6581C42.2551 26.9572 42.7865 27.5265 43.2839 28.2279C43.4569 28.4795 43.4971 28.7815 43.2746 28.9639C42.7185 29.4389 41.7299 30.3133 41.1862 30.8071L41.18 30.8008Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_205_1544">
        <rect width="88" height="27" fill="white" transform="translate(30.4062 15.1871)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconAmazon;
