import { Fragment as F } from 'react';
import { Box, Button as ChakraButton } from '@chakra-ui/react';

const Button = ({ children = null, icon = null, iconRight = null, title = null, ...rest }) => {
  return (
    <ChakraButton {...rest}>
      {children || (
        <F>
          {icon ? <Box mr="8px">{icon}</Box> : null}
          {title}
          {iconRight || null}
        </F>
      )}
    </ChakraButton>
  );
};

export default Button;
