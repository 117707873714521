import axios from 'axios';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Invite, User, UserRole } from '@/components/types';

interface UpdateUserRequest extends Partial<User> {
  roleId?: number;
}

const entity = 'users';
const entityInvites = 'invites';

const URL = `/${entity}`;
const URL_INVITES = `${URL}/${entityInvites}`;

const getUsers = () => axios.get<User[]>(URL).then(({ data }) => data);

const getInvites = () => axios.get<Invite[]>(URL_INVITES).then(({ data }) => data);

export const useUsers = () => useQuery([entity], () => getUsers());

export const useInvites = () => useQuery([entityInvites], () => getInvites());

const getUserRoles = () => axios.get<UserRole[]>(`${URL}/roles`).then(({ data }) => data);

export const useUserRoles = () => useQuery(['roles'], () => getUserRoles());

const createUser = (user: User): Promise<User> => {
  return axios.post<User>(URL, user).then(({ data }) => data);
};

export const useCreateInvites = () => {
  const client = useQueryClient();

  const { mutate: createInvites } = useMutation(
    (emails: string[]): Promise<Invite[]> => {
      const dto = {
        emails,
      };
      return axios.post(URL_INVITES, dto).then(({ data }) => data);
    },
    {
      onSuccess: () => client.invalidateQueries([entityInvites]),
    }
  );

  return {
    createInvites,
  };
};

const patchUser = (user: UpdateUserRequest) => {
  return axios.patch<User>(`${URL}/${user.id}`, user).then(({ data }) => data);
};

export const saveUser = (user: User) => {
  const method = user.id ? patchUser : createUser;

  return method(user);
};

export const useUpdateUser = () => {
  const client = useQueryClient();

  const { mutate: updateUser } = useMutation((user: UpdateUserRequest) => patchUser(user), {
    onSuccess: () => {
      client.invalidateQueries([entity]);
      client.invalidateQueries(['me']);
    },
  });

  return {
    updateUser,
  };
};

export const useDeleteSelfUser = () => {
  const client = useQueryClient();

  const { mutate: removeUser } = useMutation(() => axios.delete(`${URL}`), {
    onSuccess: () => client.invalidateQueries([entity]),
  });

  return {
    removeUser,
  };
};

export const useDeleteUser = () => {
  const client = useQueryClient();

  const { mutate: removeUser } = useMutation((id: string) => axios.delete(`${URL}/${id}`), {
    onSuccess: () => client.invalidateQueries([entity]),
  });

  return {
    removeUser,
  };
};

export const useDeleteInvite = () => {
  const client = useQueryClient();

  const { mutate: removeInvite } = useMutation(
    (id: string) => axios.delete(`${URL_INVITES}/${id}`),
    {
      onSuccess: () => client.invalidateQueries([entityInvites]),
    }
  );

  return {
    removeInvite,
  };
};

export const changePassword = (oldPassword: string, newPassword: string) =>
  axios
    .patch('/auth/change-password', {
      oldPassword,
      newPassword,
    })
    .then(({ data }) => data);
