import { Button, Text, VStack } from '@chakra-ui/react';

const ToolbarButton = ({
  icon,
  isDisabled = false,
  isHighlighted = false,
  isSelected = null,
  text = null,
  ...rest
}) => {
  return (
    <Button
      background={isSelected ? '#EDF2F7' : 'transparent'}
      borderRadius="7px"
      disabled={isDisabled}
      h="47px"
      padding="4px 4px 8px 3px"
      width="auto"
      {...(isHighlighted ? { bg: '#FFFFFF', borderRadius: 10, p: '7px', zIndex: 4 } : {})}
      {...rest}
    >
      <VStack spacing="4px">
        {icon}
        {text ? (
          <Text as="b" fontSize="9px" color={isDisabled ? '#848484' : '#000000'} lineHeight="11px">
            {text}
          </Text>
        ) : null}
      </VStack>
    </Button>
  );
};

export default ToolbarButton;
