import { useHistory } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';

import { Show } from '@chakra-ui/react';
import ButtonBack from '@/components/navbar/ButtonBack';

import CollectionsList from './components/CollectionList';

export default function CollectionListGuest() {
  const history = useHistory();

  const handleSignedIn = () => {
    window.location.href = '/collections';
  };

  return (
    <GuestPageContainer
      onSignedIn={handleSignedIn}
      rightSideNavbarContent={
        <Show above="md">
          <ButtonBack onClick={() => history.replace('/')} />
        </Show>
      }
    >
      <CollectionsList />
    </GuestPageContainer>
  );
}
