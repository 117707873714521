import { useEffect, useRef, useState } from 'react';

import { Box, HStack, Image, VStack, useToast } from '@chakra-ui/react';

import { updateTemplateSide } from '@/api/templates';

import FormInput from '@/components/form/FormInput';
import { Template, TemplateColor, TemplateSide } from '@/components/types';

import FormContainer from '../components/FormContainer';

import Dropdown from '@/components/form/Dropdown';
import { getScalingFactor } from '../utils';

import { Text } from '@chakra-ui/react';

const UNITS = ['px', 'cm'];

const RESIZE_HANDLE_WIDTH = 15;
const MIN_DRAWING_AREA_WIDTH = 45;

const ResizeHandle = (props) => (
  <Box
    bg="brand.600"
    height={`${RESIZE_HANDLE_WIDTH}px`}
    position="absolute"
    width={`${RESIZE_HANDLE_WIDTH}px`}
    {...props}
  />
);

const IMAGE_WIDTH = 300;

type DesignAreaProps = {
  onNext: (updates: object) => void;
  onUpdate: (updates: object) => void;
  templateColors: TemplateColor[];
  templateSides: TemplateSide[];
  template: Template;
};

type InitialDragOrResizePosition = {
  corner: string;
  left: number;
  top: number;
  drawingAreaWidth: number;
  drawingAreaHeight: number;
  mouseLeft: number;
  mouseTop: number;
};

const DesignArea = ({
  onNext,
  onUpdate,
  templateColors,
  templateSides,
  template,
}: DesignAreaProps) => {
  const [activeSide, setActiveSide] = useState(null);
  const [colors, setColors] = useState<TemplateColor[]>([]);
  const [sides, setSides] = useState<TemplateSide[]>([]);
  const [waiting, setWaiting] = useState(false);

  const draggable = useRef(null);

  const [isDragging, setDragging] = useState(false);
  const [isResizing, setResizing] = useState(false);

  const [initialDragOrResizePosition, setInitialDragOrResizePosition] =
    useState<InitialDragOrResizePosition>(null);

  const { id } = template;

  const toast = useToast();

  useEffect(() => {
    setColors(templateColors);
    setSides(templateSides);

    setActiveSide(templateSides[0]?.name);
  }, [templateColors, templateSides]);

  useEffect(() => {
    window.addEventListener('mouseup', endResize);

    return () => window.removeEventListener('mouseup', endResize);
  });

  const handleUpdateDrawingArea = (updates) => {
    const newSides = sides.map((side) => {
      if (side.name === activeSide) {
        return {
          ...side,
          ...updates,
        };
      }

      return side;
    });

    setSides(newSides);
  };

  const handleNext = () => {
    if (!id) {
      onNext(sides);

      return;
    }

    setWaiting(true);

    Promise.all(sides.map((side) => updateTemplateSide(id, side)))
      .then(() => {
        toast({
          title: 'Changes saved',
          status: 'success',
        });

        onUpdate(sides);
      })
      .catch((e) => {
        toast({
          title: 'Error saving template',
          description: e.response?.data?.message,
          status: 'error',
        });
      })
      .finally(() => setWaiting(false));
  };

  const side = sides.find((side) => side.name === activeSide);

  let { width = 0, height = 0, left = 0, top = 0 } = side || {};

  const {
    manufacturingAreaWidth,
    manufacturingAreaHeight,
    manufacturingImageWidth,
    manufacturingImageHeight,
    manufacturingImageTop,
    manufacturingImageLeft,
  } = side || {};

  const { unit } = side || {};

  const scalingFactor = getScalingFactor(IMAGE_WIDTH);

  width = Math.round(width * scalingFactor);
  height = Math.round(height * scalingFactor);
  left = Math.round(left * scalingFactor);
  top = Math.round(top * scalingFactor);

  const handleMouseDown = (e, corner?) => {
    const { offsetLeft, offsetTop } = draggable.current;

    setInitialDragOrResizePosition({
      corner,
      left: e.pageX - offsetLeft,
      top: e.pageY - offsetTop,
      drawingAreaWidth: width,
      drawingAreaHeight: height,
      mouseLeft: e.pageX,
      mouseTop: e.pageY,
    });

    if (corner) {
      setResizing(true);
    } else {
      setDragging(true);
    }
  };

  const endResize = () => {
    setDragging(false);
    setResizing(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging && !isResizing) {
      return;
    }

    if (isResizing) {
      handleResize(e);

      return;
    }

    const image = document.getElementById('template-image');

    const { left: startingLeft, top: startingTop } = initialDragOrResizePosition;

    let newLeft = e.pageX - startingLeft;
    let newTop = e.pageY - startingTop;

    if (newLeft < 0) {
      newLeft = 0;
    } else if (newLeft > IMAGE_WIDTH - width) {
      newLeft = IMAGE_WIDTH - width;
    }

    if (newTop < 0) {
      newTop = 0;
    } else if (newTop > image.offsetHeight - height) {
      newTop = image.offsetHeight - height;
    }

    handleUpdateDrawingArea({
      left: Math.round(newLeft / scalingFactor),
      top: Math.round(newTop / scalingFactor),
    });
  };

  const handleResize = (e) => {
    const {
      corner,
      left: originalLeft,
      top: originalTop,
      drawingAreaWidth: originalWidth,
      drawingAreaHeight: originalHeight,
      mouseLeft,
      mouseTop,
    } = initialDragOrResizePosition;

    let newLeft = left;
    let newTop = top;
    let newWidth = width;
    let newHeight = height;

    const image = document.getElementById('template-image');

    if (corner === 'middleBottom') {
      newHeight = originalHeight + (e.pageY - mouseTop);
    } else if (corner === 'bottomRight' || corner === 'middleRight') {
      newWidth = originalWidth + (e.pageX - mouseLeft);

      if (corner === 'bottomRight') {
        newHeight = originalHeight + (e.pageY - mouseTop);
      }
    } else if (corner === 'bottomLeft' || corner === 'middleLeft') {
      newWidth = originalWidth - (e.pageX - mouseLeft);

      if (corner === 'bottomLeft') {
        newHeight = originalHeight + (e.pageY - mouseTop);
      }

      if (newWidth >= MIN_DRAWING_AREA_WIDTH) {
        newLeft = e.pageX - originalLeft;
      } else {
        newWidth = width;
      }
    } else if (corner === 'topRight' || corner === 'middleTop') {
      if (corner === 'topRight') {
        newWidth = originalWidth + (e.pageX - mouseLeft);
      }

      newHeight = originalHeight - (e.pageY - mouseTop);

      if (newHeight > MIN_DRAWING_AREA_WIDTH) {
        newTop = e.pageY - originalTop;
      } else {
        newHeight = height;
      }
    } else {
      newWidth = originalWidth - (e.pageX - mouseLeft);

      if (newWidth >= MIN_DRAWING_AREA_WIDTH) {
        newLeft = e.pageX - originalLeft;
      } else {
        newWidth = width;
      }

      newHeight = originalHeight - (e.pageY - mouseTop);

      if (newHeight >= MIN_DRAWING_AREA_WIDTH) {
        newTop = e.pageY - originalTop;
      } else {
        newHeight = height;
      }
    }

    if (newLeft < 0) {
      newLeft = 0;
    }

    if (newTop < 0) {
      newTop = 0;
    }

    if (newWidth < MIN_DRAWING_AREA_WIDTH) {
      newWidth = MIN_DRAWING_AREA_WIDTH;
    } else if (newLeft + newWidth > IMAGE_WIDTH) {
      newWidth = IMAGE_WIDTH - newLeft;
    }

    if (newHeight < MIN_DRAWING_AREA_WIDTH) {
      newHeight = MIN_DRAWING_AREA_WIDTH;
    } else if (newTop + newHeight > image.offsetHeight) {
      newHeight = image.offsetHeight - newTop;
    }

    const updates = {
      left: Math.round(newLeft / scalingFactor),
      top: Math.round(newTop / scalingFactor),
      width: Math.round(newWidth / scalingFactor),
      height: Math.round(newHeight / scalingFactor),
    } as Partial<TemplateSide>;

    handleUpdateDrawingArea(updates);
  };

  const selectedUnit = unit || UNITS[0];

  return (
    <Box onMouseMove={handleMouseMove} onMouseUp={endResize}>
      <FormContainer
        description="Please assign designable area"
        onNext={handleNext}
        nextLabel={id && 'Save'}
        title="Design Area"
        waiting={waiting}
      >
        <Box position="absolute" right="66px" top="50px">
          <Dropdown
            options={sides.map((side) => ({ ...side, id: side.name }))}
            onSelectedValue={setActiveSide}
            selectedValue={activeSide}
          />
        </Box>
        <VStack align="start">
          <HStack mt="20px" spacing="20px">
            <Box position="relative">
              <Image
                id="template-image"
                src={
                  template.id
                    ? colors?.[0]?.images[side.id]?.image ||
                      `${colors?.[0]?.images[side.id]?.url}?timestamp=${Date.now()}`
                    : colors?.[0]?.images[activeSide]
                }
                w={`${IMAGE_WIDTH}px`}
                userSelect="none"
              />
              <Box
                border="1px dashed"
                borderColor="gray.500"
                cursor={isDragging ? 'grabbing' : 'grab'}
                left={`${left}px`}
                onMouseDown={handleMouseDown}
                top={`${top}px`}
                position="absolute"
                ref={draggable}
                w={`${width}px`}
                h={`${height}px`}
              >
                <ResizeHandle
                  cursor="nwse-resize"
                  left={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'topLeft');
                  }}
                  top={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                />
                <ResizeHandle
                  cursor="nesw-resize"
                  left={`${width - RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'topRight');
                  }}
                  top={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                />
                <ResizeHandle
                  cursor="ew-resize"
                  left={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                  top={`${height / 2 - RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'middleLeft');
                  }}
                />
                <ResizeHandle
                  cursor="ns-resize"
                  left={`${width / 2 - RESIZE_HANDLE_WIDTH / 2}px`}
                  top={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'middleTop');
                  }}
                />
                <ResizeHandle
                  cursor="ns-resize"
                  left={`${width / 2 - RESIZE_HANDLE_WIDTH / 2}px`}
                  top={`${height - RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'middleBottom');
                  }}
                />
                <ResizeHandle
                  cursor="ew-resize"
                  left={`${width - RESIZE_HANDLE_WIDTH / 2}px`}
                  top={`${height / 2 - RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'middleRight');
                  }}
                />
                <ResizeHandle
                  cursor="nesw-resize"
                  left={`${-RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'bottomLeft');
                  }}
                  top={`${height - RESIZE_HANDLE_WIDTH / 2}px`}
                />
                <ResizeHandle
                  cursor="nwse-resize"
                  left={`${width - RESIZE_HANDLE_WIDTH / 2}px`}
                  onMouseDown={(e) => {
                    e.stopPropagation();

                    handleMouseDown(e, 'bottomRight');
                  }}
                  top={`${height - RESIZE_HANDLE_WIDTH / 2}px`}
                />
              </Box>
            </Box>
            <VStack align="flex-start" spacing="18px">
              <Dropdown
                name="Choose unit"
                options={UNITS.map((unit) => ({ name: unit, id: unit }))}
                onSelectedValue={(unit) => handleUpdateDrawingArea({ unit })}
                selectedValue={selectedUnit}
                width="114px"
              />
              <FormInput
                name={`Width (${selectedUnit})`}
                placeholder="Width"
                value={width}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    width: Math.round(parseFloat(e.target.value) / scalingFactor) || 0,
                  })
                }
              />
              <FormInput
                name={`Length (${selectedUnit})`}
                placeholder="Length"
                value={height}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    height: Math.round(parseFloat(e.target.value) / scalingFactor) || 0,
                  })
                }
              />
            </VStack>
          </HStack>
          <Text color="black.600" fontSize="18px" mb="3">
            Manufacturing Info
          </Text>
          <HStack w="100%">
            <VStack flex={1}>
              <FormInput
                name="Manufacturing area width"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingAreaWidth || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingAreaWidth: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
              <FormInput
                name="Manufacturing area height"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingAreaHeight || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingAreaHeight: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
              <FormInput
                name="Manufacturing image width"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingImageWidth || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingImageWidth: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
            </VStack>
            <VStack flex={1}>
              <FormInput
                name="Manufacturing image height"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingImageHeight || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingImageHeight: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
              <FormInput
                name="Manufacturing image top"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingImageTop || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingImageTop: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
              <FormInput
                name="Manufacturing image left"
                placeholder="Enter value..."
                isOptional
                min={0}
                value={manufacturingImageLeft || ''}
                onChange={(e) =>
                  handleUpdateDrawingArea({
                    manufacturingImageLeft: Math.round(parseFloat(e.target.value)),
                  })
                }
              />
            </VStack>
          </HStack>
        </VStack>
      </FormContainer>
    </Box>
  );
};

export default DesignArea;
