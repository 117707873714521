import { Text as ChakraText } from '@chakra-ui/react';

import { Heading, SubHeading } from './components/Headings';
import LegalContainer from './components/LegalContainer';

const Text = (props) => <ChakraText fontSize="16px" {...props} />;

const TermsOfService = () => (
  <LegalContainer title="Terms of service">
    <>
      <Text alignSelf="flex-start" fontSize="16px">
        AGREEMENT TO OUR LEGAL TERMS
      </Text>
      <Text>
        We are Spacerunners Inc., ("Company," "we," "us," "our"), a company registered in Three
        Sugar Creek Blvd Suite 100 Sugar Land, Texas 77478 US.
      </Text>
      <Text>
        We operate the website https://ablo.ai (the "Site"), as well as any other related products
        and services that refer or link to these legal terms (the "Legal Terms") (collectively, the
        "Services").
      </Text>
      <Text>You can contact us by email at contact@spacerunners.com.</Text>
      <Text>
        These Legal Terms constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity ("you"), and Spacerunners Inc., concerning your access
        to and use of the Services. You agree that by accessing the Services, you have read,
        understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL
        OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
        DISCONTINUE USE IMMEDIATELY.
      </Text>
      <Text>
        Supplemental terms and conditions or documents that may be posted on the Services from time
        to time are hereby expressly incorporated herein by reference. We reserve the right, in our
        sole discretion, to make changes or modifications to these Legal Terms from time to time. We
        will alert you about any changes by updating the "Last updated" date of these Legal Terms,
        and you waive any right to receive specific notice of each such change. It is your
        responsibility to periodically review these Legal Terms to stay informed of updates. You
        will be subject to, and will be deemed to have been made aware of and to have accepted, the
        changes in any revised Legal Terms by your continued use of the Services after the date such
        revised Legal Terms are posted.
      </Text>
      <Text>We recommend that you print a copy of these Legal Terms for your records.</Text>
      <Heading>1. OUR SERVICES</Heading>
      <Text>
        The information provided when using the Services is not intended for distribution to or use
        by any person or entity in any jurisdiction or country where such distribution or use would
        be contrary to law or regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who choose to access the
        Services from other locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are applicable.
      </Text>
      <Text>
        The Services are not tailored to comply with industry-specific regulations, so if your
        interactions would be subjected to such laws, you may not use the Services.
      </Text>
      <Text>
        Ablo grants individuals the right to utilize the (copyrighted) Ablo design tool to design
        their own garments, which individuals can then purchase. Some of its features include
        offering services to generate graphical content powered by AI, enabling the placement and
        sizing of artwork on a garment design, inputting custom text of various fonts and colors,
        uploading artworks from devices, and more. This tool provides an innovative product
        customization experience for Ablo’s customers, powered by AI.
      </Text>
      <Text>
        Ablo’s customers may, by using the interface provided on the Site that references these
        Terms, utilise the Services in a manner that results in a transaction being carried out,
        whether for the use of Ablo’s design tool, or for the purchase of garments (each an
        “Order”). An Order may be executed in various ways, as may be appropriate.
      </Text>
      <Text>
        As part of the usage of the Services, text prompts will be utilised to generate images. The
        Company reserves all rights to collect, store and process all text prompts in connection
        with the continued improvement of the Services or otherwise.
      </Text>
      <Text>
        The Order (and any additional Orders entered into by the Parties) is hereby incorporated by
        reference into these Terms.
      </Text>
      <Text>
        The Parties agree that the terms and conditions of these Terms shall apply to and govern
        each Order. In the event of any conflict between these Terms and an Order, these specific
        conflicting terms of the Order shall govern.
      </Text>
      <Heading>2. INTELLECTUAL PROPERTY RIGHTS</Heading>
      <SubHeading>Our intellectual property</SubHeading>
      <Text>
        We are the owner or the licensee of all intellectual property rights in our Services,
        including all source code, databases, functionality, software, website designs, audio,
        video, text, photographs, and graphics in the Services (collectively, the "Content"), as
        well as the trademarks, service marks, and logos contained therein (the "Marks").
      </Text>
      <Text>
        Our Content and Marks are protected by copyright and trademark laws (and various other
        intellectual property rights and unfair competition laws) and treaties in the United States
        and around the world.
      </Text>
      <Text>
        The Content and Marks are provided in or through the Services "AS IS" for your personal,
        non-commercial use or internal business purpose only.
      </Text>
      <SubHeading>Your use of our Services</SubHeading>
      <Text>
        Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES"
        section below, we grant you a non-exclusive, non-transferable, revocable license to: access
        the Services; and to reproduce, feature, display or otherwise utilise the product resulting
        from the use of our Services as appropriate and as agreed in the Order.
      </Text>
      <Text>
        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services
        and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed,
        or otherwise exploited for any commercial purpose whatsoever, without our express prior
        written permission.
      </Text>
      <Text>
        If you wish to make any use of the Services, Content, or Marks other than as set out in this
        section or elsewhere in our Legal Terms, please address your request to:
        contact@spacerunners.com. If we ever grant you the permission to post, reproduce, or
        publicly display any part of our Services or Content, you must identify us as the owners or
        licensors of the Services, Content, or Marks and ensure that any copyright or proprietary
        notice appears or is visible on posting, reproducing, or displaying our Content.
      </Text>
      <Text>
        We reserve all rights not expressly granted to you in and to the Services, Content, and
        Marks.
      </Text>
      <Text>
        Any breach of these Intellectual Property Rights will constitute a material breach of our
        Legal Terms and your right to use our Services will terminate immediately.
      </Text>
      <Text>
        The use of the Services entails the risk of potential copyright infringement. Customers must
        ensure strict compliance with all applicable copyright laws and regulations and take full
        responsibility for any legal consequences or liabilities arising from copyright issues
        resulting from the use of the Services. We take no responsibility, and expressly exclude all
        liability for any such infringement that may arise
      </Text>
      <Heading>3. USER REPRESENTATIONS</Heading>
      <Text>
        By using the Services, you represent and warrant that: (1) you have the legal capacity and
        you agree to comply with these Legal Terms; (2) you will not use the Services for any
        illegal or unauthorized purpose; and (3) your use of the Services will not violate any
        applicable law or regulation.
      </Text>
      <Text>
        If you provide any information that is untrue, inaccurate, not current, or incomplete, we
        have the right to terminate and refuse any and all current or future use of the Services (or
        any portion thereof).
      </Text>
      <Heading>4. PAYMENT</Heading>
      <Text>
        You agree to pay all charges as set out in any Order and on such terms contained therein. We
        reserve the right to correct any errors or mistakes in pricing, even if we have already
        requested or received payment.
      </Text>
      <Heading>5. PROHIBITIED ACTIVITIES</Heading>
      <Text>
        You may not access or use the Services for any purpose other than that for which we make the
        Services available. The Services may not be used in connection with any commercial endeavors
        except those that are specifically endorsed or approved by us.
      </Text>
      <Text>
        As a user of the Services, you agree not to: Systematically retrieve data or other content
        from the Services to create or compile, directly or indirectly, a collection, compilation,
        database, or directory without written permission from us. Trick, defraud, or mislead us and
        other users, especially in any attempt to learn sensitive account information such as user
        passwords.
      </Text>
      <Text>
        Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services. Use any
        information obtained from the Services in order to harass, abuse, or harm another person.
      </Text>
      <Text>
        Use the Services in a manner inconsistent with any applicable laws or regulations.
      </Text>
      <Text>Engage in unauthorized framing of or linking to the Services.</Text>
      <Text>
        Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing
        any portion of the Services to you.
      </Text>
      <Text>
        Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML,
        JavaScript, or other code
      </Text>
      <Text>
        Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer
        any of the software comprising or in any way making up a part of the Services.
      </Text>
      <Text>
        Use the Services as part of any effort to compete with us or otherwise use the Services
        and/or the Content for any revenue-generating endeavor or commercial enterprise save as
        agreed in any Order.
      </Text>
      <Heading>6. SERVICES MANAGEMENT</Heading>
      <Text>
        We reserve the right, but not the obligation, to: (1) monitor the Services for violations of
        these Legal Terms; (2) take appropriate legal action against anyone who, in our sole
        discretion, violates the law or these Legal Terms, including without limitation, reporting
        such user to law enforcement authorities; (3) in our sole discretion and without limitation,
        notice, or liability, to remove from the Services or otherwise disable all files and content
        that are excessive in size or are in any way burdensome to our systems; and (4) otherwise
        manage the Services in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Services.
      </Text>
      <Heading>7. PRIVACY POLICY</Heading>
      <Text>
        We care about data privacy and security. Please review our Privacy Policy: [ ]. By using the
        Services, you agree to be bound by our Privacy Policy, which is incorporated into these
        Legal Terms. Please be advised the Services are hosted in the United States. If you access
        the Services from any other region of the world with laws or other requirements governing
        personal data collection, use, or disclosure that differ from applicable laws in the United
        States, then through your continued use of the Services, you are transferring your data to
        the United States, and you expressly consent to have your data transferred to and processed
        in the United States. Further, we do not knowingly accept, request, or solicit information
        from children or knowingly market to children. Therefore, in accordance with the U.S.
        Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under
        the age of 13 has provided personal information to us without the requisite and verifiable
        parental consent, we will delete that information from the Services as quickly as is
        reasonably practical.
      </Text>
      <Heading>8. COPYRIGHT INFRINGEMENTS</Heading>
      <Text>
        We respect the intellectual property rights of others. If you believe that any material
        available on or through the Services infringes upon any copyright you own or control, please
        immediately notify us using the contact information provided below (a "Notification"). A
        copy of your Notification will be sent to the person who posted or stored the material
        addressed in the Notification. Please be advised that pursuant to applicable law you may be
        held liable for damages if you make material misrepresentations in a Notification. Thus, if
        you are not sure that material located on or linked to by the Services infringes your
        copyright, you should consider first contacting an attorney.
      </Text>
      <Heading>9. TERM AND TERMINATION</Heading>
      <Text>
        These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT
        LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
        DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
        (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
        CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
        USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </Text>
      <Heading>10. MODIFICATIONS AND INTERRUPTIONS</Heading>
      <Text>
        We reserve the right to change, modify, or remove the contents of the Services at any time
        or for any reason at our sole discretion without notice. However, we have no obligation to
        update any information on our Services. We also reserve the right to modify or discontinue
        all or part of the Services without notice at any time. We will not be liable to you or any
        third party for any modification, price change, suspension, or discontinuance of the
        Services.
      </Text>
      <Text>
        We cannot guarantee the Services will be available at all times. We may experience hardware,
        software, or other problems or need to perform maintenance related to the Services,
        resulting in interruptions, delays, or errors. We reserve the right to change, revise,
        update, suspend, discontinue, or otherwise modify the Services at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever for any loss, damage,
        or inconvenience caused by your inability to access or use the Services during any downtime
        or discontinuance of the Services. Nothing in these Legal Terms will be construed to
        obligate us to maintain and support the Services or to supply any corrections, updates, or
        releases in connection therewith.
      </Text>
      <Text>
        We reserve the right to modify the contents of these Legal Terms at any time or for any
        reason at our sole discretion without notice. The latest version of these Legal Terms will
        be updated at this web address [www.ablo.ai] and will be applicable to any provision of the
        Services regardless of the date of the relevant Order.
      </Text>
      <Heading>11. GOVERNING LAW</Heading>
      <Text>
        These Legal Terms and your use of the Services are governed by and construed in accordance
        with the laws of the State of California applicable to agreements made and to be entirely
        performed within the State of California, without regard to its conflict of law principles.
      </Text>
      <Heading>12. DISPUTE RESOLUTION</Heading>
      <SubHeading>Informal Negotiations</SubHeading>
      <Text>
        To expedite resolution and control the cost of any dispute, controversy, or claim related to
        these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you
        or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first
        attempt to negotiate any Dispute (except those Disputes expressly provided below) informally
        for at least 30 days before initiating arbitration. Such informal negotiations commence upon
        written notice from one Party to the other Party.
      </Text>
      <SubHeading>Binding Arbitration</SubHeading>
      <Text>
        If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute
        (except those Disputes expressly excluded below) will be finally and exclusively resolved by
        binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
        SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under
        the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and, where
        appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA Consumer
        Rules"), both of which are available at the American Arbitration Association (AAA) website.
        Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA
        Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. If such costs are
        determined by the arbitrator to be excessive, we will pay all arbitration fees and expenses.
        The arbitration may be conducted in person, through the submission of documents, by phone,
        or online. The arbitrator will make a decision in writing, but need not provide a statement
        of reasons unless requested by either Party. The arbitrator must follow applicable law, and
        any award may be challenged if the arbitrator fails to do so. Except where otherwise
        required by the applicable AAA rules or applicable law, the arbitration will take place in
        Los Angeles County , California. Except as otherwise provided herein, the Parties may
        litigate in court to compel arbitration, stay proceedings pending arbitration, or to
        confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
      </Text>
      <Text>
        If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be
        commenced or prosecuted in the state and federal courts located in Los Angeles County ,
        California, and the Parties hereby consent to, and waive all defenses of lack of personal
        jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such state
        and federal courts. Application of the United Nations Convention on Contracts for the
        International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are
        excluded from these Legal Terms.
      </Text>
      <Text>
        In no event shall any Dispute brought by either Party related in any way to the Services be
        commenced more than one (1) years after the cause of action arose. If this provision is
        found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or unenforceable and such
        Dispute shall be decided by a court of competent jurisdiction within the courts listed for
        jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that
        court.
      </Text>
      <SubHeading>Restrictions</SubHeading>
      <Text>
        The Parties agree that any arbitration shall be limited to the Dispute between the Parties
        individually. To the full extent permitted by law, (a) no arbitration shall be joined with
        any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on
        a class-action basis or to utilize class action procedures; and (c) there is no right or
        authority for any Dispute to be brought in a purported representative capacity on behalf of
        the general public or any other persons.
      </Text>
      <SubHeading>Exceptions to Informal Negotiations and Arbitration</SubHeading>
      <Text>
        The Parties agree that the following Disputes are not subject to the above provisions
        concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or
        protect, or concerning the validity of, any of the intellectual property rights of a Party;
        (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of
        privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is
        found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or unenforceable and such
        Dispute shall be decided by a court of competent jurisdiction within the courts listed for
        jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that
        court.
      </Text>
      <Heading>13. CORRECTIONS</Heading>
      <Text>
        There may be information on the Services that contains typographical errors, inaccuracies,
        or omissions, including descriptions, pricing, availability, and various other information.
        We reserve the right to correct any errors, inaccuracies, or omissions and to change or
        update the information on the Services at any time, without prior notice.
      </Text>
      <Heading>14. DISCLAIMER</Heading>
      <Text>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
        SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE
        APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
        ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
        PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
        SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, AND/OR (5) ANY BUGS, VIRUSES, TROJAN
        HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY.
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
        SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </Text>
      <Heading>15. LIMITATIONS OF LIABILITY</Heading>
      <Text>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
        PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
        DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
        YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
        CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
        $100.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
        IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
        HAVE ADDITIONAL RIGHTS.
      </Text>
      <Heading>16. INDEMNIFICATION</Heading>
      <Text>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
        affiliates, and all of our respective officers, agents, partners, and employees, from and
        against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees
        and expenses, made by any third party due to or arising out of: (1) use of the Services; (2)
        breach of these Legal Terms; (3) any breach of your representations and warranties set forth
        in these Legal Terms; or (4) your violation of the rights of a third party, including but
        not limited to intellectual property rights. Notwithstanding the foregoing, we reserve the
        right, at your expense, to assume the exclusive defense and control of any matter for which
        you are required to indemnify us, and you agree to cooperate, at your expense, with our
        defense of such claims. We will use reasonable efforts to notify you of any such claim,
        action, or proceeding which is subject to this indemnification upon becoming aware of it.
      </Text>
      <Heading>17. USER DATA</Heading>
      <Text>
        We will maintain certain data that you transmit to the Services for the purpose of managing
        the performance of the Services, as well as data relating to your use of the Services.
        Although we perform regular routine backups of data, you are solely responsible for all data
        that you transmit or that relates to any activity you have undertaken using the Services.
        You agree that we shall have no liability to you for any loss or corruption of any such
        data, and you hereby waive any right of action against us arising from any such loss or
        corruption of such data.
      </Text>
      <Heading>18. 18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</Heading>
      <Text>
        Visiting the Services, sending us emails, and completing online forms constitute electronic
        communications. You consent to receive electronic communications, and you agree that all
        agreements, notices, disclosures, and other communications we provide to you electronically,
        via email and on the Services, satisfy any legal requirement that such communication be in
        writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
        RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
        INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or
        requirements under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by any means other than electronic means.
      </Text>
      <Heading>19. MISCELLANEOUS</Heading>
      <Text>
        These Legal Terms and any policies or operating rules posted by us on the Services or in
        respect to the Services constitute the entire agreement and understanding between you and
        us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not
        operate as a waiver of such right or provision. These Legal Terms operate to the fullest
        extent permissible by law. We may assign any or all of our rights and obligations to others
        at any time. We shall not be responsible or liable for any loss, damage, delay, or failure
        to act caused by any cause beyond our reasonable control. If any provision or part of a
        provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that
        provision or part of the provision is deemed severable from these Legal Terms and does not
        affect the validity and enforceability of any remaining provisions. There is no joint
        venture, partnership, employment or agency relationship created between you and us as a
        result of these Legal Terms or use of the Services. You agree that these Legal Terms will
        not be construed against us by virtue of having drafted them. You hereby waive any and all
        defenses you may have based on the electronic form of these Legal Terms and the lack of
        signing by the parties hereto to execute these Legal Terms.
      </Text>
      <Heading>20. CONTACT US</Heading>
      <Text>
        In order to resolve a complaint regarding the Services or to receive further information
        regarding use of the Services, please contact us at:
      </Text>
      <Text>
        Spacerunners Inc <br />
        Suite 100
        <br />
        Sugar Land, Texas 77478, USA
        <br />
        contact@spacerunners.com
      </Text>
    </>
  </LegalContainer>
);

export default TermsOfService;
