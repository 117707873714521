import { useMemo } from 'react';

import { uniq, uniqBy } from 'lodash';

import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import InputSearch from '@/components/input-search';
import Multiselect from '@/components/multiselect';
import { Option, Template, TemplateFilters } from '@/components/types';

type TemplateFilter = {
  name: string;
  property: string;
  options: Option<string>[];
};

const isLightColor = (hex) => hex.toLowerCase() === '#ffffff';

const getCustomOptionRenderer = (
  property: string
): ((name: string, value: string) => React.ReactNode) => {
  if (property === 'color') {
    return (name, value) => {
      const hasBorder = isLightColor(name);

      return (
        <HStack>
          <Box
            borderRadius="50%"
            border={hasBorder ? '1px solid #000000' : 'none'}
            h="24px"
            w="24px"
            bg={name}
          />
          <Text>{value}</Text>
        </HStack>
      );
    };
  }

  return null;
};

const getTemplateFilters = (templates: Template[]): TemplateFilter[] => {
  const templateTypes = uniq(templates.map(({ name }) => name)).map((name) => ({
    id: name,
    name,
  }));

  const colors = templates.reduce((result, { colors }) => {
    const newColors = uniqBy(colors, 'name').filter(
      ({ name }) => !result.find((item) => item.name === name)
    );

    return [...result, ...newColors];
  }, []);

  return [
    { name: 'Template Styles', property: 'type', options: templateTypes },
    {
      name: 'Template Colors',
      property: 'color',
      options: colors.map(({ name, hex }) => ({ id: name, name: hex })),
    },
  ];
};

type TemplateFilterPickerProps = {
  templates: Template[];
  filters: TemplateFilters;
  onSelectedFilters: (updates: object) => void;
  search: string;
  onSearchChange: (search: string) => void;
};

export default function TemplateFilterPicker({
  templates,
  filters,
  onSelectedFilters,
  search,
  onSearchChange,
}: TemplateFilterPickerProps) {
  const templateFilters = useMemo(() => getTemplateFilters(templates), [templates]);

  return (
    <Flex justify="space-between" pb="21px" w="100%" pr="43px">
      <HStack spacing="13px">
        {templateFilters.map((filter) => {
          const { name, options, property } = filter;

          return (
            <Multiselect
              customOptionRenderer={getCustomOptionRenderer(property)}
              key={property}
              name={name}
              options={options}
              onUpdateSelectedValues={(values) => {
                onSelectedFilters({
                  ...filters,
                  [property]: values,
                });
              }}
              selectedValues={filters[property]}
            />
          );
        })}
      </HStack>
      <HStack>
        <InputSearch search={search} onSearchChange={onSearchChange} />
      </HStack>
    </Flex>
  );
}
