import { CardBody, VStack, Text, Image, HStack } from '@chakra-ui/react';
import { capitalize } from 'lodash';

import StyleCardContainer from './StyleCardContainer';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';

const IMAGE_SIZE = 118;

interface StyleCardProps {
  style: IStyle;
}

const getStatusLabel = (trainingStatus) =>
  capitalize(trainingStatus.split('TRAINING_')[1].toLowerCase());

export default function StyleCard({ style }: StyleCardProps) {
  const { imageUrl, name, trainingStatus } = style;

  return (
    <StyleCardContainer>
      <CardBody p="13px 11px 21px 11px">
        <HStack align="flex-start" spacing="14px">
          <Image borderRadius="50%" h={IMAGE_SIZE} w={IMAGE_SIZE} src={imageUrl} fit="cover" />
          <VStack align="flex-start">
            <Text fontWeight={600}>{name}</Text>
            <Text color="brand.600" fontSize="13px" fontWeight={500} w="100%">
              {getStatusLabel(trainingStatus)}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </StyleCardContainer>
  );
}
