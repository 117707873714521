import { Icon } from '@chakra-ui/react';

const IconUpload = () => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect x="0.608453" y="0.306696" width="28.7832" height="28.7832" rx="4.07309" fill="white" />
    <rect
      x="0.608453"
      y="0.306696"
      width="28.7832"
      height="28.7832"
      rx="4.07309"
      stroke="#C8CDD9"
      strokeWidth="0.543079"
    />
    <path
      d="M10.1121 13.4763L6.44629 18.364H19.8875L14.9998 11.0325L11.334 15.9202L10.1121 13.4763Z"
      fill="#C8CDD9"
    />
    <path
      d="M23.554 17.1421H21.1101V20.8079H17.4443V23.2517H21.1101V26.9175H23.554V23.2517H27.2198V20.8079H23.554V17.1421Z"
      fill="#C8CDD9"
    />
  </Icon>
);

export default IconUpload;
