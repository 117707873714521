import { Icon } from '@chakra-ui/react';

const IconClose = (props) => (
  <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 4L4 12Z" fill="white" />
    <path
      d="M12 4L4 12"
      stroke="white"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 4L12 12Z" fill="white" />
    <path
      d="M4 4L12 12"
      stroke="white"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconClose;
