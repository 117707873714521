import { Box, IconButton, Text, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import UpdateQuantityControl from '@/components/cart/UpdateQuantityControl';

import { Cart, CartItem } from '@/components/types';

import CartItemSummary from './CartItemSummary';
import IconTrash from '@/components/icons/IconTrash';

const UPDATE_QUANTITY_BUTTON_SIZE = 26;

interface CartSummaryProps {
  cart: Cart;
  designs: Record<string, Design>;
  totalPrice: number;
  onSetQuantity(item: CartItem, quantity: number): void;
}

const CartSummary = ({ cart, designs, onSetQuantity }: CartSummaryProps) => {
  return (
    <VStack align="flex-start" spacing={0}>
      {cart.items.map((item, index) => {
        const design = designs[item.id];

        const { quantity, templateColorSize } = item;

        return design?.template ? (
          <Box
            borderBottom="1px solid"
            borderColor="borderSecondary"
            key={index}
            p="10px 16px"
            position="relative"
            w="100%"
          >
            <CartItemSummary
              design={design}
              item={item}
              templateColorSize={templateColorSize}
              quantity={quantity}
              quantityControl={
                <UpdateQuantityControl
                  quantity={quantity}
                  onSetQuantity={(quantity) => onSetQuantity(item, quantity)}
                  buttonProps={{
                    height: `${UPDATE_QUANTITY_BUTTON_SIZE}px`,
                    width: `${UPDATE_QUANTITY_BUTTON_SIZE}px`,
                  }}
                  spacing={8}
                  textProps={{
                    fontSize: '13px',
                  }}
                />
              }
            />
            <IconButton
              variant="ghost"
              aria-label="Remove"
              icon={<IconTrash />}
              onClick={() => onSetQuantity(item, 0)}
              top="16px"
              position="absolute"
              right="3px"
            ></IconButton>
          </Box>
        ) : (
          <Text width="100%" align="center" mt="20px">
            Design missing
          </Text>
        );
      })}
    </VStack>
  );
};
export default CartSummary;
