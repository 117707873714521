import { Icon } from '@chakra-ui/react';

const IconMenu = () => (
  <Icon
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8192 3.56096C10.8192 4.56599 10.0045 5.38072 8.99944 5.38072C7.99442 5.38072 7.17969 4.56599 7.17969 3.56096C7.17969 2.55594 7.99442 1.74121 8.99944 1.74121C10.0045 1.74121 10.8192 2.55594 10.8192 3.56096Z"
      fill="#626F82"
    />
    <path
      d="M10.8192 9.00047C10.8192 10.0055 10.0045 10.8202 8.99944 10.8202C7.99442 10.8202 7.17969 10.0055 7.17969 9.00047C7.17969 7.99545 7.99442 7.18072 8.99944 7.18072C10.0045 7.18072 10.8192 7.99545 10.8192 9.00047Z"
      fill="#626F82"
    />
    <path
      d="M10.8192 14.44C10.8192 15.445 10.0045 16.2597 8.99944 16.2597C7.99442 16.2597 7.17969 15.445 7.17969 14.44C7.17969 13.435 7.99442 12.6202 8.99944 12.6202C10.0045 12.6202 10.8192 13.435 10.8192 14.44Z"
      fill="#626F82"
    />
  </Icon>
);

export default IconMenu;
