import { Box, Center, Text } from '@chakra-ui/react';

import IconUploadImage from '@/components/icons/IconUploadImage';

import ImageUpload from '@/lib/components/upload/ImageUpload';

type ImageUploadButtonProps = {
  image?: string;
  onImageUploaded: (image: string) => void;
  title?: string;
};

export default function ImageUploadButton({
  image,
  title,
  onImageUploaded,
}: ImageUploadButtonProps) {
  return (
    <ImageUpload
      customButtonContent={
        image ? (
          <Center>
            <img src={image} width="64px" height="80px" />
          </Center>
        ) : (
          <Box>
            <IconUploadImage />
            <Text color="secondaryDarkGray.500" fontSize="10px" fontWeight={400}>
              {title}
            </Text>
          </Box>
        )
      }
      onImagesUploaded={(images) => onImageUploaded(images[0].src)}
    />
  );
}
