import { Icon } from '@chakra-ui/react';

const IconInfo = () => (
  <Icon
    width="17px"
    height="16px"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0905 14.661C4.40849 14.661 1.42383 11.6763 1.42383 7.9943C1.42383 4.3123 4.40849 1.32764 8.0905 1.32764C11.7725 1.32764 14.7572 4.3123 14.7572 7.9943C14.7572 11.6763 11.7725 14.661 8.0905 14.661ZM7.42383 7.32764V11.3276H8.75716V7.32764H7.42383ZM7.42383 4.66097V5.9943H8.75716V4.66097H7.42383Z"
      fill="#3182CE"
    />
  </Icon>
);

export default IconInfo;
