import { Icon } from '@chakra-ui/react';

const IconStat = () => (
  <Icon
    width="40px"
    height="40px"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28" cy="28" r="28" fill="#F4F7FE" />
    <g clipPath="url(#clip0_44_3704)">
      <path
        d="M25.75 40L23.2947 33.4553L16.75 31L23.2947 28.5447L25.75 22L28.2053 28.5447L34.75 31L28.2053 33.4553L25.75 40ZM20.125 24.25L19.0202 21.2298L16 20.125L19.0202 19.0202L20.125 16L21.2298 19.0202L24.25 20.125L21.2298 21.2298L20.125 24.25ZM34.75 28L33.2917 24.2083L29.5 22.75L33.2917 21.2917L34.75 17.5L36.2083 21.2917L40 22.75L36.2083 24.2083L34.75 28Z"
        fill="#064AC4"
      />
    </g>
    <defs>
      <clipPath id="clip0_44_3704">
        <rect
          width="32.8417"
          height="32.8417"
          fill="white"
          transform="translate(11.5264 11.7241)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default IconStat;
