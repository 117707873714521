import { useHistory } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';

import SignInForm from './SignInForm';

export default function SignInPage() {
  const history = useHistory();

  return (
    <AuthContainer title="Sign In" subtitle="Welcome back! Please enter your details.">
      <SignInForm
        onGoToSignUp={() => history.push('/auth/signup')}
        onSignedIn={() => {
          window.location.href = '/';
        }}
      />
    </AuthContainer>
  );
}
