import { useEffect, useState } from 'react';
import { Center, Stack, Text, useBreakpointValue } from '@chakra-ui/react';

import { Design } from '@/lib/types';
import SubmitDesignSideImage from './SubmitDesignSideImage';

const THUMBNAIL_IMAGE_WIDTH = 82;

const PREVIEW_IMAGE_MOBILE_WIDTH = 255;
const PREVIEW_IMAGE_WIDTH = 543;

interface DesignPreviewGalleryProps {
  design: Design;
}

const SubmitDesignPreviewGallery = ({ design }: DesignPreviewGalleryProps) => {
  const [selectedSide, setSelectedSide] = useState<string>(null);

  const [hoveredSide, setHoveredSide] = useState<string>(null);

  const { template } = design;

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!template) {
      return;
    }
    setSelectedSide(template?.sides[0]?.name);
  }, [template]);

  return (
    <Stack
      bg={{ base: 'transparent', md: 'secondaryGray.300' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify={{ base: 'flex-start', md: 'center' }}
      h={{ base: 'auto', md: '100%' }}
      position="relative"
      spacing={{ base: '5px', md: '13px' }}
      w="100%"
    >
      <SubmitDesignSideImage
        design={design}
        sideName={selectedSide}
        imageWidth={isMobile ? PREVIEW_IMAGE_MOBILE_WIDTH : PREVIEW_IMAGE_WIDTH}
        width="100%"
      />
      {template.sides.length > 1 ? (
        <Stack
          direction={{ base: 'row', md: 'column' }}
          position={{ md: 'absolute' }}
          spacing={{ base: '5px', md: '8px' }}
          right="16px"
          top="16px"
        >
          {template.sides.map((side) => {
            const isHovered = side.id === hoveredSide;

            return (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={selectedSide === side.name ? '1px' : 0}
                key={side.name}
                onClick={() => setSelectedSide(side.name)}
                onMouseEnter={() => setHoveredSide(side.id)}
                onMouseLeave={() => setHoveredSide(null)}
                position="relative"
              >
                <SubmitDesignSideImage
                  design={design}
                  sideName={side.name}
                  imageWidth={62}
                  height={THUMBNAIL_IMAGE_WIDTH}
                  width={THUMBNAIL_IMAGE_WIDTH}
                />
                {isHovered && !isMobile ? (
                  <Center
                    bg="rgba(98, 111, 130, 0.8)"
                    borderRadius={4}
                    position="absolute"
                    left={0}
                    top={0}
                    p="12px"
                    h="100%"
                    w="100%"
                  >
                    <Text as="b" color="#FFFFFF" textStyle="bodySmall">
                      {side.name}
                    </Text>
                  </Center>
                ) : null}
              </Center>
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default SubmitDesignPreviewGallery;
