import { Card } from '@chakra-ui/react';

const StyleCardContainer = ({ children, ...rest }) => (
  <Card
    boxShadow="0px 4px 20px 0px #00348B26"
    mb="20px"
    h={143}
    w={307}
    bgColor="#FFFFFF"
    borderRadius={14}
    p="8px 12px"
    {...rest}
  >
    {children}
  </Card>
);

export default StyleCardContainer;
