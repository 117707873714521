import { Box, Heading, Text } from '@chakra-ui/react';

interface StatBoxProps {
  title: string;
  value: number;
}

const CollectionStatBox = ({ title, value }: StatBoxProps) => {
  return (
    <Box
      border="1px solid"
      borderColor="borderSecondary"
      borderRadius={12}
      p={{ base: '10px 12px', md: '15px 16px' }}
      textAlign="left"
      w={{ base: '147px', md: '185px' }}
    >
      <Text fontSize={{ base: '13px', md: '16px' }} mb="9px">
        {title}
      </Text>
      <Heading fontSize={{ base: '20px', md: '24px' }}>{value}</Heading>
    </Box>
  );
};

export default CollectionStatBox;
