import { Icon } from '@chakra-ui/react';

const IconDots = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0658 18.0326C22.3908 18.0326 21.0329 16.6748 21.0329 14.9997C21.0329 13.3247 22.3908 11.9668 24.0658 11.9668C25.7409 11.9668 27.0988 13.3247 27.0988 14.9997C27.0988 16.6748 25.7409 18.0326 24.0658 18.0326Z"
      fill={'black'}
    />
    <path
      d="M15 18.0326C13.325 18.0326 11.9671 16.6748 11.9671 14.9997C11.9671 13.3247 13.325 11.9668 15 11.9668C16.675 11.9668 18.0329 13.3247 18.0329 14.9997C18.0329 16.6748 16.675 18.0326 15 18.0326Z"
      fill={'black'}
    />
    <path
      d="M5.93415 18.0326C4.25911 18.0326 2.90123 16.6748 2.90123 14.9997C2.90123 13.3247 4.25911 11.9668 5.93415 11.9668C7.60918 11.9668 8.96707 13.3247 8.96707 14.9997C8.96707 16.6748 7.60918 18.0326 5.93415 18.0326Z"
      fill={'black'}
    />
  </Icon>
);

export default IconDots;
