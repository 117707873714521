import Button from '../button';
import IconArrowRight from '@/lib/components/icons/IconArrowRight';

export const ButtonBack = (props) => (
  <Button
    outlined
    h="30px"
    color="#000000"
    fontWeight={600}
    borderColor="#CBD5E0"
    pl="8px"
    textTransform="none"
    {...props}
  >
    <IconArrowRight transform="rotate(180deg)" />
    Back
  </Button>
);

export default ButtonBack;
