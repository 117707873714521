import { Flex } from '@chakra-ui/react';

import AppContainer from '@/layouts/AppContainer';
import SubNavbar from '@/components/navbar/SubNavbar';
import CartContent from './CartContent';

declare global {
  interface Window {
    r: {
      sendCheckoutEvent: (cart: string, id: string, url: string, aid: string, cs: string) => void;
    };
  }
}

export default function CartPage() {
  return (
    <AppContainer contentContainerProps={{ p: 0 }}>
      <Flex direction="column" h="100%" w="100%">
        <SubNavbar title="Shopping Cart" />
        <CartContent />
      </Flex>
    </AppContainer>
  );
}
