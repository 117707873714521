enum CreateTemplateStep {
  GENERAL_INFORMATION = 'templateInformation',
  SIDES = 'sides',
  COLOR_VARIANTS = 'colorVariants',
  DESIGN_AREA = 'designArea',
  DESIGN_ELEMENTS = 'designElements',
  PREVIEW_IMAGES = 'previewImages',
  PREVIEW = 'preview',
}

export default CreateTemplateStep;
