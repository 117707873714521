import { Icon } from '@chakra-ui/react';

const IconCloseModalSmall = () => (
  <Icon
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.481201" y="0.240723" width="20.5188" height="20.5188" rx="10.2594" fill="#F2F2F7" />
    <path
      d="M7.35424 13.3599C7.14151 13.5726 7.13716 13.9503 7.35858 14.1717C7.58434 14.3932 7.96206 14.3888 8.17045 14.1804L10.7406 11.6102L13.3065 14.1761C13.5236 14.3932 13.8969 14.3932 14.1184 14.1717C14.3398 13.946 14.3398 13.577 14.1227 13.3599L11.5568 10.794L14.1227 8.22384C14.3398 8.00676 14.3441 7.63339 14.1184 7.41197C13.8969 7.19055 13.5236 7.19055 13.3065 7.40763L10.7406 9.97348L8.17045 7.40763C7.96206 7.19489 7.58 7.18621 7.35858 7.41197C7.13716 7.63339 7.14151 8.01545 7.35424 8.22384L9.92009 10.794L7.35424 13.3599Z"
      fill="#3C3C43"
      fillOpacity="0.6"
    />
  </Icon>
);

export default IconCloseModalSmall;
