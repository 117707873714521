import {
  Button,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';

import Coin from './images/Coin.png';

interface HintPopoverProps {
  onClose: () => void;
  onGoToDemo: () => void;
}

const PopoverDemoHint = ({ onClose, onGoToDemo }: HintPopoverProps) => {
  return (
    <Portal>
      <PopoverContent bg="brand.600" borderRadius="9px" p={0}>
        <PopoverArrow bg="brand.600" />
        <PopoverBody color="#252525" fontSize="13px" maxW="311px" p="12px 16px 24px 16px">
          <HStack spacing={1}>
            <Text color="#FFFFFF" fontWeight={500} fontSize="md" lineHeight="30px">
              You have
            </Text>
            <Text color="#EBAD21" fontWeight={500} fontSize="md" lineHeight="30px">
              100 Credits!
            </Text>
            <Image src={Coin} h="17px" w="16px" />
          </HStack>
          <Text
            color="#FFFFFF"
            cursor="pointer"
            fontSize="md"
            lineHeight="30px"
            onClick={onGoToDemo}
          >
            Click here to see demo and test it out!
          </Text>
          <Button
            borderRadius="6px"
            border="1px solid #FFF"
            bg="transparent"
            color="#FFFFFF"
            mt="13px"
            onClick={(e) => {
              e.stopPropagation();

              onClose();
            }}
            padding="3px 10px"
          >
            Dismiss
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

export default PopoverDemoHint;
