import { Button as ChakraButton } from '@chakra-ui/react';

import IconTrash from '@/components/icons/IconTrash';

const ButtonDelete = (props) => (
  <ChakraButton
    bg="transparent"
    minW="none"
    rightIcon={<IconTrash width="24px" height="24px" />}
    p={0}
    title="Remove"
    _hover={{
      bg: 'none',
    }}
    {...props}
  />
);

export default ButtonDelete;
