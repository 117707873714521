import { Box, HStack } from '@chakra-ui/react';

import { TemplateColor } from '@/lib/types';

import ToolbarButton from '../components/ToolbarButton';

const BUTTON_SIZE = 20;

const isLightColor = (colorName) => ['Blanco', 'White'].includes(colorName);

type Props = {
  selectedVariantId: string;
  onToggleColorPicker: () => void;
  options: TemplateColor[];
};

const ColorPicker = ({ selectedVariantId, onToggleColorPicker, options }: Props) => {
  const selectedVariant = options.find(({ id }) => id === selectedVariantId);

  return (
    <HStack align="flex-start">
      <ToolbarButton
        icon={
          <Box
            bg={selectedVariant?.hex || '#000000'}
            border={isLightColor(selectedVariant?.name) ? '1px solid #000000' : 'none'}
            width={`${BUTTON_SIZE}px`}
            height={`${BUTTON_SIZE}px`}
            borderRadius="50%"
          />
        }
        onClick={onToggleColorPicker}
        text="Color"
      />
    </HStack>
  );
};

export default ColorPicker;
