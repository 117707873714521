import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react';

import QRCodeImage from './QRCodeImage.png';

type Props = {
  onClose: () => void;
};

export default function QRCodeInstructionsModal({ onClose }: Props) {
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p="24px" w="342px">
        <ModalBody p={0}>
          <VStack>
            <Image src={QRCodeImage} h="171px" mb="22px" w="193px" />
            <Text fontFamily="Inter" fontSize="24px" fontWeight={700} textAlign="center">
              Custom Link for QR Code
            </Text>
            <Text fontFamily="Roboto" textAlign="left">
              Type or paste the web address you want your QR code to direct to. Whether it’s your
              personal blog, your business page, or a special event, make sure the URL is correct.
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
