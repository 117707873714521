import { ablo } from './ablo-wrapper';

export const urlToUpscale = async (imageUrl: string, scale: number): Promise<string> => {
  const response = await ablo().upscale.fromUrl(imageUrl, scale);

  return response.imageUrl;
};

export const imageToUpscale = async (imageFile: File, scale: number): Promise<string> => {
  const response = await ablo().upscale.fromFile(imageFile, scale);

  return response.imageUrl;
};
