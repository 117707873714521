import { Button, FormControl, FormLabel, Input, Show, Text } from '@chakra-ui/react';

import IconInfo from '../../components/icons/IconInfo';
import { useEffect, useRef } from 'react';

const InputStyleProps = {
  padding: '12px 14px',
  background: '#F5F5F5',
  borderRadius: '10px',
  color: 'black.600',
  fontSize: '15px',
  fontWeight: 500,
  h: '42px',
  _placeholder: {
    color: '#878787',
  },
};

interface FormFieldProps {
  error?: React.ReactNode | string;
  name?: string;
  isRequired?: boolean;
  onInfoClick?: () => void;
  isFocused?: boolean;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

const FormField = (props: FormFieldProps) => {
  const inputRef = useRef(null);

  const { name, error, isFocused, onChange, onInfoClick, ...rest } = props;

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  return (
    <FormControl w="100%">
      {name ? (
        <Show above="md">
          <FormLabel
            alignItems="center"
            display="flex"
            fontSize="14px"
            fontWeight={600}
            color="black.200"
            mb="4px"
          >
            {name}
            {onInfoClick ? (
              <Button bg="transparent" h="16px" onClick={onInfoClick} p="0 4px" minW="none">
                <IconInfo />
              </Button>
            ) : null}
          </FormLabel>
        </Show>
      ) : null}
      <Input
        {...InputStyleProps}
        onChange={(e) => onChange(e.target.value)}
        ref={inputRef}
        {...rest}
      />
      {error ? (
        <Text color="#E53E3E" fontSize="12px" mt="7px">
          {error}
        </Text>
      ) : null}
    </FormControl>
  );
};

export default FormField;
