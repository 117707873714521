import { CallbackFunction } from 'use-double-tap';

import { Box, Image } from '@chakra-ui/react';

import { IconExpand } from '../../../components/icons/IconExpand';

import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';

type ExpandableImageThumnbailProps = {
  image: IAbloImage;
  isAutoWidth: boolean;
  isSelected: boolean;
  bindCompressed?: {
    onClick: CallbackFunction<Element>;
  };
  onExpand?: () => void;
};

const IMAGE_SIDE = 108;

const ExpandableImageThumnbail = ({
  image,
  isAutoWidth,
  isSelected,
  onExpand,
  bindCompressed,
}: ExpandableImageThumnbailProps) => {
  return (
    <Box position="relative" key={image.id} style={{ display: 'inline-block' }}>
      <Image
        border={isSelected ? '4px solid #000000' : 'none'}
        borderRadius="5px"
        id={image.id}
        h={IMAGE_SIDE}
        w={isAutoWidth ? 'auto' : IMAGE_SIDE}
        src={image.url}
        alt="Generated image"
        {...(bindCompressed || {})}
      />
      <Box
        id={image.id}
        position="absolute"
        top={IMAGE_SIDE / 20}
        right={IMAGE_SIDE / 20}
        onClick={onExpand}
        bg="white"
        borderRadius="4px"
        p="4px"
      >
        <IconExpand id={image.id} />
      </Box>
    </Box>
  );
};

export default ExpandableImageThumnbail;
