export function base64ToFile(base64String, filename) {
  // Split the base64 string into the data and the content type
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a Blob from the byte array
  const blob = new Blob([u8arr], { type: mime });

  // Create a File object
  const file = new File([blob], filename, { type: mime });

  return file;
}
