import { Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';

import IconStat from './icons/IconStat';

type StatBoxProps = {
  name: string;
  onClick: () => void;
  value: string;
};

const StatBox = ({ name, value, onClick }: StatBoxProps) => (
  <Card bg="#FFFFFF" borderRadius="20px" flex={1} as="button" onClick={onClick}>
    <CardBody p="20px 6px 20px 12px">
      <HStack align="flex-start" w="100%">
        <IconStat />
        <VStack align="flex-start" spacing={0}>
          <Text color="secondaryGray.600" fontSize="sm" textAlign="left">
            {name}
          </Text>
          <Text color="black.700" fontSize="2xl" fontWeight={700}>
            {value || '-'}
          </Text>
        </VStack>
      </HStack>
    </CardBody>
  </Card>
);

export default StatBox;
