import AppContainer from '@/layouts/AppContainer';
import { Box, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Heading } from './Headings';

interface LegalContainerProps {
  children: ReactNode;
  title: string;
}

const LegalContainer = ({ children, title }: LegalContainerProps) => (
  <AppContainer hideSidebar>
    <Box bg="#FFFFFF" w="100%">
      <VStack
        align="flex-start"
        margin="0 auto"
        py="40px"
        w={{ base: '300px', md: '750px' }}
        spacing="16px"
      >
        <Heading alignSelf="flex-start" color="brand.600" fontSize="20px" textTransform="uppercase">
          {title}
        </Heading>
        {children}
      </VStack>
    </Box>
  </AppContainer>
);

export default LegalContainer;
