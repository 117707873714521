import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Image,
  HStack,
} from '@chakra-ui/react';

import Button from '@/components/button';

import CoinBlue from '@/assets/CoinBlue.png';
import { useHistory } from 'react-router-dom';
import { useMe } from '../../api/auth';
import IconBack from '../../lib/components/icons/IconBack';

type Props = {
  onClose: () => void;
};

const OutOfCreditsModal = ({ onClose }: Props) => {
  const history = useHistory();
  const { data: me } = useMe();

  const handleConfirm = () => {
    onClose();

    history.push('/subscription');
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="527px" maxHeight="283px">
        <ModalBody padding="40px">
          <VStack spacing="14px">
            <Image src={CoinBlue} w="32px" />
            <Text color="gray.900" fontSize="24px" fontWeight={600}>
              You have {me?.client?.credits} remaining credits
            </Text>
            <Text color="gray.600" fontSize="sm">
              Your credits will be replenished in 24 hours.
            </Text>
            <HStack mt="26px" fontSize="16px">
              <Button height="50px" px="24px" onClick={() => onClose()} secondary>
                <IconBack />
                Go Back
              </Button>
              <Button height="50px" px="36px" onClick={handleConfirm}>
                Add Credits
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OutOfCreditsModal;
