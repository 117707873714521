export const getPasswordComplexityErrors = (password = '') => {
  const errors = [];

  if (password.length < 8) {
    errors.push('at least 8 characters');
  }

  if (password.search(/[#?!@$%^&*\-_\\/]/) < 0) {
    errors.push('at least one special character');
  }

  if (password.search(/[A-Z]/) < 0) {
    errors.push('at least one uppercase letter');
  }

  if (password.search(/[a-z]/) < 0) {
    errors.push('at least one lowercase letter');
  }

  if (password.search(/[0-9]/) < 0) {
    errors.push('at least one number');
  }

  return errors;
};
