import {
  Button,
  LightMode,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverProps,
} from '@chakra-ui/react';

interface HintPopoverProps extends PopoverProps {
  title: string;
  description: string;
  onNext: () => void;
  step: number;
  totalSteps: number;
  children: React.ReactNode;
}

const HintPopover = ({
  isOpen,
  title,
  description,
  onNext,
  step,
  totalSteps,
  onClose,
  children,
  ...rest
}: HintPopoverProps) => {
  return (
    <LightMode>
      <Popover
        isOpen={isOpen}
        returnFocusOnClose={false}
        placement="bottom"
        onClose={onClose}
        offset={[0, 0]}
        {...rest}
      >
        <PopoverTrigger>{children}</PopoverTrigger>
        {isOpen ? (
          <PopoverContent w="295px">
            <PopoverHeader borderBottom="none" color="black.600" fontWeight={500} fontSize="sm">
              {title}
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody color="#252525" fontSize="13px" maxW="271px">
              {description}
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="space-between">
              <Button color="#444" fontSize="sm" fontWeight={400} onClick={onClose} variant="ghost">
                Skip
              </Button>
              <Button color="secondaryDarkGray.600" fontSize="sm" fontWeight={400} variant="ghost">
                {step} of {totalSteps}
              </Button>
              <Button
                bg="brand.600"
                borderRadius="6px"
                color="#FFFFFF"
                fontWeight={400}
                onClick={onNext}
                p="6px 20px"
              >
                Next
              </Button>
            </PopoverFooter>
          </PopoverContent>
        ) : null}
      </Popover>
    </LightMode>
  );
};

export default HintPopover;
