import { useState } from 'react';
import Colors from '@/theme/colors';
import {
  Button,
  Popover,
  HStack,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
  VStack,
  Box,
  PopoverHeader,
} from '@chakra-ui/react';

import IconCloseModalSmall from '@/lib/components/icons/IconCloseModalSmall';
import FormInput from './FormInput';
import ButtonCTA from '../../views/admin/subscription/components/buttons/ButtonCTA';

const { abloBlue } = Colors;

type TagsInputProps = {
  name: string;
  tags: string[];
  onUpdateTags: (tags: string[]) => void;
  error?: string;
};

function TagsInput({ name, tags, onUpdateTags, error }: TagsInputProps) {
  const [newTag, setNewTag] = useState('');

  // Handlers
  const removeTag = (tag: string) => onUpdateTags(tags.filter((t) => t !== tag));
  const addTag = () => {
    const val = newTag && newTag.trim();
    if (val && !tags.includes(val)) {
      onUpdateTags([...tags, newTag.trim()]);
    }
    setNewTag('');
  };

  return (
    <FormControl w="100%">
      {name ? <FormLabel>{name}</FormLabel> : null}

      <Popover closeOnBlur={true}>
        <PopoverTrigger>
          <Box w="180px">
            {tags && tags.length ? (
              tags.map((t, index) => (
                <Tag
                  key={index}
                  variant="solid"
                  backgroundColor={abloBlue}
                  size={'md'}
                  me={1}
                  mb={1}
                >
                  {t}
                </Tag>
              ))
            ) : (
              <Tag variant="solid" backgroundColor={abloBlue} size={'md'}>
                (click to add)
              </Tag>
            )}
          </Box>
        </PopoverTrigger>

        <PopoverContent backgroundColor="white" w="240px" borderColor={abloBlue}>
          <PopoverHeader>
            <HStack>
              <FormInput
                placeholder="Add tag..."
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    addTag();
                  }
                }}
              />
              <ButtonCTA onClick={addTag} p={0} title="Add">
                Add
              </ButtonCTA>
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <VStack align="start">
              {tags.map((t, index) => (
                <Tag key={index} variant="solid" backgroundColor={abloBlue} me={3} size={'lg'}>
                  <HStack>
                    <Box>{t}</Box>
                    <Button
                      bg="transparent"
                      minWidth="none"
                      onClick={() => removeTag(t)}
                      padding={0}
                      tabIndex={-1}
                    >
                      <IconCloseModalSmall />
                    </Button>
                  </HStack>
                </Tag>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
}

export default TagsInput;
