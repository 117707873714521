import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  color: '#A0AEC0',
});

export const closeButton = defineStyleConfig({
  defaultProps: {
    variant: 'outline',
  },
  variants: { outline },
});
