import axios from 'axios';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiKey } from '../components/types';

const entity = 'api-keys';

const URL = `/${entity}`;

export const getApiKeys = () => axios.get<ApiKey[]>(URL).then(({ data }) => data);

export const useGetApiKeys = () => useQuery([entity], () => getApiKeys());

export const useGenerateApiKey = () => {
  const client = useQueryClient();

  const { mutateAsync: generateApiKey } = useMutation(() => axios.post<ApiKey>(`${URL}`), {
    onSuccess: () => {
      client.invalidateQueries([entity]);
      client.invalidateQueries(['me']);
    },
  });

  return {
    generateApiKey,
  };
};

export const useDeleteApiKey = () => {
  const client = useQueryClient();

  const { mutateAsync: deleteApiKey } = useMutation((id: string) => axios.delete(`${URL}/${id}`), {
    onSuccess: () => {
      client.invalidateQueries([entity]);
      client.invalidateQueries(['me']);
    },
  });

  return {
    deleteApiKey,
  };
};

const patchApiKey = (apiKey: Partial<ApiKey>) => {
  return axios.patch<ApiKey>(`${URL}/${apiKey.id}`, apiKey).then(({ data }) => data);
};

export const useUpdateApiKey = () => {
  const client = useQueryClient();

  const { mutateAsync: updateApiKey } = useMutation(
    (apiKey: Partial<ApiKey>) => patchApiKey(apiKey),
    {
      onSuccess: () => {
        client.invalidateQueries([entity]);
        client.invalidateQueries(['me']);
      },
    }
  );

  return {
    updateApiKey,
  };
};
