const Config = {
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  API_URL: import.meta.env.VITE_API_URL || 'https://dev.ablo.ai',
  CLIENT_URL: import.meta.env.VITE_CLIENT_URL,
  GOOGLE_CLIENT_ID:
    import.meta.env.VITE_GOOGLE_CLIENT_ID ||
    '3985027070-joibmdj5n2l3s5he4c12gt5mme1sp6m7.apps.googleusercontent.com',
  INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  PUBNUB_PUBLISH_KEY: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
  PUBNUB_SUBSCRIBE_KEY: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
};

export default Config;
