import { TemplateSide } from '../types';

export const getFullPrintableAreaImage = (
  base64OutputImage: string,
  templateSide: TemplateSide
): Promise<string> =>
  new Promise((resolve) => {
    const image = new Image();

    const {
      manufacturingAreaHeight,
      manufacturingAreaWidth,
      manufacturingImageLeft,
      manufacturingImageTop,
      manufacturingImageHeight,
      manufacturingImageWidth,
    } = templateSide;

    image.onload = () => {
      const helperCanvas = document.createElement('canvas');

      helperCanvas.width = manufacturingAreaWidth;
      helperCanvas.height = manufacturingAreaHeight;

      const context = helperCanvas.getContext('2d');

      context.drawImage(
        image,
        manufacturingImageLeft || 0,
        manufacturingImageTop || 0,
        manufacturingImageWidth || image.width,
        manufacturingImageHeight || image.height
      );

      const manufacturingImage = helperCanvas.toDataURL();

      resolve(manufacturingImage);
    };

    image.src = base64OutputImage;
  });
