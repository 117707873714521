import CustomArtStyle from '@/views/admin/custom-art-style';
import Dashboard from '@/views/admin/dashboard';

import TemplateLibrary from '@/views/admin/template-library';
import DesignCenter from '@/views/admin/design-center';
import Subscription from '@/views/admin/subscription';
import Team from '@/views/admin/team';
import ProfileSettings from '@/views/admin/profile-settings';

import Editor from '@/views/demo/pages/editor';
import RemixSubmittedPage from '@/views/demo/pages/remix-submitted';
import SubmitRemixPage from '@/views/demo/pages/submit-remix';

import TermsOfService from '@/views/legal/TermsOfService';

import IconCustomArtStyle from './icons/IconCustomArtStyle';
import IconDashboard from './icons/IconDashboard';
import IconHome from './icons/IconHome';
import IconDesignCenter from './icons/IconDesignCenter';
import IconSubscriptionPlan from './icons/IconSubscriptionPlan';
import IconTeam from './icons/IconTeam';

import PrivacyPolicy from '@/views/legal/PrivacyPolicy';
import IconHomeActive from './icons/IconHomeActive';
import IconGenerateArt from './icons/IconGenerateArt';
import IconGenerateArtActive from './icons/IconGenerateArtActive';
import IconDashboardActive from './icons/IconDashboardActive';
import IconDesignCenterActive from './icons/IconDesignCenterActive';
import IconTemplateLibrary from './icons/IconTemplateLibrary';
import IconTemplateLibraryActive from './icons/IconTemplateLibraryActive';
import IconTeamActive from './icons/IconTeamActive';
import IconCustomArtStyleActive from './icons/IconCustomArtStyleActive';
import IconSubscriptionPlanActive from './icons/IconSubscriptionPlan copy';
import HomeSignedIn from '../../views/home/HomeSignedIn';
import CollectionSignedIn from '../../views/collection/CollectionSignedIn';
import IconMerchShop from './icons/IconMerchShop';
import IconMerchShopActive from './icons/IconMerchShopActive';
import CollectionsListPage from '@/views/collection/CollectionsListPage';
import ProductDetailsPage from '@/views/product/ProductDetailsPage';
import CartPage from '@/views/cart';
import IconOrders from './icons/IconOrders';
import Orders from '@/views/admin/orders';

const ROUTES = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    icon: <IconHome />,
    iconActive: <IconHomeActive />,
    component: HomeSignedIn,
  },
  {
    name: 'Collection Details',
    path: '/collection/:id',
    notInMenu: true,
    component: CollectionSignedIn,
  },
  {
    name: 'Product Details',
    path: '/products/:id',
    notInMenu: true,
    component: ProductDetailsPage,
  },
  {
    name: 'Generate Art',
    defaultPath: '/designs/new',
    path: '/designs/:id',
    icon: <IconGenerateArt />,
    iconActive: <IconGenerateArtActive />,
    component: Editor,
  },
  {
    name: 'My Designs',
    path: '/designs',
    icon: <IconDesignCenter />,
    iconActive: <IconDesignCenterActive />,
    component: DesignCenter,
  },
  {
    name: 'Merch Shop',
    path: '/collections',
    icon: <IconMerchShop />,
    iconActive: <IconMerchShopActive />,
    component: CollectionsListPage,
  },
  {
    name: 'Orders',
    path: '/orders',
    icon: <IconOrders />,
    iconActive: <IconOrders />,
    component: Orders,
  },
  {
    name: 'Cart',
    path: '/cart',
    notInMenu: true,
    component: CartPage,
  },
  {
    name: 'Advanced',
    routes: [
      {
        name: 'Template library',
        path: '/template-library',
        icon: <IconTemplateLibrary />,
        iconActive: <IconTemplateLibraryActive />,
        component: TemplateLibrary,
      },
      {
        name: 'Manage Team',
        path: '/team',
        icon: <IconTeam />,
        iconActive: <IconTeamActive />,
        component: Team,
      },
      {
        name: 'Analytics',
        path: '/dashboard',
        icon: <IconDashboard />,
        iconActive: <IconDashboardActive />,
        component: Dashboard,
      },
      {
        name: 'Custom Art style',
        path: '/custom-art-styles',
        icon: <IconCustomArtStyle />,
        iconActive: <IconCustomArtStyleActive />,
        component: CustomArtStyle,
      },
      {
        name: 'Subscription Plan',
        path: '/subscription',
        icon: <IconSubscriptionPlan />,
        iconActive: <IconSubscriptionPlanActive />,
        component: Subscription,
      },
    ],
  },
  {
    name: 'Remix submitted',
    path: '/share',
    notInMenu: true,
    component: RemixSubmittedPage,
  },
  {
    name: 'Submit remix',
    path: '/submit',
    notInMenu: true,
    component: SubmitRemixPage,
  },
  {
    name: 'Profile',
    path: '/profile',
    notInMenu: true,
    layout: '',
    component: ProfileSettings,
  },
  {
    name: 'Terms',
    path: '/terms',
    notInMenu: true,
    layout: '',
    component: TermsOfService,
  },
  {
    name: 'Privacy policy',
    path: '/privacy-policy',
    notInMenu: true,
    layout: '',
    component: PrivacyPolicy,
  },
];

export default ROUTES;
