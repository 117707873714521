const getAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const getResizedImage = (
  imgAsBase64: string,
  imgWidth: number,
  imgHeight: number,
  maxSize = 200
) => {
  const image = document.createElement('img');
  image.src = imgAsBase64;

  const { width, height } = getAspectRatioFit(imgWidth, imgHeight, maxSize, maxSize);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d', { alpha: false });

  context.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
};

export default getResizedImage;
