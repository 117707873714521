import { Icon } from '@chakra-ui/react';

const IconHome = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.825195L21.7381 10.062H18.3801V18.5897H12.222V13.6939H9.45822V18.5897H3.61996V10.062H0.261993L11 0.825195ZM4.30595 8.56202H5.11996V17.0897H7.95822V12.1939H13.722V17.0897H16.8801V8.56202H17.6941L11 2.8038L4.30595 8.56202Z"
      fill="black"
    />
  </Icon>
);

export default IconHome;
