import { Icon } from '@chakra-ui/react';

const IconCopy = () => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0521 4.76848C18.6612 3.37756 16.4061 3.37757 15.0152 4.76848L12.8776 6.9061L11.6048 5.63331L13.7424 3.49569C15.8363 1.40183 19.2311 1.40183 21.3249 3.49568C23.4188 5.58954 23.4188 8.98436 21.3249 11.0782L18.3702 14.033C16.1442 16.259 12.5351 16.259 10.3092 14.033L9.82629 13.5501L11.0991 12.2773L11.582 12.7602C13.105 14.2832 15.5743 14.2832 17.0974 12.7602L20.0521 9.80542C21.4431 8.41451 21.4431 6.15939 20.0521 4.76848ZM13.9188 11.3803C12.3958 9.85729 9.92645 9.85729 8.40341 11.3803L5.44865 14.3351C4.05774 15.726 4.05774 17.9811 5.44866 19.372C6.83957 20.7629 9.09469 20.7629 10.4856 19.372L12.6232 17.2344L13.896 18.5072L11.7584 20.6448C9.66453 22.7387 6.26972 22.7387 4.17587 20.6448C2.082 18.551 2.082 15.1562 4.17586 13.0623L7.13062 10.1075C9.3566 7.88155 12.9656 7.88155 15.1916 10.1075L15.6745 10.5904L14.4017 11.8632L13.9188 11.3803Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconCopy;
