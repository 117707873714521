import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

const baseStyle = definePartsStyle({
  tablist: {
    borderBottom: 'none',
    gap: { base: '22px', md: '40px' },
    mb: 0,
    w: 'max-content',
  },
  tab: {
    color: 'secondaryDarkGray.600',
    fontSize: { base: '14px', md: '16px' },
    borderBottomColor: 'transparent',
    paddingX: 0,
    paddingY: 0,
    _selected: {
      borderBottomWidth: '2px',
      borderBottomColor: 'brand.600',
      color: 'brand.600',
      fontWeight: 600,
    },
  },
});

export const tabsStyles = defineMultiStyleConfig({
  defaultProps: {
    variant: 'unstyled',
  },
  variants: {
    unstyled: baseStyle,
  },
});
