import { Icon } from '@chakra-ui/react';

const IconGenerateArt = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.52971 3.02633L8.14393 3.81574L5.52971 4.60515L4.7403 7.21937L3.95089 4.60515L1.33667 3.81574L3.95089 3.02633L4.7403 0.412109L5.52971 3.02633Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2658 4.7947L14.981 1.53699L13.6961 4.7947L10.4384 6.07953L13.6961 7.36435L14.981 10.6221L16.2658 7.36435L19.5235 6.07953L16.2658 4.7947ZM16.7979 6.07953L15.4949 5.56562L14.981 4.26259L14.467 5.56562L13.164 6.07953L14.467 6.59343L14.981 7.89646L15.4949 6.59343L16.7979 6.07953Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5152 10.3412L8.38568 4.94165L6.25614 10.3412L0.856628 12.4707L6.25614 14.6002L8.38568 19.9998L10.5152 14.6002L15.9147 12.4707L10.5152 10.3412ZM11.8263 12.4707L9.35884 11.4975L8.38568 9.03006L7.41252 11.4975L4.94504 12.4707L7.41252 13.4439L8.38568 15.9113L9.35884 13.4439L11.8263 12.4707Z"
      fill="black"
    />
  </Icon>
);

export default IconGenerateArt;
