import { Text, VStack } from '@chakra-ui/react';

import { H3 } from '@/components/typography/Headings';
import Button from '@/components/button';
import { useHistory } from 'react-router-dom';
import { Design } from '@/lib';
import RemixCard from '@/components/remix/RemixCard';

import ModalContainer from '@/components/modals/ModalContainer';

type Props = {
  design: Design;
  onClose: () => void;
};

const IpPublishedModal = ({ design, onClose }: Props) => {
  const history = useHistory();

  const handleGoToProductDetails = () => history.push(`/products/${design.id}`);

  const handleGoToMyIPs = () => history.push(`/designs?isPublished=true`);

  return (
    <ModalContainer onClose={onClose} width={{ base: 'calc(100% - 20px)', md: 480 }}>
      <>
        <H3 mb="6px">Your IP is published!</H3>
        <Text mb="21px" textStyle="body">
          You’ll earn 15% in royalties from each sale of your merch.
        </Text>
        <VStack w="100%">
          <RemixCard design={design} hideVoteButton />
        </VStack>
        <VStack gap="12px" mt="29px" spacing={12} w="100%">
          <Button onClick={handleGoToProductDetails} w="100%">
            Product details
          </Button>
          <Button onClick={handleGoToMyIPs} secondary w="100%">
            My IPs
          </Button>
        </VStack>
      </>
    </ModalContainer>
  );
};

export default IpPublishedModal;
