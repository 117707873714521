import { Fragment as F, useState } from 'react';
import { CardBody, HStack, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import StyleCardContainer from './StyleCardContainer';
import OutOfCreditsModal from '@/components/subscription/OutOfCreditsModal';
import { User } from '@/components/types';
import IconCredits from './IconCredits';

interface NewStyleCardProps {
  cost: number;
  me: User;
}

export default function NewStyleCard({ cost, me }: NewStyleCardProps) {
  const [isOutOfCreditsModalVisible, setIsOutOfCreditsModalVisible] = useState(false);

  const history = useHistory();

  const handleStartTraining = () => {
    if (cost && me.client.credits < cost) {
      setIsOutOfCreditsModalVisible(true);

      return;
    }

    history.push('/custom-art-styles/new');
  };

  return (
    <F>
      <StyleCardContainer border="2px solid brand.600">
        <CardBody as="button" p="27px 10px 30px 23px" onClick={handleStartTraining}>
          <HStack spacing={0} w="100%" justify="center">
            <Text color="brand.600" fontSize="sm" fontWeight={600}>
              Create New Art Style - {cost}
            </Text>
            <IconCredits />
          </HStack>
        </CardBody>
      </StyleCardContainer>
      {isOutOfCreditsModalVisible ? (
        <OutOfCreditsModal onClose={() => setIsOutOfCreditsModalVisible(false)} />
      ) : null}
    </F>
  );
}
