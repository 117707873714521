import { Icon } from '@chakra-ui/react';

export const IconHeartRed = (props) => (
  <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.1667 12.5722L12.6667 20.0002L5.16668 12.5722C4.67199 12.0908 4.28233 11.5122 4.02223 10.8728C3.76214 10.2334 3.63724 9.54713 3.65542 8.85711C3.6736 8.16709 3.83445 7.48831 4.12784 6.86351C4.42123 6.23871 4.84082 5.68143 5.36017 5.22676C5.87952 4.77208 6.48739 4.42987 7.14549 4.22166C7.8036 4.01345 8.49769 3.94376 9.18405 4.01698C9.87041 4.0902 10.5342 4.30473 11.1336 4.64708C11.7329 4.98943 12.2549 5.45218 12.6667 6.00618C13.0802 5.4562 13.6028 4.9975 14.2018 4.65878C14.8007 4.32007 15.4632 4.10863 16.1476 4.0377C16.8321 3.96677 17.5238 4.03787 18.1795 4.24657C18.8351 4.45526 19.4407 4.79705 19.9582 5.25054C20.4758 5.70403 20.8941 6.25946 21.1871 6.88207C21.4801 7.50468 21.6414 8.18106 21.661 8.86889C21.6806 9.55671 21.5579 10.2412 21.3008 10.8794C21.0437 11.5177 20.6576 12.096 20.1667 12.5782"
      fill="#FF0000"
    />
  </Icon>
);
