import axios from 'axios';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Favorite } from '../lib';

const entity = 'favorites';

const URL = `/${entity}`;

export const getFavorites = (userId: string) =>
  axios.get<Favorite[]>(URL, { params: { userId } }).then(({ data }) => data);

export const useFavorites = (userId: string) => useQuery([entity], () => getFavorites(userId));

export const useAddFavorite = () => {
  const client = useQueryClient();

  const { mutate: addFavorite } = useMutation(
    (favorite: Favorite) => axios.post<Favorite>(`${URL}`, favorite),
    {
      onSuccess: () => client.invalidateQueries([entity]),
    }
  );

  return {
    addFavorite,
  };
};

export const useRemoveFavorite = () => {
  const client = useQueryClient();

  const { mutate: removeFavorite } = useMutation(
    ({ id, userId }: { id: string; userId: string }) =>
      axios.delete(`${URL}/${id}?userId=${userId}`),
    {
      onSuccess: () => client.invalidateQueries([entity]),
    }
  );

  return {
    removeFavorite,
  };
};
