import { Box, Center, Image, Text, VStack } from '@chakra-ui/react';

import Spinner from '@/assets/spinner.gif';

const LoadingOverlayDiv = () => (
  <Center h="100%" w="100%" position="absolute" top={0} left={0}>
    <Box bg="black" opacity="0.5" h="100%" position="absolute" w="100%" left={0} top={0} />
    <VStack
      bg="#FFFFFF"
      borderRadius="9px"
      boxShadow="0px 0px 14px 0px #BBBBBB59"
      h="96px"
      py="10px"
      w="152px"
      spacing={0}
      zIndex={2}
    >
      <Image src={Spinner} w="46px" />
      <Text>Updating</Text>
    </VStack>
  </Center>
);

export default LoadingOverlayDiv;
