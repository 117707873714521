import { Box, SimpleGrid } from '@chakra-ui/react';

import { Favorite } from '@/lib';
import Button from '@/lib/components/Button';
import { IconGenerate } from '@/lib/components/icons/IconGenerate';
import IconClose from '@/lib/components/icons/IconClose';
import { useState } from 'react';

import ExpandableImageThumnbail from './ExpandableImageThumbnail';
import ExpandedImage from './ExpandedImage';
import { useDoubleTap } from 'use-double-tap';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';

const IMAGE_SIDE = 108;

const ButtonRemove = ({ onRemove, ...rest }) => (
  <Button
    position="absolute"
    top={IMAGE_SIDE / 2 - 13}
    left={IMAGE_SIDE / 10}
    w="80%"
    h="26px"
    borderRadius="4px"
    borderColor="gray.900"
    bg="gray.900"
    title="Remove"
    icon={<IconClose width="24px" height="24px" mt="8px" mr="-14px" />}
    onClick={onRemove}
    _hover={{}}
    {...rest}
  />
);

type FavoriteGalleryProps = {
  onGeneratedImageSelected: (image: IAbloImage) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
};

const FavoriteGallery = ({
  favorites,
  removeFavorite,
  onGeneratedImageSelected,
}: FavoriteGalleryProps) => {
  const [selectedImage, setSelectedImage] = useState<IAbloImage>(null);

  const [isImageExpanded, setImageExpanded] = useState(false);

  const images = favorites?.map((favorite) => favorite.image) || [];
  const isSelected = (image: IAbloImage) => image.id === selectedImage?.id;

  const bindCompressed = useDoubleTap(
    () => {
      handleExpand((event.target as HTMLElement).id);
    },
    300,
    {
      onSingleTap: (event) => {
        setSelectedImage(images.find(({ id }) => id === (event.target as HTMLElement).id));
      },
    }
  );

  const bindExpanded = useDoubleTap(() => {
    setImageExpanded(false);
  }, 300);

  const onRemove = (image: IAbloImage) => {
    removeFavorite(favorites.find((favorite) => favorite.imageId === image.id).id);
    setSelectedImage(null);
    setImageExpanded(false);
  };

  const handleExpand = (imageId: string) => {
    setSelectedImage(images.find(({ id }) => id === imageId));
    setImageExpanded(true);
  };

  return (
    <Box>
      {isImageExpanded ? (
        <Box position="relative">
          <ExpandedImage
            image={selectedImage}
            bindExpanded={bindExpanded}
            onCompress={() => setImageExpanded(false)}
          />
          <ButtonRemove left="20px" top="20px" onRemove={() => onRemove(selectedImage)} w="auto" />
        </Box>
      ) : (
        <SimpleGrid columns={{ base: 3, md: 6, lg: 3 }} spacing="8px" alignContent="start">
          {images.map((image: IAbloImage, index) => (
            <Box position="relative" key={index}>
              <ExpandableImageThumnbail
                image={image}
                isAutoWidth={images.length === 1}
                isSelected={isSelected(image)}
                key={image.id}
                onExpand={() => handleExpand(image.id)}
                bindCompressed={bindCompressed}
              />
              {isSelected(image) && <ButtonRemove onRemove={() => onRemove(image)} />}
            </Box>
          ))}
        </SimpleGrid>
      )}
      <Button
        isDisabled={!images?.length || !selectedImage}
        onClick={() => onGeneratedImageSelected(selectedImage)}
        title="Place Artwork"
        w="100%"
        mt="20px"
        icon={<IconGenerate />}
      />
    </Box>
  );
};

export default FavoriteGallery;
