import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import IconTableMenu from '../../../../../../components/table/icons/IconTableMenu';
import { MenuItem } from '../../../../../../components/table/MenuItem';

interface TableActionMenuProps {
  onActivate: () => void;
  onDeactivate: () => void;
  onDelete: () => void;
  isActive: boolean;
}

export const ApiKeyMenu = ({
  onActivate,
  onDeactivate,
  onDelete,
  isActive,
}: TableActionMenuProps) => {
  return (
    <Menu placement="bottom">
      <MenuButton
        as={Button}
        bg="transparent"
        border="none"
        h="15px"
        rightIcon={<IconTableMenu />}
        minWidth="none"
        onClick={(e) => e.stopPropagation()}
        p={0}
        _focus={{
          bg: 'transparent',
        }}
        _hover={{
          bg: 'transparent',
        }}
        _active={{
          bg: 'transparent',
        }}
      />
      <MenuList
        bg="#FFFFFF"
        border="1px solid"
        borderColor="secondaryGray.300"
        minWidth="none"
        p={0}
        w="124px"
      >
        {!isActive && <MenuItem onClick={onActivate}>Activate</MenuItem>}
        {isActive && <MenuItem onClick={onDeactivate}>Deactivate</MenuItem>}
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </MenuList>
    </Menu>
  );
};
