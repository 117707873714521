const units = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'week', seconds: 604800 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 },
];

const calculateTimeDifference = (time: number) => {
  for (const { label, seconds } of units) {
    const interval = Math.floor(time / seconds);
    if (interval >= 1) {
      return {
        interval: interval,
        unit: label,
      };
    }
  }
  return {
    interval: 0,
    unit: '',
  };
};

export const timeAgo = (date: string | number | Date) => {
  const time = Math.floor((new Date().valueOf() - new Date(date).valueOf()) / 1000);
  const { interval, unit } = calculateTimeDifference(time);
  const suffix = interval === 1 ? '' : 's';
  return `${interval} ${unit}${suffix} ago`;
};

const pad = (n) => {
  return n < 10 ? '0' + n : n;
};

export const getCountdownLabel = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hoursLeft = Math.floor(seconds - days * 86400);
  const hours = Math.floor(hoursLeft / 3600);
  const minutesLeft = Math.floor(hoursLeft - hours * 3600);
  const minutes = Math.floor(minutesLeft / 60);
  const remainingSeconds = seconds % 60;

  return [`${pad(days)}d`, `${pad(hours)}h`, `${pad(minutes)}m`, `${pad(remainingSeconds)}s`].join(
    ' '
  );
};
