import { Box, HStack, Textarea, VStack, useToast } from '@chakra-ui/react';

import { Design, getCanvas, getDesignOutputImageFromJson } from '@/lib';
import { updateBaseDesign, updateDesignSide } from '@/api/designs';
import { useEffect, useState } from 'react';

import Button from '@/components/button';
import FormInput from '@/components/form/FormInput';
import { useLocation } from 'react-router-dom';
import { getFullPrintableAreaImage } from '@/lib/utils/canvas-manufacturing-image-export';
import { uploadBase64ToStorage } from '@/api/storage';

interface SubmitRemixFormProps {
  design: Design;
  onSubmitted: (updates?: Partial<Design>) => void;
}

export default function SubmitRemixForm({ design, onSubmitted }: SubmitRemixFormProps) {
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState({ name: '', description: '' });

  const [savingDraft, setSavingDraft] = useState(false);
  const [submittingRemix, setSubmittingRemix] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const { name, description } = design;

    setValues({ name, description: description || '' });
  }, []);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (searchParams.get('fromAcme')) {
      onSubmitted();
    }
  }, []);

  const handleSubmit = async (isDraft = false) => {
    setSubmitted(true);

    const { name, description } = values;

    if (!name) {
      return;
    }

    if (isDraft) {
      setSavingDraft(true);
    } else {
      setSubmittingRemix(true);
    }

    try {
      const response = await updateBaseDesign({
        id: design.id,
        name,
        description: description || ' ',
        isPublished: true,
        redirectUrl: `${window.location.href}&fromAcme=true`,
      });

      const { sides } = design;

      const sidesWithImages = await Promise.all(
        sides.map(async (side) => {
          if (!side.canvasState || !JSON.parse(side.canvasState)?.objects?.length) {
            return null;
          }

          const { canvasState, templateSide } = side;

          const canvas = getCanvas(templateSide, true);

          const designImage = await getDesignOutputImageFromJson(canvas, canvasState);

          const manufacturingImage = await getFullPrintableAreaImage(
            designImage,
            side.templateSide
          );

          const manufacturingImageUrl = await uploadBase64ToStorage(
            manufacturingImage,
            'image/png'
          );

          return {
            ...side,
            manufacturingImageUrl,
          };
        })
      );

      for (const side of sidesWithImages.filter((side) => !!side)) {
        const { id, manufacturingImageUrl } = side;

        await updateDesignSide(design.id, {
          id,
          manufacturingImageUrl,
        });
      }

      if (isDraft) {
        toast({ title: 'Draft saved', status: 'success' });
      } else {
        const { acmeMintingLink } = response;

        if (!acmeMintingLink) {
          onSubmitted({ name, description });

          return;
        }

        const link = acmeMintingLink;

        window.location.href = link;
      }
    } catch (e) {
      console.error(e);
      toast({ title: 'Error saving remix', status: 'error' });
    } finally {
      setSavingDraft(false);
      setSubmittingRemix(false);
    }
  };

  const { name, description } = values;

  return (
    <VStack align="center" alignItems="flex-start">
      <Box mt="16px" w="100%">
        <VStack spacing="12px">
          <FormInput
            error={submitted && !name ? 'Give it a name' : ''}
            name="Merch Name"
            description="[Graphic] – [Descriptor], e.g. Smiley – Bold Style"
            value={name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <Textarea
            placeholder="Description (optional)"
            value={description}
            mt="7px"
            onChange={(e) => setValues({ ...values, description: e.target.value })}
          />
          <HStack spacing="12px" w="100%">
            <Button isLoading={submittingRemix} onClick={() => handleSubmit()} flex={1}>
              Publish design
            </Button>
            <Button isLoading={savingDraft} onClick={() => handleSubmit(true)} secondary flex={1}>
              Save draft
            </Button>
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
}
