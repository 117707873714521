import { Icon } from '@chakra-ui/react';

const IconCredits = ({ outlined = false }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9519 13.9657C17.9519 16.3501 14.6155 18.283 10.4999 18.283C6.38424 18.283 3.04785 16.3501 3.04785 13.9657C3.04785 11.5813 6.38424 9.64844 10.4999 9.64844C14.6155 9.64844 17.9519 11.5813 17.9519 13.9657Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0674 15.8071C16.2032 15.1491 16.5519 14.4484 16.5519 13.9657C16.5519 13.483 16.2032 12.7823 15.0674 12.1243C13.9678 11.4873 12.356 11.0484 10.4999 11.0484C8.64375 11.0484 7.03195 11.4873 5.93231 12.1243C4.7966 12.7823 4.44785 13.483 4.44785 13.9657C4.44785 14.4484 4.7966 15.1491 5.93231 15.8071C7.03195 16.4441 8.64375 16.883 10.4999 16.883C12.356 16.883 13.9678 16.4441 15.0674 15.8071ZM10.4999 18.283C14.6155 18.283 17.9519 16.3501 17.9519 13.9657C17.9519 11.5813 14.6155 9.64844 10.4999 9.64844C6.38424 9.64844 3.04785 11.5813 3.04785 13.9657C3.04785 16.3501 6.38424 18.283 10.4999 18.283Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <path
      d="M17.9519 10.0712C17.9519 12.4555 14.6155 14.3884 10.4999 14.3884C6.38424 14.3884 3.04785 12.4555 3.04785 10.0712C3.04785 7.68681 6.38424 5.75391 10.4999 5.75391C14.6155 5.75391 17.9519 7.68681 17.9519 10.0712Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0674 11.9125C16.2032 11.2546 16.5519 10.5539 16.5519 10.0712C16.5519 9.58848 16.2032 8.88776 15.0674 8.22979C13.9678 7.59273 12.356 7.15391 10.4999 7.15391C8.64375 7.15391 7.03195 7.59273 5.93231 8.22979C4.7966 8.88776 4.44785 9.58848 4.44785 10.0712C4.44785 10.5539 4.7966 11.2546 5.93231 11.9125C7.03195 12.5496 8.64375 12.9884 10.4999 12.9884C12.356 12.9884 13.9678 12.5496 15.0674 11.9125ZM10.4999 14.3884C14.6155 14.3884 17.9519 12.4555 17.9519 10.0712C17.9519 7.68681 14.6155 5.75391 10.4999 5.75391C6.38424 5.75391 3.04785 7.68681 3.04785 10.0712C3.04785 12.4555 6.38424 14.3884 10.4999 14.3884Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <path
      d="M17.9519 6.17664C17.9519 8.561 14.6155 10.4939 10.4999 10.4939C6.38424 10.4939 3.04785 8.561 3.04785 6.17664C3.04785 3.79228 6.38424 1.85937 10.4999 1.85937C14.6155 1.85937 17.9519 3.79228 17.9519 6.17664Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0674 8.01801C16.2032 7.36005 16.5519 6.65933 16.5519 6.17664C16.5519 5.69395 16.2032 4.99323 15.0674 4.33526C13.9678 3.69819 12.356 3.25937 10.4999 3.25937C8.64375 3.25937 7.03195 3.69819 5.93231 4.33526C4.7966 4.99323 4.44785 5.69395 4.44785 6.17664C4.44785 6.65933 4.7966 7.36005 5.93231 8.01801C7.03195 8.65508 8.64375 9.0939 10.4999 9.0939C12.356 9.0939 13.9678 8.65508 15.0674 8.01801ZM10.4999 10.4939C14.6155 10.4939 17.9519 8.561 17.9519 6.17664C17.9519 3.79228 14.6155 1.85937 10.4999 1.85937C6.38424 1.85937 3.04785 3.79228 3.04785 6.17664C3.04785 8.561 6.38424 10.4939 10.4999 10.4939Z"
      fill={outlined ? '#000000' : '#FFFFFF'}
    />
    <g clipPath="url(#clip0_6901_1099)">
      <path
        d="M8.66006 1.80469C5.15006 1.80469 2.31006 5.50469 2.31006 10.0647C2.31006 14.6247 5.15006 18.3247 8.66006 18.3247H12.3301C15.8401 18.3247 18.6801 14.6247 18.6801 10.0647C18.6801 5.50469 15.8401 1.80469 12.3301 1.80469L8.88006 1.80469H8.66006Z"
        fill={outlined ? '#FFFFFF' : '#064AC4'}
      />
      <path
        d="M5.59008 7.61469C5.89008 6.92469 6.31008 6.38469 6.84008 6.00469C7.37008 5.62469 7.98008 5.43469 8.67008 5.43469C9.56008 5.43469 10.2901 5.70469 10.8701 6.23469C11.4501 6.76469 11.8301 7.51469 12.0101 8.46469H10.7201C10.6001 7.91469 10.3601 7.48469 10.0001 7.17469C9.64008 6.86469 9.20008 6.70469 8.67008 6.70469C7.98008 6.70469 7.43008 6.99469 7.01008 7.58469C6.59008 8.16469 6.38008 8.99469 6.38008 10.0647C6.38008 11.1347 6.59008 11.9847 7.01008 12.5747C7.43008 13.1647 7.99008 13.4547 8.67008 13.4547C9.20008 13.4547 9.64008 13.3047 9.99008 12.9947C10.3401 12.6947 10.5801 12.2547 10.7201 11.6847H12.0101C11.8201 12.6047 11.4301 13.3347 10.8501 13.8847C10.2701 14.4347 9.54008 14.7147 8.67008 14.7147C7.98008 14.7147 7.37008 14.5247 6.84008 14.1447C6.31008 13.7647 5.89008 13.2247 5.59008 12.5247C5.29008 11.8247 5.14008 11.0047 5.14008 10.0647C5.14008 9.12469 5.29008 8.32469 5.59008 7.63469V7.61469Z"
        fill={outlined ? '#000000' : '#FFFFFF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66006 1.05469C6.62396 1.05469 4.83268 2.12978 3.5741 3.76772C2.3162 5.40477 1.56006 7.63384 1.56006 10.0647C1.56006 12.4955 2.3162 14.7246 3.5741 16.3617C4.83268 17.9996 6.62396 19.0747 8.66006 19.0747H12.3301C14.3662 19.0747 16.1574 17.9996 17.416 16.3617C18.6739 14.7246 19.4301 12.4955 19.4301 10.0647C19.4301 7.63384 18.6739 5.40477 17.416 3.76772C16.1574 2.12978 14.3662 1.05469 12.3301 1.05469L8.66006 1.05469ZM3.06006 10.0647C3.06006 7.93553 3.72392 6.03461 4.76352 4.68166C5.80244 3.32959 7.18615 2.55469 8.66006 2.55469H8.76007V2.5647C10.2725 2.5647 11.6768 3.36199 12.7187 4.71699C13.761 6.07244 14.4201 7.96556 14.4201 10.0747C14.4201 12.1838 13.761 14.077 12.7187 15.4324C11.7444 16.6995 10.4533 17.4789 9.05312 17.5747H8.66006C7.18615 17.5747 5.80244 16.7998 4.76352 15.4477C3.72392 14.0948 3.06006 12.1938 3.06006 10.0647ZM11.9131 17.5747H12.3301C13.804 17.5747 15.1877 16.7998 16.2266 15.4477C17.2662 14.0948 17.9301 12.1938 17.9301 10.0647C17.9301 7.93553 17.2662 6.03461 16.2266 4.68166C15.1877 3.32959 13.804 2.55469 12.3301 2.55469H11.8837C12.4916 2.96729 13.0399 3.49425 13.5114 4.10741C14.6991 5.65196 15.4201 7.76384 15.4201 10.0747C15.4201 12.3856 14.6991 14.4974 13.5114 16.042C13.0475 16.6453 12.5093 17.1651 11.9131 17.5747Z"
        fill={outlined ? '#000000' : '#FFFFFF'}
      />
    </g>
    <defs>
      <clipPath id="clip0_6901_1099">
        <rect
          width="17.88"
          height="18.03"
          fill={outlined ? '#000000' : '#FFFFFF'}
          transform="translate(1.56006 1.05469)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default IconCredits;
