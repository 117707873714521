import axios from 'axios';

import { Cart, CartItem } from '@/components/types';

const URL = '/cart';
const URL_ORDERS = '/orders';

export const getCheckoutUrl = () => axios.get<string>(`${URL}/checkout`).then(({ data }) => data);

export const getCart = (): Promise<Cart> => axios.get<Cart>(URL).then(({ data }) => data);

export const getOrders = (): Promise<Cart[]> =>
  axios.get<Cart[]>(URL_ORDERS).then(({ data }) => data);

export const createCartItem = (item: CartItem): Promise<CartItem> =>
  axios.post<CartItem>(`${URL}/item`, item).then(({ data }) => data);

export const updateCartItem = (id: string, quantity: number) =>
  axios.patch<CartItem>(`${URL}/item/${id}`, { quantity }).then(({ data }) => data);

export const deleteCartItem = (id: string) => axios.delete(`${URL}/item/${id}`);
