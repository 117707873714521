import { Card, CardBody, HStack, Image, Text, VStack } from '@chakra-ui/react';

import Button from '@/components/button';

import SampleApps from './images/SampleApps.png';
import DeveloperDocumentation from './images/DeveloperDocumentation.png';

const INFO_BOXES = [
  {
    title: 'Sample Apps',
    description: 'Get up and running quickly with our example apps.',
    action: 'Check out',
    image: SampleApps,
    link: 'https://github.com/Space-Runners',
  },
  {
    title: 'Developer Documentation',
    description:
      'Read our Developer documentation that covers how to implement our SDK into your app.',
    action: 'Read',
    image: DeveloperDocumentation,
    link: 'http://developer.ablo.ai/',
  },
];

const InfoBoxes = () => (
  <HStack align="flex-start" w="100%">
    {INFO_BOXES.map(({ title, description, action, image, link }, index) => (
      <Card bg="#FFFFFF" borderRadius="20px" flex={1} h="100%" key={index}>
        <CardBody h="100%" p="20px">
          <HStack align="flex-start" h="100%">
            <Image src={image} h="100px" w="100px" />
            <VStack align="flex-start" h="100%" justify="space-between" spacing="10px">
              <Text color="secondaryDarkGray.900" fontSize="20px" fontWeight={700}>
                {title}
              </Text>
              <Text color="#939393" fontSize="sm">
                {description}
              </Text>
              <Button as="a" href={link} target="_blank">
                {action}
              </Button>
            </VStack>
          </HStack>
        </CardBody>
      </Card>
    ))}
  </HStack>
);

export default InfoBoxes;
