export enum Hint {
  SIDE_PICKER = 'SIDE_PICKER_HINT',
  CUSTOM_QR_CODE = 'CUSTOM_QR_CODE',
  CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATES',
  PINCH_ZOOM = 'PINCH_ZOOM_HINT',
  FINISH_AND_SHARE = 'FINISH_AND_SHARE_HINT',
}

const TOTAL_STEPS = 5;

export const getCanvasHintProps = (hint: Hint, isMobile = false) => {
  if (hint === Hint.SIDE_PICKER) {
    return {
      title: 'Select Side',
      description: 'Toggle between different views to customize each drawable area.',
      totalSteps: TOTAL_STEPS,
      step: 1,
      offset: [0, 20] as [number, number],
    };
  }

  if (hint === Hint.CUSTOM_QR_CODE) {
    return {
      title: 'Custom QR Code',
      description:
        'Instantly generate a customizable QR code on your product, link it your website, social media, Spotify playlists, etc.',
      totalSteps: TOTAL_STEPS,
      step: 2,
      next: Hint.CUSTOM_TEMPLATE,
      offset: [80, 20] as [number, number],
    };
  }

  if (hint === Hint.CUSTOM_TEMPLATE) {
    return {
      title: 'Add or Remove Templates',
      description: 'Add trending templates to your design and make your custom design even cooler!',
      totalSteps: TOTAL_STEPS,
      step: 3,
      next: Hint.PINCH_ZOOM,
      offset: [80, 20] as [number, number],
    };
  }

  if (hint === Hint.PINCH_ZOOM) {
    return {
      title: isMobile ? 'Pinch to zoom in & out' : 'Zoom Feature',
      description: isMobile
        ? 'Zoom in and out using fingers to fine-tune your design details.'
        : 'Use the + and - buttons to zoom in and out of the art board. Click and hold anywhere on the art board, then drag to move around.',
      totalSteps: TOTAL_STEPS,
      step: 4,
      next: Hint.FINISH_AND_SHARE,
    };
  }

  if (hint === Hint.FINISH_AND_SHARE) {
    return {
      title: 'Finish & Share',
      description: 'Complete your design and share your unique creation.',
      totalSteps: TOTAL_STEPS,
      step: 5,
      next: null,
    };
  }

  return null;
};
