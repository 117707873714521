import { Ablo } from '@space-runners/ablo-ts-sdk';
import { StorageKeys } from '../constants';
import Config from '../config';

const { API_URL } = Config;

export const uploadBase64ToStorage = async (
  image: string,
  contentType: string
): Promise<string> => {
  const ablo = new Ablo(localStorage.getItem(StorageKeys.API_KEY), { baseUrl: API_URL });

  const url = await ablo.storage.uploadBase64(image, contentType);
  return url;
};
export const uploadBlobToStorage = async (
  image: File | ArrayBuffer | string,
  contentType: string
): Promise<string> => {
  const ablo = new Ablo(localStorage.getItem(StorageKeys.API_KEY), { baseUrl: API_URL });
  const url = await ablo.storage.uploadBlob(image, contentType);
  return url;
};
