import { HStack, Text } from '@chakra-ui/react';

const OrLine = () => {
  return (
    <HStack justify="center" spacing="12px" width="100%">
      <Text color="#A0AEC0" fontSize="sm" textAlign="center">
        or
      </Text>
    </HStack>
  );
};

export default OrLine;
