import { Icon } from '@chakra-ui/react';

const IconInfo = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.22797 7.79622V14.3417H10.7706V7.79622H9.22797Z" fill="#CBD5E0" />
    <path
      d="M9.37285 6.62434C9.54899 6.78628 9.75922 6.86724 10.0035 6.86724C10.2507 6.86724 10.4609 6.78628 10.6342 6.62434C10.8104 6.45957 10.8984 6.26213 10.8984 6.03202C10.8984 5.79906 10.8104 5.60162 10.6342 5.43969C10.4609 5.27491 10.2507 5.19253 10.0035 5.19253C9.75922 5.19253 9.54899 5.27491 9.37285 5.43969C9.19672 5.60162 9.10865 5.79906 9.10865 6.03202C9.10865 6.26213 9.19672 6.45957 9.37285 6.62434Z"
      fill="#CBD5E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3551 9.99982C18.3551 14.6143 14.6143 18.3551 9.99982 18.3551C5.38532 18.3551 1.64453 14.6143 1.64453 9.99982C1.64453 5.38532 5.38532 1.64453 9.99982 1.64453C14.6143 1.64453 18.3551 5.38532 18.3551 9.99982ZM16.9551 9.99982C16.9551 13.8411 13.8411 16.9551 9.99982 16.9551C6.15852 16.9551 3.04453 13.8411 3.04453 9.99982C3.04453 6.15852 6.15852 3.04453 9.99982 3.04453C13.8411 3.04453 16.9551 6.15852 16.9551 9.99982Z"
      fill="#CBD5E0"
    />
  </Icon>
);

export default IconInfo;
