import {
  Center,
  HStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
} from '@chakra-ui/react';

import Button from '../buttons/ButtonCTA';

const ButtonProps = {
  fontSize: 'xs',
  w: '135px',
};

type Props = {
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({ onClose, onConfirm }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="772px" maxHeight="426px">
        <ModalBody padding={0}>
          <Center
            bg="linear-gradient(105deg, #CEEDFF 16.17%, rgba(255, 255, 255, 0.00) 87.53%)"
            borderTopLeftRadius={20}
            h="203px"
          >
            <Text as="b" color="black.600" fontSize="32px">
              Downgrading plan
            </Text>
          </Center>
          <Flex align="center" direction="column" padding="16px 22px 69px 22px">
            <Text color="black.700" fontSize="sm" fontWeight={700} mb="16px">
              Are you sure?
            </Text>
            <Text color="black.700" fontSize="sm" mb="38px">
              I'm aware that I'm downgrading my plan
            </Text>
            <HStack spacing="10px">
              <Button outlined onClick={onClose} {...ButtonProps}>
                Cancel
              </Button>
              <Button onClick={onConfirm} {...ButtonProps}>
                Confirm
              </Button>
            </HStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
