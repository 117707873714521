import { HStack, Text } from '@chakra-ui/react';
import IconCredits from '../../icons/IconCredits';

const CreditCostIcon = ({ cost, outlined }: { cost: number; outlined?: boolean }) =>
  cost ? (
    <HStack ml="4px" spacing="1px">
      <IconCredits outlined={outlined} />
      <Text>{cost}</Text>
    </HStack>
  ) : null;

export default CreditCostIcon;
