import { isArray } from 'lodash';

import { Template, TemplateSide } from '@/components/types';

import { DESIGN_TEMPLATE_REFERENCE_WIDTH } from '@/constants';

/* Top, left, width and height for the canvas drawing area is scaled to template image width of 500px,
 * which is how it currently is in the Ablo demo desktop version.
 */

export const getScalingFactor = (imageWidth) => imageWidth / DESIGN_TEMPLATE_REFERENCE_WIDTH;

export const getTemplateWithImagesForSides = (template: Template) => {
  const { sides: allSides, colors: allColors = [], ...rest } = template;

  const sides = allSides.filter(({ isDeleted }) => !isDeleted);

  const colors = allColors.filter(({ isDeleted }) => !isDeleted);

  const sidesWithImage = sides.map<TemplateSide>((side) => {
    if (side.image || !colors[0]) {
      return side;
    }

    const { images } = colors[0];

    const image =
      isArray(images) && images.find(({ templateSideId }) => templateSideId === side.id);

    return {
      ...side,
      image: image?.url,
    };
  });

  const colorsWithImagesForSides = colors.map((color) => {
    const { images } = color;

    if (!images || !isArray(images)) {
      return color;
    }

    const imagesForSides = {};

    sides.forEach(({ id }) => {
      const image = images.find(({ templateSideId }) => templateSideId === id);

      imagesForSides[id] = image;
    });

    return {
      ...color,
      images: imagesForSides,
    };
  });

  return { ...rest, colors: colorsWithImagesForSides, sides: sidesWithImage };
};
