import { Box, Card, CardBody, Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import type { Stripe } from 'stripe';

import IconCloud from './icons/IconCloud';
import Button from '../components/buttons/ButtonCTA';

import { getFormattedAmount } from '../utils';

type ActivePlanProps = {
  onChange: () => void;
  plan:
    | Stripe.Plan
    | {
        amount: number;
        currency: string;
        interval: string;
        product: {
          name: string;
        };
      };
};

const CLOUD_BOX_SIZE = 69;

const ActivePlan = ({ onChange, plan }: ActivePlanProps) => {
  const { amount, currency, interval, product } = plan;

  const { name } = product as Stripe.Product;

  return (
    <Card bg="#FFFFFF" borderRadius={20} height="100%">
      <CardBody p={0}>
        <HStack align="flex-start" borderBottom="1px solid #EEEEEE" p="14px 30px" spacing="21px">
          <Center
            bg="secondaryGray.300"
            borderRadius="50%"
            height={`${CLOUD_BOX_SIZE}px`}
            width={`${CLOUD_BOX_SIZE}px`}
          >
            <IconCloud />
          </Center>
          <VStack align="flex-start" spacing={0}>
            <Text color="secondaryDarkGray.600" fontSize="md" fontWeight={500}>
              Plan
            </Text>
            <Text color="black.700" fontSize="26px" fontWeight={700} mb="10px">
              {name}
            </Text>
            <Box
              border="1px solid #E3E3E3"
              borderRadius="16px"
              width="177px"
              height="40px"
              p="8px 30px"
            >
              <Text color="black.400" fontSize="sm">
                You’re on this plan
              </Text>
            </Box>
          </VStack>
        </HStack>
        <Flex align="center" justify="space-between" p="18px 14px">
          <HStack align="flex-end" spacing="5px">
            <Text color="black.700" fontSize="30px" fontWeight={700} lineHeight="46px">
              {getFormattedAmount(currency, amount)}
            </Text>
            <Text
              bottom="8px"
              color="secondaryDarkGray.600"
              fontSize="13px"
              fontWeight={500}
              position="relative"
            >
              / {interval}
            </Text>
          </HStack>
          <Button onClick={onChange} w="120px">
            Change
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ActivePlan;
