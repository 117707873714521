import { Button as ChakraButton, HStack } from '@chakra-ui/react';

import IconColorPicker from '../../components/icons/IconColorPicker';

import { useState } from 'react';
import ColorPickerModal from '../../components/modals/ColorPickerModal';

const Button = ({ isSelected = false, ...rest }) => {
  const SIZE = 25;

  return (
    <ChakraButton
      border={isSelected ? '4px solid' : 'none'}
      borderColor="#000000"
      minWidth={`${SIZE}px`}
      padding={0}
      width={`${SIZE}px`}
      height={`${SIZE}px`}
      borderRadius="50%"
      {...rest}
    />
  );
};

type ColorPickerProps = {
  selectedColor: string;
  onUpdateColor: (color: string) => void;
};

export default function ColorPicker({ selectedColor, onUpdateColor }: ColorPickerProps) {
  const [isColorPickerModalVisible, setColorPickerModalVisible] = useState(false);

  const COLORS = ['#FFFFFF', '#050606', '#EA2824', '#019548', '#4063AE', '#FDDE0A', '#8399AA'];

  return (
    <HStack mt="8px" overflow="auto" pl="20px" pb="8px" spacing="10px">
      {COLORS.map((color) => (
        <Button
          bg={color}
          border={
            color === selectedColor
              ? '1px solid'
              : color === '#FFFFFF'
              ? '1px solid #EAE9E9'
              : 'none'
          }
          borderColor={color === selectedColor ? 'brand.500' : 'none'}
          isSelected={color === selectedColor}
          key={color}
          onClick={() => onUpdateColor(color)}
        />
      ))}
      <Button onClick={() => setColorPickerModalVisible(true)}>
        <IconColorPicker />
      </Button>
      {isColorPickerModalVisible ? (
        <ColorPickerModal
          onClose={() => setColorPickerModalVisible(false)}
          onSelectedColor={(color) => {
            onUpdateColor(color);
          }}
          selectedColor={selectedColor}
        />
      ) : null}
    </HStack>
  );
}
