interface CreateYourStyleVideoProps {
  width: string;
}

const CreateYourStyleVideo = ({ width }: CreateYourStyleVideoProps) => (
  <video
    autoPlay
    style={{
      borderRadius: '24px',
      width,
      objectFit: 'cover',
    }}
    loop
    muted
    playsInline
    src="ABLO_FigmaCut.mp4"
  />
);

export default CreateYourStyleVideo;
