import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Flex, VStack, Text, Center, Image, HStack } from '@chakra-ui/react';

import JSZip from 'jszip';

import { createCustomStyle } from '@/api/custom-styles';
import Button from '@/components/button';
import FormInput from '@/components/form/FormInput';
import IconUpload from '@/components/icons/IconUpload';

import ImageUpload from '@/lib/components/upload/ImageUpload';
import getResizedImage from '@/utils/resizeImage';

import TrainingStartedMessage from './TrainingStartedMessage';

const THUMBNAIL_WIDTH = 90;
const MAX_IMAGE_SIZE = 512;

const getImageWithLimits = (image: HTMLImageElement) =>
  image.width > MAX_IMAGE_SIZE || image.height > MAX_IMAGE_SIZE
    ? getResizedImage(image.src, image.width, image.height, MAX_IMAGE_SIZE)
    : image.src;

const CreateCustomStyle = () => {
  const [name, setName] = useState('');
  const [coverImage, setCoverImage] = useState<{ preview: string; file: File }>(null);
  const [trainingImages, setTrainingImages] = useState<string[]>(null);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [waiting, setWaiting] = useState(false);

  const handleSubmit = () => {
    setError(null);

    if (!coverImage) {
      setError('Please upload a cover image.');

      return;
    }

    if (!trainingImages || trainingImages.length < 10) {
      setError('Please upload at least 10 training images.');

      return;
    }

    if (!name) {
      setError('Please add a name.');

      return;
    }

    const zip = new JSZip();

    trainingImages.forEach((image, index) => {
      zip.file(`Image_${index}.png`, image.split(',')[1], { base64: true });
    });

    zip.generateAsync({ type: 'blob' }).then((zipBlob) => {
      const sizeInMB = zipBlob.size / (1024 * 1024);

      if (sizeInMB > 50) {
        setError('Zipped training data size has to be less than 50MB. Please remove some images.');

        return;
      }

      setWaiting(true);

      createCustomStyle({ name, trainingData: zipBlob, coverImage: coverImage.file })
        .then(() => {
          setWaiting(false);

          setSuccess(true);
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
          setWaiting(false);
        });
    });
  };

  const handleCoverImageUploaded = (image: HTMLImageElement) => {
    const coverImageAsBase64 = getImageWithLimits(image);

    fetch(coverImageAsBase64)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'coverImage', { type: 'image/png' });

        setCoverImage({ preview: coverImageAsBase64, file });
      });
  };

  const handleTrainingImagesUploaded = (images: HTMLImageElement[]) => {
    const formattedImages = images.map(getImageWithLimits);

    setTrainingImages(formattedImages);
  };

  return (
    <Box>
      <VStack
        bg="linear-gradient(180deg, #064AC4 0%, #00338F 100%);"
        borderRadius="19px"
        height="530px"
        pt="68px"
        width="100%"
      >
        <Box>
          <Link to="/custom-art-styles">
            <Text color="#FFFFFF" fontSize="md" fontWeight={700}>
              Back to List
            </Text>
          </Link>
          {success ? (
            <TrainingStartedMessage />
          ) : (
            <VStack
              align="flex-start"
              bg="#FFFFFF"
              borderRadius="20px"
              mt="12px"
              p="50px 66px"
              position="relative"
              spacing="20px"
              userSelect="none"
              w="785px"
            >
              <Text color="black.700" fontSize="26px" fontWeight={700}>
                Custom Art Style
              </Text>
              <VStack align="flex-start" spacing="10px">
                <Text color="black.700" fontWeight={500}>
                  Add Cover image
                </Text>
                <ImageUpload
                  customButtonContent={
                    coverImage ? (
                      <Image borderRadius={20} src={coverImage.preview} w={THUMBNAIL_WIDTH} />
                    ) : (
                      <Center
                        width={`${THUMBNAIL_WIDTH}px`}
                        padding="18px 13px"
                        borderRadius="16px"
                        border="1px dashed"
                        borderColor="secondaryGray.500"
                        cursor="pointer"
                      >
                        <VStack spacing="10px">
                          <IconUpload />
                          <Text color="secondaryDarkGray.500" fontSize="10px" fontWeight={400}>
                            Upload cover
                          </Text>
                        </VStack>
                      </Center>
                    )
                  }
                  onImagesUploaded={(images) => handleCoverImageUploaded(images[0])}
                />
              </VStack>
              <FormInput
                name="Name the Art Style"
                placeholder="Ex) Hiphop pig"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Text color="black.600" fontSize="10px" w="448px">
                Ensure a signature style by uploading at least 10 images, with a maximum of 15.
                <br />
                Accepted formats: JPEG or PNG.
              </Text>
              <Box alignSelf="stretch">
                <ImageUpload multiple onImagesUploaded={handleTrainingImagesUploaded} />
              </Box>
              {trainingImages ? (
                <HStack spacing="20px" wrap="wrap" w="100%">
                  {trainingImages.map((image, index) => (
                    <Image key={index} borderRadius={20} src={image} w={THUMBNAIL_WIDTH} />
                  ))}
                </HStack>
              ) : null}
              {error ? (
                <Flex bg="red.200" borderRadius="14px" padding="12px" width="100%">
                  <Text color="red.400" textAlign="left">
                    {error}
                  </Text>
                </Flex>
              ) : null}
              <Button isLoading={waiting} onClick={handleSubmit} w="128px">
                Submit
              </Button>
            </VStack>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default CreateCustomStyle;
