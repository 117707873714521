import { useEffect } from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

const ButtonCTA = ({ onClick, ...rest }) => {
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        onClick();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onClick]);

  return (
    <ChakraButton
      bg="brand.600"
      borderRadius="16px"
      color="#FFFFFF"
      fontSize="sm"
      h="54px"
      p="8px 16px"
      onClick={onClick}
      {...rest}
    />
  );
};

export default ButtonCTA;
