import { Icon } from '@chakra-ui/react';

const IconInfo = () => (
  <Icon
    width="28px"
    height="27px"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.7648 9.97414V20.4469H15.233V9.97414H12.7648Z" fill="black" />
    <path
      d="M12.9967 8.09914C13.2785 8.35823 13.6148 8.48778 14.0058 8.48778C14.4012 8.48778 14.7376 8.35823 15.0148 8.09914C15.2967 7.8355 15.4376 7.5196 15.4376 7.15141C15.4376 6.77869 15.2967 6.46278 15.0148 6.20369C14.7376 5.94005 14.4012 5.80823 14.0058 5.80823C13.6148 5.80823 13.2785 5.94005 12.9967 6.20369C12.7148 6.46278 12.5739 6.77869 12.5739 7.15141C12.5739 7.5196 12.7148 7.8355 12.9967 8.09914Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3683 13.4999C27.3683 20.8831 21.383 26.8684 13.9998 26.8684C6.61661 26.8684 0.631348 20.8831 0.631348 13.4999C0.631348 6.1167 6.61661 0.131439 13.9998 0.131439C21.383 0.131439 27.3683 6.1167 27.3683 13.4999ZM25.1283 13.4999C25.1283 19.646 20.1459 24.6284 13.9998 24.6284C7.85373 24.6284 2.87135 19.646 2.87135 13.4999C2.87135 7.35382 7.85373 2.37144 13.9998 2.37144C20.1459 2.37144 25.1283 7.35382 25.1283 13.4999Z"
      fill="black"
    />
  </Icon>
);

export default IconInfo;
