import { useState } from 'react';

import { Box, Button, Text } from '@chakra-ui/react';
import IconUp from './IconUp';
import IconDown from './IconDown';

const Panel = ({ children, title, ...rest }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Box
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="borderSecondary"
      w={{ base: '100%', md: '382px' }}
      {...rest}
    >
      <Button
        alignItems="center"
        bg="transparent"
        borderRadius={0}
        color="#0000000"
        display="flex"
        fontWeight={400}
        height="63px"
        justifyContent="space-between"
        onClick={() => setOpen(!isOpen)}
        padding={{ base: '22px 14px', md: '22px 0' }}
        w="100%"
        _hover={{ bg: '' }}
        _active={{
          bg: '',
        }}
        _focus={{
          bg: '',
          boxShadow: '',
        }}
      >
        <Text fontSize={{ base: 'sm', md: 'md' }} mr="8px">
          {title}
        </Text>
        {isOpen ? <IconUp /> : <IconDown />}
      </Button>
      {isOpen ? <Box>{children}</Box> : null}
    </Box>
  );
};

export default Panel;
