import { Icon } from '@chakra-ui/react';

const IconCloseModal = () => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="white" />
    <path
      d="M12.0003 13.2728L18.1521 19.4246L19.4249 18.1518L13.2731 12L19.4249 5.84817L18.1521 4.57538L12.0003 10.7272L5.84845 4.57538L4.57566 5.84817L10.7275 12L4.57566 18.1518L5.84845 19.4246L12.0003 13.2728Z"
      fill="#A0AEC0"
    />
  </Icon>
);

export default IconCloseModal;
