import AppContainer from '@/layouts/AppContainer';

import CollectionsList from './components/CollectionList';

const CollectionsListPage = () => {
  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      <CollectionsList />
    </AppContainer>
  );
};

export default CollectionsListPage;
