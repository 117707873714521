import { Center, Image } from '@chakra-ui/react';

import { Design } from '@/lib/types';

type DesignPreviewProps = {
  sideName: string;
  design: Design;
  height?: number | string;
  width: number | string;
  imageWidth: number;
};

const SubmitDesignSideImage = ({
  design,
  sideName,
  imageWidth,
  height,
  width,
}: DesignPreviewProps) => {
  const { sides = [] } = design;

  const designSide = sides.find(({ templateSide }) => templateSide.name === sideName) || {};

  const { previewImage } = designSide;

  return (
    <Center
      bg="secondaryGray.300"
      borderRadius={4}
      cursor="pointer"
      position="relative"
      height={height}
      width={width}
    >
      <Image src={previewImage} width={`${imageWidth}px`} />
    </Center>
  );
};

export default SubmitDesignSideImage;
