import { HStack, Text, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import IconVote from './icons/IconVote';

import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import VoteButton from './VoteButton';
import Button from '../button';
import IconCart from '../icons/IconCart';
import AddToCartModal from '../cart/AddToCartModal';
import { Template } from '../types';
import DesignCardContainer from './DesignCardContainer';
import { useState } from 'react';

const ButtonProps = {
  w: '100%',
};

type RemixCardProps = {
  design: Design;
  votingForDesign?: string;
  hasVoted?: boolean;
  onVote?: () => void;
  onAddToCart?: () => void;
  height?: { base: number; lg: number };
  hideVoteButton?: boolean;
};

const RemixCard = ({
  design,
  votingForDesign,
  hasVoted,
  onVote,
  onAddToCart,
  height,
  hideVoteButton,
}: RemixCardProps) => {
  const [isAddToCartModalVisible, setAddToCartModalVisible] = useState(false);

  const { name, numVotes, template, user } = design;

  const { contestEndAt } = template as Template;

  const secondsToEnd = Math.floor((new Date(contestEndAt).getTime() - new Date().getTime()) / 1000);

  const hasVotingEnded = !contestEndAt || secondsToEnd <= 0;

  const isVotingDisabled = hasVotingEnded || !onVote;

  return (
    <>
      <DesignCardContainer
        design={design}
        childrenOnHover={
          <VStack mt="4px" spacing="8px" w="100%">
            <Button
              small
              {...ButtonProps}
              onClick={() => (onAddToCart ? onAddToCart() : setAddToCartModalVisible(true))}
            >
              <HStack spacing="6px">
                <IconCart />
                <Text as="b" fontSize="sm">
                  Add to cart
                </Text>
              </HStack>
            </Button>
            {!hideVoteButton ? (
              <VoteButton
                hasVoted={hasVoted}
                hasVotingEnded={hasVotingEnded}
                isLoading={votingForDesign === design.id}
                onClick={onVote}
                h="38px"
                p="0 16px"
                {...ButtonProps}
              >
                <HStack spacing={1}>
                  {isVotingDisabled ? null : <IconVote />}
                  <Text>
                    {numVotes} Vote{numVotes === 1 ? '' : 's'}
                  </Text>
                </HStack>
              </VoteButton>
            ) : null}
          </VStack>
        }
        height={height}
      >
        <>
          <Text
            fontSize="md"
            fontWeight={700}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxW="100%"
          >
            {name}
          </Text>
          <CreatorAvatar user={user} />
          <Text as="b" fontSize="sm">
            ${template.price}
          </Text>
        </>
      </DesignCardContainer>
      {isAddToCartModalVisible ? (
        <AddToCartModal
          designId={design.id}
          goBackText="Go Back"
          onClose={() => setAddToCartModalVisible(false)}
        />
      ) : null}
    </>
  );
};

export default RemixCard;
