import { buttonStyles } from './button';
import { checkboxStyle } from './checkbox';
import { closeButton } from './close-button';

import { textAreaStyles } from './textarea';

import { formStyles } from './form';
import { tabsStyles } from './tabs';

export const components = {
  Checkbox: checkboxStyle,
  Form: formStyles,
  Heading: {
    baseStyle: {
      color: '#000000',
      fontWeight: 700,
    },
  },
  Tooltip: {
    baseStyle: {
      border: '1px solid',
      borderColor: 'borderSecondary',
      borderRadius: '4px',
      bg: '#FFFFFF',
      color: 'inherit',
      fontSize: 'sm',
      fontWeight: 500,

      padding: '8px 12px',
    },
  },
  CloseButton: closeButton,
  Tabs: tabsStyles,
  Textarea: textAreaStyles,
  ...buttonStyles,
};
