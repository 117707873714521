import { VStack, Text } from '@chakra-ui/react';

import Button from '@/components/button';

type FormContainerProps = {
  children: React.ReactNode;
  onNext: () => void;
  nextDisabled?: boolean;
  nextLabel?: string;
  title: string;
  description?: string;
  waiting?: boolean;
};

const FormContainer = ({
  children,
  title,
  description,
  nextDisabled,
  nextLabel,
  onNext,
  waiting,
}: FormContainerProps) => (
  <VStack
    align="flex-start"
    bg="#FFFFFF"
    borderRadius="20px"
    maxW="1000px"
    p="50px 66px"
    position="relative"
    userSelect="none"
  >
    <Text color="black.700" fontSize="26px" fontWeight={700}>
      {title}
    </Text>
    <Text color="secondaryGray.600">{description}</Text>
    {children}
    {onNext ? (
      <Button isDisabled={nextDisabled} isLoading={waiting} mt="20px" onClick={onNext} w="128px">
        {nextLabel || 'Next'}
      </Button>
    ) : null}
  </VStack>
);

export default FormContainer;
