import { Icon } from '@chakra-ui/react';

const IconTextToImage = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.36659 4.2373L12.0265 5.34248L8.36659 6.44765L7.26142 10.1076L6.15625 6.44765L2.49634 5.34248L6.15625 4.2373L7.26142 0.577393L8.36659 4.2373Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3971 6.71302L21.5983 2.15222L19.7996 6.71302L15.2388 8.51178L19.7996 10.3105L21.5983 14.8713L23.3971 10.3105L27.9579 8.51178L23.3971 6.71302ZM24.142 8.51178L22.3178 7.79231L21.5983 5.96807L20.8789 7.79231L19.0546 8.51178L20.8789 9.23125L21.5983 11.0555L22.3178 9.23125L24.142 8.51178Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3463 14.4781L12.365 6.91875L9.3836 14.4781L1.82428 17.4594L9.3836 20.4408L12.365 28.0001L15.3463 20.4408L22.9056 17.4594L15.3463 14.4781ZM17.1819 17.4594L13.7274 16.097L12.365 12.6425L11.0025 16.097L7.54806 17.4594L11.0025 18.8218L12.365 22.2763L13.7274 18.8218L17.1819 17.4594Z"
      fill="black"
    />
  </Icon>
);

export default IconTextToImage;
