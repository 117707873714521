import axios from 'axios';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { Stripe } from 'stripe';
import { IBillableAction } from '@space-runners/ablo-ts-sdk';
import { ablo } from './ablo-wrapper';

const entity = 'subscription';

const URL = `/${entity}`;

const getPlans = () => axios.get<Stripe.Plan[]>(`${URL}/plans`).then(({ data }) => data);

export const usePlans = () => useQuery(['plans'], () => getPlans());

const getSubscriptionStatus = () => axios.get<Stripe.Subscription>(URL).then(({ data }) => data);

export const useSubscriptionStatus = () =>
  useQuery([entity], () => getSubscriptionStatus(), { retry: 0 });

export const createSubscription = (planId) =>
  axios
    .post<Stripe.Subscription>(URL, {
      planId,
    })
    .then(({ data }) => data);

export const updateSubscription = (planId) =>
  axios
    .put<Stripe.Subscription>(URL, {
      planId,
    })
    .then(({ data }) => data);

const getPaymentMethod = () =>
  axios.get<Stripe.PaymentMethod>(`${URL}/payment-method`).then(({ data }) => data);

export const usePaymentMethod = () => useQuery(['payment-method'], () => getPaymentMethod());

export const updatePaymentMethod = () =>
  axios.post<Stripe.Checkout.Session>(`${URL}/payment-method`).then(({ data }) => data);
const getInvoices = () => axios.get<Stripe.Invoice[]>(`${URL}/invoices`).then(({ data }) => data);

export const useInvoices = () => useQuery(['invoices'], () => getInvoices());

export const useCancelSubscription = () => {
  const client = useQueryClient();

  const { mutate: cancelSubscription } = useMutation(() => axios.delete(URL), {
    onSuccess: () => client.invalidateQueries([entity]),
  });

  return {
    cancelSubscription,
  };
};

export const sendCustomPlanInquiry = () => axios.post(`${URL}/custom-plan-inquiry`);

export const getBillableActions = async (): Promise<IBillableAction[]> => {
  const response = await ablo().billableActions.getAll();

  return response;
};

export const useBillableActions = () => useQuery(['billable-actions'], () => getBillableActions());
