import { Alert, AlertDescription, Box, HStack, VStack } from '@chakra-ui/react';
import IconError from './IconError';

const LOW_RISK = 33;
const MODERATE_RISK = 66;

type IpInfringementAlertProps = {
  riskScore: number;
};

const IpInfringementAlert = ({ riskScore }: IpInfringementAlertProps) => {
  let color = null;
  let colorText = null;
  let risk = null;
  if (riskScore && riskScore > 0) {
    if (riskScore <= LOW_RISK) {
      color = 'yellow.100';
      colorText = 'yellow.900';
      risk = 'Low';
    } else if (riskScore < MODERATE_RISK) {
      color = 'orange.100';
      colorText = 'orange.900';
      risk = 'Moderate';
    } else {
      color = 'red.100';
      colorText = 'red.900';
      risk = 'High';
    }
  }
  return (
    risk && (
      <Alert bg={color} justifyContent="space-between">
        <HStack align={'start'}>
          <IconError />
          <AlertDescription ps={1}>
            <VStack align={'start'}>
              <Box color={colorText} fontWeight={700}>
                {risk} risk
              </Box>
              <div>These images may be subject to copyright.</div>
            </VStack>
          </AlertDescription>
        </HStack>
      </Alert>
    )
  );
};

export default IpInfringementAlert;
