import {
  Design,
  getDesignSideWithCanvasState,
  getDesignSideWithPreviewImage,
  TemplateColorImage,
} from '@/lib';
import { Image, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const IMAGE_MOBILE_SIZE = 394;
const IMAGE_DESKTOP_SIZE = 500;

interface TemplatePreviewImageProps {
  topDesign: Design;
}

const TemplatePreviewImage = ({ topDesign }: TemplatePreviewImageProps) => {
  const { template } = topDesign;

  const { colors, sides } = template;

  const defaultPreviewImage =
    template.previewImage ||
    (colors[0].images as TemplateColorImage[]).find(
      ({ templateSideId }) => templateSideId === sides[0]?.id
    )?.url;

  const [previewImage, setPreviewImage] = useState(defaultPreviewImage);

  useEffect(() => {
    const loadDesignPreviewImage = async () => {
      const { sides } = topDesign;

      const firstSideWithDesign = sides.find(({ canvasStateUrl }) => !!canvasStateUrl);

      if (!firstSideWithDesign) {
        return;
      }

      const sideWithCanvasState = await getDesignSideWithCanvasState(firstSideWithDesign);

      const { previewImage } = await getDesignSideWithPreviewImage(topDesign, sideWithCanvasState);

      if (previewImage) {
        setPreviewImage(previewImage);
      }
    };

    loadDesignPreviewImage();
  }, [topDesign]);

  return (
    <Box
      bg="#ededed"
      display="flex"
      alignItems="center"
      justifyContent="center"
      h={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }} // No extra padding, only image size
      w={{ base: '100%', lg: IMAGE_DESKTOP_SIZE + 50 }}
      borderRadius={{ base: 0, '2sm': '24px', lg: '25px' }}
    >
      <Image
        objectFit="contain"
        src={previewImage}
        alt="Template image"
        h={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }}
        w={{ base: IMAGE_MOBILE_SIZE, lg: IMAGE_DESKTOP_SIZE }}
      />
    </Box>
  );
};

export default TemplatePreviewImage;
