import { Icon } from '@chakra-ui/react';

const IconCheckbox = ({ isChecked }) => {
  return (
    <>
      {isChecked ? (
        <Icon
          width="12px"
          height="12px"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3714 2.39978L5.43532 9.82694C5.27783 10.0639 4.94272 10.0971 4.74184 9.89556L1.19995 6.34264"
            stroke="white"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Icon>
      ) : null}
    </>
  );
};

export default IconCheckbox;
