import { Icon } from '@chakra-ui/react';

export const UploadButton = () => (
  <Icon
    width="60px"
    height="60px"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.26001 30.4111C0.26001 14.1187 13.4676 0.911133 29.76 0.911133C46.0524 0.911133 59.26 14.1187 59.26 30.4111C59.26 46.7035 46.0524 59.9111 29.76 59.9111C13.4676 59.9111 0.26001 46.7035 0.26001 30.4111Z"
      fill="#064AC4"
    />
    <path d="M30.406 23.5232H28.9806V33.3632H30.406V23.5232Z" fill="white" />
    <path
      d="M33.1332 27.8132L29.7598 24.2632L26.3864 27.8132L25.3792 26.7532L29.7598 22.1432L34.1405 26.7532L33.1332 27.8132Z"
      fill="white"
    />
    <path
      d="M36.3072 37.5733H23.2126V31.6033H24.638V36.0733H34.8818V31.6033H36.3072V37.5733Z"
      fill="white"
    />
  </Icon>
);
