import { FormLabel as FormLabelBase } from '@chakra-ui/react';

type FormLabelProps = {
  isOptional?: boolean;
  children: React.ReactNode;
};

function FormLabel(props: FormLabelProps) {
  const { children, ...rest } = props;

  return (
    <FormLabelBase
      fontSize="sm"
      fontWeight={400}
      color="secondaryDarkGray.600"
      lineHeight="16px"
      mb={0}
      {...rest}
    >
      {children}
    </FormLabelBase>
  );
}

export default FormLabel;
