import { Box, Button, HStack, Text } from '@chakra-ui/react';

type Step<T> = {
  label: string;
  name: T;
};

type StepsProgressBarProps<T> = {
  activeStep: string;
  onStepClick: (step: T) => void;
  steps: Step<T>[];
};

const StepsProgressBar = function <T>({
  activeStep,
  steps,
  onStepClick,
}: StepsProgressBarProps<T>) {
  return (
    <Box>
      <HStack mb="37px" spacing={0} zIndex={2}>
        {steps.map(({ label, name }, index) => (
          <HStack key={index} position="relative" spacing={0}>
            <Button
              bg={activeStep === name ? '#FFFFFF' : 'transparent'}
              minW={0}
              padding={0}
              border="2px solid #FFFFFF"
              borderRadius="50%"
              w="18px"
              h="18px"
              onClick={() => onStepClick(name)}
            >
              <Box />
            </Button>
            {index < steps.length - 1 ? <Box bg="#FFFFFF" h="2px" top="9px" w="148px" /> : null}
            <Text
              color="#FFFFFF"
              cursor="pointer"
              fontSize="15px"
              fontWeight={700}
              left={`-${(6 * label.length) / 2}px`}
              opacity={name ? 1 : 0.6}
              onClick={() => onStepClick(name)}
              position="absolute"
              top="26px"
            >
              {label}
            </Text>
          </HStack>
        ))}
      </HStack>
    </Box>
  );
};

export default StepsProgressBar;
