import { Icon } from '@chakra-ui/react';

const IconBalmain = () => (
  <Icon
    width={{ base: '99px', md: '150px' }}
    height={{ base: '32px', md: '48px' }}
    viewBox="0 0 150 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="48" fill="white" />
    <g clipPath="url(#clip0_205_1524)">
      <path
        d="M34.4507 18.3197C35.165 17.7227 35.6666 16.7912 35.6666 15.6912C35.6675 13.6035 34.1422 12.2243 31.3043 12.2243H25.8594V26.055H32.5784C35.3778 26.055 37.1347 24.2465 37.1347 21.8427C37.1347 20.0904 36.1503 18.8605 34.4515 18.3197H34.4507ZM28.5622 17.6489V14.6851H31.13C32.4819 14.6851 33.1185 15.2259 33.1185 16.1574C33.1185 17.0889 32.481 17.6489 31.13 17.6489H28.5622ZM34.3926 21.8243C34.3926 22.9612 33.6397 23.5951 32.1913 23.5951H28.5622V20.0351H32.1922C33.6406 20.0351 34.3934 20.6874 34.3934 21.8243M50.2823 26.0558H53.256L47.7913 12.2243H45.2039L39.6239 26.055H42.578L43.6017 23.5011H49.2782L50.2823 26.055V26.0558ZM44.5869 21.0789L46.46 16.4005L48.3135 21.0789H44.5869ZM59.6161 12.1874H56.9133V26.055H64.8868V23.5196H59.6161V12.1874ZM75.0247 22.8866H76.8397L80.3535 16.5305V26.1296H83.2879V12.1497H79.4648L75.9126 18.5435L72.245 12.1497H68.5578V26.1296H71.4153V16.6236L75.0256 22.8866H75.0247ZM97.1755 26.055H100.148L94.6845 12.2243H92.0979L86.5179 26.055H89.472L90.4957 23.5011H96.1722L97.1763 26.055H97.1755ZM91.4801 21.0781L93.3524 16.3997L95.2058 21.0781H91.4801ZM103.458 26.055H106.238V12.2243H103.458V26.055ZM121.156 12.2243V21.2089L113.878 12.2243H111.02V26.055H113.723V16.3812L121.543 26.055H123.859V12.2243H121.157H121.156Z"
        fill="black"
      />
      <path
        d="M59.8083 37.5415H58.3608V40.0568H56.9414V33.0945H59.8083C61.2653 33.0945 62.1078 33.9204 62.1078 35.3407C62.1078 36.761 61.2849 37.5407 59.8083 37.5407V37.5415ZM59.7135 36.2604C60.3005 36.2604 60.6406 35.9259 60.6406 35.3415C60.6406 34.7571 60.2996 34.4134 59.7135 34.4134H58.3608V36.2604H59.7135Z"
        fill="black"
      />
      <path
        d="M68.6593 38.9249H65.9155L65.4711 40.0576H63.9492L66.7016 33.0953H67.9501L70.5897 40.0576H69.0951L68.6602 38.9249H68.6593ZM68.1859 37.6714L67.3058 35.3599L66.4068 37.6714H68.1859Z"
        fill="black"
      />
      <path
        d="M77.6074 37.4024L78.9892 40.0568H77.3716L76.0753 37.5415H75.2045V40.0568H73.7852V33.0945H76.6521C78.109 33.0945 78.9507 33.9204 78.9507 35.3407C78.9507 36.408 78.4773 37.1232 77.6074 37.4015V37.4024ZM76.5572 36.2604C77.1434 36.2604 77.4844 35.9259 77.4844 35.3415C77.4844 34.7571 77.1434 34.4134 76.5572 34.4134H75.2045V36.2604H76.5572Z"
        fill="black"
      />
      <path d="M82.3789 33.0945H83.7983V40.0568H82.3789V33.0945Z" fill="black" />
      <path
        d="M89.7249 35.805L91.1161 36.1949C92.2133 36.5017 92.8097 37.1322 92.8097 38.0981C92.8097 39.3792 91.8065 40.1497 90.1513 40.1497C88.3346 40.1497 87.4357 39.3792 87.2656 37.6713H88.8268C88.9875 38.5533 89.3378 38.8686 90.2086 38.8686C90.9084 38.8686 91.3058 38.6087 91.3058 38.1543C91.3058 37.7644 91.0033 37.5598 90.4547 37.4022L89.0541 36.9939C87.9091 36.6593 87.3981 36.0934 87.3981 35.063C87.3981 33.8473 88.4671 33.0021 90.0189 33.0021C91.7219 33.0021 92.8003 33.9764 92.7815 35.499H91.2297C91.2485 34.71 90.7948 34.2832 89.9334 34.2832C89.243 34.2832 88.8174 34.5339 88.8174 34.9984C88.8174 35.416 89.1199 35.639 89.7258 35.8058L89.7249 35.805Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_205_1524">
        <rect width="98" height="28" fill="white" transform="translate(25.8594 12.1497)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconBalmain;
