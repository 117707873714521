import { useHistory, useLocation } from 'react-router-dom';

import AppContainer from '@/layouts/AppContainer';

import { useEffect, useRef, useState } from 'react';
import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import {
  Box,
  Button as ChakraButton,
  Center,
  Hide,
  Image,
  Show,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import Button from '@/components/button';
import IconShare from '@/components/icons/IconShare';
import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import { getBillableActions } from '@/api/subscription';
import { BillableActionType } from '@/components/types';

import DownloadDesignForm from './components/DownloadDesignForm';
import IconBack from '@/lib/components/icons/IconBack';
import ButtonBack from '@/components/navbar/ButtonBack';
import IconCopy from './icons/IconCopy';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const THUMBNAIL_SIZE = 340;

const RESOLUTION_MULTIPLIERS = [1, 2, 3, 4];

const getShareLink = (designId) => `${window.location.origin}/designs/${designId}`;

const getShareContent = (designId) => ({
  title: 'Check out this awesome design I created on Ablo.ai',
  text: 'You can design on top of my design on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
  url: getShareLink(designId),
});

export default function RemixSubmitted() {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const [selectedResolution, setSelectedResolution] = useState(RESOLUTION_MULTIPLIERS[0]);
  const [creditsForUpscaling, setCreditsForUpscaling] = useState<number>(null);

  const [selectedSide, setSelectedSide] = useState(null);

  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);

  const imageRef = useRef(null);

  const { search } = useLocation();

  const history = useHistory();

  const toast = useToast();

  const searchParams = new URLSearchParams(search);

  const designId = searchParams.get('designId');

  const canShare = navigator.share && navigator.canShare(getShareContent(designId));

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getBillableActions().then((billableActions) => {
      const upscaleAction = billableActions.find(
        ({ name }) => name === BillableActionType.IMAGE_UPSCALING
      );

      setCreditsForUpscaling(upscaleAction.credits);
    });
  }, []);

  const shareLink = getShareLink(designId);

  const handleImageLoad = () => {
    const height = imageRef.current.naturalHeight;
    const width = imageRef.current.naturalWidth;

    setSelectedResolution(RESOLUTION_MULTIPLIERS[0]);

    setImageHeight(height);
    setImageWidth(width);
  };

  const { sides = [], name, description, user } = design || {};

  const designSide = sides.find(({ id }) => id === selectedSide) || sides[0];

  const { designImage, previewImage } = designSide || {};

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);

      toast({
        title: 'Link copied to clipboard',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error copying link to clipboard',
        description: error.message,
        status: 'success',
      });
    }
  };

  const handleShare = async () => {
    const blob = await (await fetch(designImage)).blob();

    const file = new File([blob], `${name}.png`, { type: blob.type });

    try {
      await navigator.share({
        title: 'Check out this Design I created on ablo.ai',
        text: '#ablo #ai #aiimagegeneration #design #fashion',
        files: [file],
        url: shareLink,
      });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error.message,
        status: 'error',
      });
    }
  };

  return (
    <AppContainer
      contentContainerProps={{ p: 0 }}
      rightSideNavbarContent={
        <Show above="md">
          <ButtonBack onClick={() => history.push(`/designs/${design.id}`)} />
        </Show>
      }
    >
      {isLoading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Stack h="100%" direction={{ base: 'column', md: 'row' }}>
          <Box h="100%" bg="#FFFFFF" overflow="auto">
            <Hide above="md">
              <Box pt="12px">
                <ChakraButton onClick={() => history.push(`/designs/${design.id}`)} variant="ghost">
                  <IconBack />
                </ChakraButton>
              </Box>
            </Hide>
            <VStack
              align="flex-start"
              h="100%"
              p="12px 26px 26px 26px"
              w={{ base: '100%', md: '393px' }}
            >
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                fontSize="26px"
                fontWeight={700}
                mb="20px"
              >
                Congrats! Your work is submitted!
              </Text>
              <Image
                objectFit="contain"
                ref={imageRef}
                src={designImage}
                alt="Remix image"
                borderRadius="14px"
                h={THUMBNAIL_SIZE}
                w={THUMBNAIL_SIZE}
                onLoad={handleImageLoad}
              />
              <Text fontSize={{ base: '24px', md: '20px' }} fontWeight={700}>
                {name}
              </Text>
              <CreatorAvatar user={user} />
              <Text fontSize="14px" fontWeight={500} mt="4px" maxW={{ base: 'none', md: '416px' }}>
                {description || 'Description. A piece of work judged over time to be definitive.'}
              </Text>
              <VStack mt="20px" pb={{ base: '100px', md: '26px' }} w="100%">
                {canShare ? (
                  <Hide above="lg">
                    <Button secondary w="100%" height="50px" mt="20px" onClick={handleShare}>
                      <IconShare color="#000000" mr="8px" />
                      Share
                    </Button>
                  </Hide>
                ) : null}
                <Show above="lg">
                  <Button onClick={handleCopyLink} outlined w="100%" height="50px">
                    <IconCopy />
                    Copy Link
                  </Button>
                </Show>
                <DownloadDesignForm
                  originalImageHeight={imageHeight}
                  originalImageWidth={imageWidth}
                  selectedResolution={selectedResolution}
                  onChangeSelectedResolution={setSelectedResolution}
                  design={design}
                  creditsForUpscaling={creditsForUpscaling}
                  resolutionMultipliers={RESOLUTION_MULTIPLIERS}
                  selectedSide={selectedSide}
                  onSelectedSide={setSelectedSide}
                />
              </VStack>
            </VStack>
          </Box>
          <Show above="md">
            <Center flex={1}>
              <Image width={535} h={514} objectFit="contain" src={previewImage} />
            </Center>
          </Show>
        </Stack>
      )}
    </AppContainer>
  );
}
