import { Icon } from '@chakra-ui/react';

export const IconAi = () => (
  <Icon
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47963 12.1268L9.02557 7.48901H9.97425L11.5202 12.1268L16.158 13.6728V14.6215L11.5202 16.1674L9.97425 20.8052H9.02557L7.47963 16.1674L2.8418 14.6215V13.6728L7.47963 12.1268Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2296 5.87684L17.0256 3.48901H17.9743L18.7702 5.87684L21.158 6.67279V7.62147L18.7702 8.41741L17.9743 10.8052H17.0256L16.2296 8.41741L13.8418 7.62147V6.67279L16.2296 5.87684Z"
      fill="white"
    />
  </Icon>
);
