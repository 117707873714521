import { Center, Text, HStack, ButtonProps, TextProps } from '@chakra-ui/react';

import IconSubtract from './icons/IconSubtract';
import IconAdd from './icons/IconAdd';

const BUTTON_SIZE = 34;

interface UpdateQuantityControlProps {
  onSetQuantity: (quantity: number) => void;
  quantity: number;
  buttonProps?: ButtonProps;
  textProps?: TextProps;
  spacing?: number;
}

const Button = (props) => (
  <Center
    as="button"
    borderRadius="61px"
    border="1px solid"
    borderColor="borderSecondary"
    width={`${BUTTON_SIZE}px`}
    height={`${BUTTON_SIZE}px`}
    padding="12px"
    {...props}
  />
);

const UpdateQuantityControl = ({
  quantity = 0,
  onSetQuantity,
  buttonProps = {},
  textProps = {},
  spacing,
}: UpdateQuantityControlProps) => {
  const isDisabled = quantity === 0;

  return (
    <HStack spacing={`${spacing || 19}px`}>
      <Button
        disabled={isDisabled}
        onClick={() => onSetQuantity(Math.max(quantity - 1, 0))}
        {...buttonProps}
      >
        <IconSubtract />
      </Button>
      <Text {...textProps} color={isDisabled ? 'borderSecondary' : 'black.500'}>
        {quantity}
      </Text>
      <Button onClick={() => onSetQuantity(Math.max(quantity + 1))} {...buttonProps}>
        <IconAdd />
      </Button>
    </HStack>
  );
};

export default UpdateQuantityControl;
