import { Modal, ModalOverlay, ModalContent, ModalBody, Button } from '@chakra-ui/react';
import RemixDetails from './RemixDetails';
import IconCloseModal from '@/components/icons/IconCloseModal';
import { DesignVote } from '@/components/types';

type Props = {
  onClose?: () => void;
  designId: string;
  onVote: (designId: string) => void;
  isVoting: boolean;
  hasVotingEnded: boolean;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign: string;
};

export default function RemixDetailsModal({
  designId,
  designVotes,
  isVoting,
  hasVotingEnded,
  onVote,
  onClose,
  votedForDesign,
}: Props) {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        borderRadius={{ base: '14px', md: '24px' }}
        h="553px"
        maxW="none"
        p={0}
        w={{ base: '343px', md: '1015px' }}
      >
        <ModalBody padding={{ base: 0, md: '12px' }}>
          <Button
            variant="ghost"
            minWidth={0}
            height="auto"
            onClick={onClose}
            padding="2px"
            position="absolute"
            right={{ base: '14px', md: '18px' }}
            top={{ base: '16px', md: '18px' }}
          >
            <IconCloseModal />
          </Button>
          <RemixDetails
            designVotes={designVotes}
            designId={designId}
            hasVotingEnded={hasVotingEnded}
            isVoting={isVoting}
            onVote={onVote}
            votedForDesign={votedForDesign}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
