import { Icon } from '@chakra-ui/react';

const IconCloud = () => (
  <Icon
    width="33px"
    height="33px"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1027_8056)">
      <path
        d="M26.2212 13.9079C25.3219 9.34532 21.3148 5.92004 16.5009 5.92004C12.6788 5.92004 9.35936 8.08894 7.70623 11.2629C3.72551 11.6861 0.630859 15.0585 0.630859 19.145C0.630859 23.5225 4.18838 27.08 8.56586 27.08H25.7584C29.4085 27.08 32.3709 24.1176 32.3709 20.4675C32.3709 16.9761 29.6597 14.146 26.2212 13.9079ZM25.7584 24.435H8.56586C5.64313 24.435 3.27586 22.0678 3.27586 19.145C3.27586 16.4339 5.29928 14.1724 7.98396 13.8947L9.39903 13.7492L10.0603 12.4929C11.3167 10.0727 13.7765 8.56504 16.5009 8.56504C19.9658 8.56504 22.9547 11.0249 23.6291 14.4237L24.0259 16.4075L26.0493 16.5529C28.1124 16.6852 29.7259 18.4177 29.7259 20.4675C29.7259 22.6497 27.9405 24.435 25.7584 24.435ZM13.8559 19.3831L11.0918 16.6191L9.22711 18.4838L13.8559 23.1125L21.8041 15.1643L19.9394 13.2996L13.8559 19.3831Z"
        fill="#064AC4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1027_8056">
        <rect width="31.74" height="31.74" fill="white" transform="translate(0.630859 0.630005)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconCloud;
