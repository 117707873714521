import { Center, Flex, Modal, ModalOverlay, ModalContent, ModalBody, Text } from '@chakra-ui/react';

import Button from '../../components/buttons/ButtonCTA';

type Props = {
  onClose: () => void;
};

const CustomInquirySentModal = ({ onClose }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="772px" maxHeight="426px">
        <ModalBody padding={0}>
          <Center
            bg="linear-gradient(105deg, #CEEDFF 16.17%, rgba(255, 255, 255, 0.00) 87.53%)"
            borderTopLeftRadius={20}
            h="203px"
          >
            <Text as="b" color="black.600" fontSize="32px">
              Thank you!
            </Text>
          </Center>
          <Flex align="center" direction="column" padding="16px 22px 69px 22px">
            <Text color="black.700" fontSize="sm" fontWeight={700} mb="18px">
              Someone from our team will reach out to you shortly
            </Text>
            <Button onClick={onClose} w="135px">
              Take me home
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomInquirySentModal;
