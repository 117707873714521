import {
  Center,
  HStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  useToast,
} from '@chakra-ui/react';
import Button from '../../subscription/components/buttons/ButtonCTA';
import { useState } from 'react';
import { useDeleteSelfUser } from '../../../../api/users';
import { handleLogout } from '../../../../analytics';
import { StorageKeys } from '../../../../constants';

const ButtonProps = {
  fontSize: 'xs',
  w: '135px',
};

type Props = {
  onClose: () => void;
};

export const DeleteConfirmationModal = ({ onClose }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { removeUser } = useDeleteSelfUser();
  const toast = useToast();

  const onDelete = () => {
    setIsDeleting(true);

    // Delete the account
    removeUser(null, {
      onSuccess: () => {
        toast({
          title: 'Successfully deleted your account and data',
          status: 'success',
        });

        localStorage.removeItem(StorageKeys.ACCESS_TOKEN);

        window.location.href = '/';

        handleLogout();
      },
      onError: () => {
        toast({
          title: 'Error deleting account',
          status: 'error',
        });
      },
    });

    setIsDeleting(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxW={500}>
        <ModalBody padding={0}>
          <Center
            bg="linear-gradient(105deg, #CEEDFF 16.17%, rgba(255, 255, 255, 0.00) 87.53%)"
            borderTopLeftRadius={20}
            p={8}
          >
            <Text as="b" color="black.600" fontSize="32px">
              Delete my account
            </Text>
          </Center>
          <Flex align="center" direction="column" padding="32px 16px">
            <Text color="black.700" fontWeight={700} mb="16px">
              Are you sure?
            </Text>
            <Text color="black.700" mb="38px">
              This is permanent and will delete all your information and data
            </Text>
            <HStack spacing="10px">
              <Button outlined onClick={onClose} {...ButtonProps}>
                Cancel
              </Button>
              <Button
                onClick={onDelete}
                {...{ ...ButtonProps, bg: '#CE0000' }}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </HStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
