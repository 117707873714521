import { Icon } from '@chakra-ui/react';

const IconVoteHover = (props) => (
  <Icon
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5426 2.35937L22.8059 13.4094L17.3945 13.4094L17.3945 22.2175L7.87094 22.2175L7.87094 13.4094L2.2793 13.4094L12.5426 2.35937ZM6.40777 11.6094L9.67094 11.6094L9.67094 20.4175L15.5945 20.4175L15.5945 11.6094H18.6774L12.5426 5.00433L6.40777 11.6094Z"
      fill="white"
    />
  </Icon>
);

export default IconVoteHover;
