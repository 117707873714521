import { Button, Center, Text, useBreakpointValue } from '@chakra-ui/react';

import IconCart from '@/components/icons/IconCart';

import { useHistory, useLocation } from 'react-router-dom';

const CartIndicator = ({ cartItemsCount }: { cartItemsCount: number }) => {
  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const isCartDrawerVisible = searchParams.get('showCart');

  const handleGoToCart = (e) => {
    e.preventDefault();

    if (isMobile) {
      history.push('/cart');

      return;
    }

    history.replace(isCartDrawerVisible ? `?showCart` : '?showCart=true');
  };

  return (
    <Button bg="transparent" onClick={handleGoToCart} position="relative" padding="0">
      <IconCart
        color="#212529"
        height={{ base: '28px', md: '32px' }}
        width={{ base: '28px', md: '32px' }}
      />
      <Center
        bg="brand.600"
        borderRadius={94}
        width={{ base: '18px', md: '21px' }}
        height={{ base: '18px', md: '21px' }}
        padding="5px 8px"
        position="absolute"
        right={{ base: 0, md: '-4px' }}
        top={{ base: '4px', md: '2px' }}
      >
        <Text as="b" color="#FFFFFF" fontSize="9px">
          {cartItemsCount}
        </Text>
      </Center>
    </Button>
  );
};

export default CartIndicator;
