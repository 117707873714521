import { Flex, HStack, IconButton, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import IconSuccess from '@/components/icons/IconSuccess';

import IconClose from './IconCloseBanner';

type CustomStylePurchasedBannerProps = {
  onClose: () => void;
};

const CustomStylePurchasedBanner = ({ onClose }: CustomStylePurchasedBannerProps) => {
  const history = useHistory();

  const handleGoToCustomArtStyles = () => history.push('/custom-art-styles');

  return (
    <Flex
      padding="10px 16px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="9px"
      bg="#ECFBED"
      mb="20px"
      w="100%"
    >
      <HStack spacing="5px">
        <IconSuccess />
        <Text color="black.600" fontSize="md" fontWeight={700}>
          Thank you for purchasing custom AI style! We have billed to saved payment method.
        </Text>
      </HStack>
      <HStack spacing="5px">
        <IconSuccess />
        <Text
          as="button"
          color="brand.600"
          fontSize="md"
          fontWeight={700}
          onClick={handleGoToCustomArtStyles}
        >
          Train the AI with custom Art style
        </Text>
        <IconButton
          aria-label="Close banner"
          bg="transparent"
          icon={<IconClose />}
          onClick={onClose}
        />
      </HStack>
    </Flex>
  );
};

export default CustomStylePurchasedBanner;
