import { Box, Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';

import IconCheck from './icons/IconCheck';
import { getFormattedAmount } from '../../utils';
import { ReactNode } from 'react';

type PlanCardProps = {
  dark?: boolean;
  name: string;
  subtitle?: string;
  amount?: number;
  interval?: string;
  currency?: string;
  features?: string[];
  children?: ReactNode;
  button: ReactNode;
  onClick: () => void;
};

const PlanCard = ({
  children,
  dark,
  name,
  subtitle,
  amount,
  interval,
  currency,
  features,
  button,
  onClick,
}: PlanCardProps) => {
  return (
    <Card
      h="453px"
      w="280px"
      bgColor={dark ? 'brand.600' : '#FFFFFF'}
      borderRadius={10}
      boxShadow="-3.83442px 3.83442px 28.75811px 0px rgba(0, 0, 0, 0.04)"
      cursor="pointer"
      onClick={onClick}
    >
      <CardBody
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        p="33px 30px 20px 30px"
        position="relative"
      >
        {dark ? (
          <Box
            bg="#FE1585"
            borderRadius="0px 9.026px"
            position="absolute"
            right={0}
            textAlign="center"
            top={0}
            w="105px"
          >
            <Text as="b" color="#FFFFFF" fontSize="11px">
              Best Plan
            </Text>
          </Box>
        ) : null}
        <VStack align="flex-start">
          <Text as="b" color={dark ? '#FFFFFF' : 'black.600'}>
            {name}
          </Text>
          <HStack align="flex-end" spacing="5px">
            <Text
              color={dark ? '#FFFFFF' : 'black.600'}
              fontSize="38px"
              fontWeight={700}
              lineHeight="46px"
            >
              {subtitle || getFormattedAmount(currency, amount)}
            </Text>
            {interval ? (
              <Text
                as="b"
                color={dark ? '#FFFFFF' : 'gray.500'}
                fontSize="11px"
                position="relative"
                bottom="6px"
              >
                /{interval}
              </Text>
            ) : null}
          </HStack>
          {features ? (
            <VStack align="flex-start" mt="67px" spacing="20px">
              {features.map((feature, index) => (
                <HStack spacing="12px" key={index}>
                  <IconCheck color={dark && '#FFFFFF'} />
                  <Text as="b" color={dark ? '#FFFFFF' : 'black.300'} fontSize="sm">
                    {feature}
                  </Text>
                </HStack>
              ))}
            </VStack>
          ) : null}
        </VStack>

        {children}
        {button}
      </CardBody>
    </Card>
  );
};

export default PlanCard;
