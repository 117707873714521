import { Card, CardBody, Text, VStack } from '@chakra-ui/react';

import { ReactNode } from 'react';

type FormCardProps = {
  title: string;
  description: string;
  children: ReactNode;
};

export default function FormCard({ title, description, children }: FormCardProps) {
  return (
    <Card bg="#FFFFFF" borderRadius="20px" w="100%">
      <CardBody p="50px 66px">
        <VStack align="flex-start" mb="35px">
          <Text color="black.700" fontSize="26px" fontWeight={700}>
            {title}
          </Text>
          <Text color="secondaryGray.600" fontSize="md">
            {description}
          </Text>
        </VStack>
        {children}
      </CardBody>
    </Card>
  );
}
