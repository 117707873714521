import { Icon } from '@chakra-ui/react';

const IconMerchShop = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.36 17.59H10V16.09H13.86V6.79002H17.3V4.40002H10V2.90002H18.8V8.29002H15.36V17.59Z"
      fill="black"
    />
    <path
      d="M9.99995 17.59H4.63995V8.29002H1.19995V2.90002H9.99995V4.40002H2.69995V6.79002H6.13995V16.09H9.99995V17.59Z"
      fill="black"
    />
    <path
      d="M10 7.26001C8.34 7.26001 7 5.91001 7 4.26001V3.26001H13.01V4.26001C13.01 5.92001 11.66 7.26001 10.01 7.26001H10ZM8 4.26001C8 5.36001 8.9 6.26001 10 6.26001C11.1 6.26001 12 5.36001 12 4.26001H8Z"
      fill="black"
    />
  </Icon>
);

export default IconMerchShop;
