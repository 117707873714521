import { Icon } from '@chakra-ui/react';

const IconTrash = (props) => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6798 17.7596H4.31982V6.84961H5.81982V16.2596H14.1798V6.84961H15.6798V17.7596Z"
      fill="#626F82"
    />
    <path
      d="M17.4201 5.2998H11.5901V3.7998H8.41008V5.2998H2.58008V3.7998H6.91008V2.2998H13.0901V3.7998H17.4201V5.2998Z"
      fill="#626F82"
    />
    <path d="M8.91992 6.91016H7.91992V14.5902H8.91992V6.91016Z" fill="#626F82" />
    <path d="M12.1401 6.91016H11.1401V14.5902H12.1401V6.91016Z" fill="#626F82" />
  </Icon>
);

export default IconTrash;
