import { Icon } from '@chakra-ui/react';

const IconTemplate = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.212728 14.9995H10.909L10.909 2.90527H0.212646L0.212728 14.9995ZM0.944234 3.7324H10.1776V12.2275H0.944234V3.7324Z"
      fill="black"
    />
    <path
      d="M5.1565 0L4.8501 2.5835H5.58608L5.78608 0.898L14.9629 1.85454L13.9614 10.2977L11.2177 10.0116V12.8006L14.3617 13.1285L15.7873 1.10825L5.1565 0Z"
      fill="black"
    />
  </Icon>
);

export default IconTemplate;
