import CollectionGuest from '@/views/collection/CollectionGuest';
import CollectionListGuest from '@/views/collection/CollectionListGuest';
import DesignPreviewPage from '@/views/demo/pages/design/DesignPreviewPage';
import HomeGuest from '@/views/home/HomeGuest';
import ProductDetailsGuest from '@/views/product/ProductDetailsGuest';

const PUBLIC_ROUTES = [
  {
    path: '/designs/:id',
    component: DesignPreviewPage,
  },
  {
    path: '/',
    exact: true,
    component: HomeGuest,
  },
  {
    path: '/collections',
    exact: true,
    component: CollectionListGuest,
  },
  {
    path: '/collection/:id',
    exact: true,
    component: CollectionGuest,
  },
  {
    path: '/products/:id',
    exact: true,
    component: ProductDetailsGuest,
  },
];

export default PUBLIC_ROUTES;
