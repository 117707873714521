import {
  ShowMore as ShowMoreBase,
  type ShowMoreRef,
  type ShowMoreToggleLinesFn,
} from '@re-dev/react-truncate';
import { ReactNode, useRef } from 'react';
import ShowMoreButton from './ShowMoreButton';

interface ShowMoreProps {
  children: ReactNode;
}

const ShowMore = ({ children }: ShowMoreProps) => {
  const ref = useRef<ShowMoreRef>(null);

  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    ref.current?.toggleLines(e);
  };

  return (
    <ShowMoreBase
      ref={ref}
      lines={3}
      more={<ShowMoreButton isCollapsed onToggleLines={toggleLines} />}
      less={<ShowMoreButton onToggleLines={toggleLines} />}
    >
      {children}
    </ShowMoreBase>
  );
};

export default ShowMore;
