import { Icon } from '@chakra-ui/react';

const IconFontToImage = () => (
  <Icon
    width="20px"
    height="20px"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_16897_2696)">
      <path
        d="M10.8429 21.4294H4.7109L3.6609 24.4254H1.1409L6.3909 9.75342H9.1769L14.4269 24.4254H11.8929L10.8429 21.4294ZM10.1709 19.4694L7.7909 12.6654L5.3829 19.4694H10.1709Z"
        fill="black"
      />
      <path
        d="M14.5109 15.4654C15.0009 14.5694 15.6589 13.8694 16.4989 13.3654C17.3389 12.8614 18.2629 12.6094 19.2709 12.6094C20.1809 12.6094 20.9789 12.7914 21.6649 13.1414C22.3509 13.5054 22.8969 13.9534 23.3029 14.4854V12.8054H25.7249V24.4114H23.3029V22.6894C22.8969 23.2354 22.3369 23.6974 21.6369 24.0614C20.9369 24.4254 20.1389 24.6074 19.2289 24.6074C18.2349 24.6074 17.3109 24.3554 16.4849 23.8374C15.6589 23.3194 15.0009 22.6054 14.5109 21.6814C14.0209 20.7574 13.7829 19.7214 13.7829 18.5454C13.7829 17.3694 14.0209 16.3474 14.5109 15.4514V15.4654ZM22.7989 16.5154C22.4629 15.9274 22.0429 15.4794 21.4969 15.1714C20.9649 14.8634 20.3909 14.7094 19.7749 14.7094C19.1589 14.7094 18.5849 14.8634 18.0529 15.1574C17.5209 15.4654 17.0869 15.8994 16.7509 16.4874C16.4149 17.0754 16.2609 17.7614 16.2609 18.5594C16.2609 19.3574 16.4289 20.0574 16.7509 20.6594C17.0869 21.2614 17.5209 21.7234 18.0529 22.0454C18.5989 22.3674 19.1729 22.5214 19.7749 22.5214C20.3769 22.5214 20.9649 22.3674 21.4969 22.0594C22.0289 21.7514 22.4629 21.3034 22.7989 20.7014C23.1349 20.0994 23.2889 19.3994 23.2889 18.6014C23.2889 17.8034 23.1209 17.1034 22.7989 16.5154Z"
        fill="black"
      />
      <path
        d="M25.417 5.25012L28.665 4.13012L25.403 3.02412L24.283 -0.223877L23.177 3.03812L19.929 4.17212L23.191 5.26412L24.325 8.52612L25.417 5.25012Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_16897_2696">
        <rect width="28" height="28" fill="white" transform="translate(0.875)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconFontToImage;
