import { Icon } from '@chakra-ui/react';

type Props = {
  color?: string;
};

const IconCheck = ({ color }: Props) => (
  <Icon
    width="16px"
    height="15px"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1324_1507)">
      <path
        d="M12.9044 3.82983L6.314 10.4202L3.31836 7.4246"
        stroke={color || '#156CFE'}
        strokeWidth="1.91721"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9044 4.06958L6.314 10.66L3.31836 7.66434"
        stroke={color || '#156CFE'}
        strokeWidth="1.91721"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1324_1507">
        <rect
          width="14.3791"
          height="14.3791"
          fill="white"
          transform="translate(0.921875 0.235107)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default IconCheck;
