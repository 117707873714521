import { Brand } from '@/components/types';
import { Box, BoxProps, HStack, Image } from '@chakra-ui/react';

interface BrandsProps extends BoxProps {
  brands: Brand[];
  isDark?: boolean;
}

const Brands = ({ brands, isDark = false, ...rest }: BrandsProps) =>
  brands?.length > 0 ? (
    <HStack {...rest}>
      {brands.map(({ id, logoUrl }, index) => (
        <>
          {index !== 0 ? (
            <Box bg={isDark ? '#000000' : '#FFFFFF'} h="100%" mx="8px" w="1px" />
          ) : null}
          <Image key={id} src={logoUrl} height={{ base: '100%', md: '100%' }} />
        </>
      ))}
    </HStack>
  ) : null;

export default Brands;
