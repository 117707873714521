async function toDataURL(url) {
  const blob = await fetch(url).then((res) => res.blob());
  return URL.createObjectURL(blob);
}

export const downloadFromUrl = async (url: string, name?: string) => {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = name || 'image.png';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
