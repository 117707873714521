import { Button as ChakraButton } from '@chakra-ui/react';
import { trackEvent } from '../../../../../analytics';

const Button = ({ outlined = false, onClick, children, ...rest }) => {
  const handleClick = (e) => {
    // Track the button click
    trackEvent(`Button Clicked: ${children}`, { text: children });

    // If there is an onClick handler passed, call it
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ChakraButton
      bg={outlined ? '#FFFFFF' : 'brand.600'}
      border={outlined ? '1px solid' : 'none'}
      borderColor="brand.600"
      borderRadius="16px"
      color={outlined ? 'brand.600' : '#FFFFFF'}
      fontSize="sm"
      h="36px"
      p="6px"
      onClick={handleClick}
      children={children}
      {...rest}
    />
  );
};

export default Button;
