import { Box, HStack, Image, Text } from '@chakra-ui/react';

import type { Stripe } from 'stripe';

import Link from './images/Link.png';

type LinkDetailsProps = {
  link: Stripe.PaymentMethod.Link;
};

const LinkDetails = ({ link }: LinkDetailsProps) => {
  const { email } = link;

  return (
    <HStack spacing="9px">
      <Image src={Link} h="41px" w="58px" />
      <Box fontSize="sm" fontWeight={700} mb="3px">
        <Text color="secondaryDarkGray.600">{email}</Text>
      </Box>
    </HStack>
  );
};

export default LinkDetails;
