import { useParams } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';

import { useState } from 'react';

import CollectionPage from './CollectionPage';

export default function CollectionGuest() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [addingDesignToCart, setAddingDesignToCart] = useState<string>(null);

  const { id } = useParams<{ id: string }>();

  const handleCancelSignIn = () => {
    setSignInModalVisible(false);
  };

  const handleSignedIn = () => {
    setSignInModalVisible(false);

    let destination = `/collection/${id}`;

    if (addingDesignToCart) {
      destination = `?designToAddToCart=${addingDesignToCart}`;
    } else if (votingForDesign) {
      destination = `?votingForDesign=${votingForDesign}`;
    }

    window.location.href = destination;
  };

  const handleSignInToVote = (designId: string) => {
    setVotingForDesign(designId);

    setSignInModalVisible(true);
  };

  const handleSignInToAddToCart = (designId: string) => {
    setAddingDesignToCart(designId);

    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={handleCancelSignIn}
      onSignedIn={handleSignedIn}
    >
      <CollectionPage
        onSignInToVote={handleSignInToVote}
        onSignInToAddToCart={handleSignInToAddToCart}
      />
    </GuestPageContainer>
  );
}
