import CreateTemplateStep from './CreateTemplateStep';

const STEPS = [
  {
    label: 'Template Information',
    name: CreateTemplateStep.GENERAL_INFORMATION,
  },
  {
    label: 'Template Sides',
    name: CreateTemplateStep.SIDES,
  },
  {
    label: 'Color Variants',
    name: CreateTemplateStep.COLOR_VARIANTS,
  },
  {
    label: 'Design Area',
    name: CreateTemplateStep.DESIGN_AREA,
  },
  {
    label: 'Design Elements',
    name: CreateTemplateStep.DESIGN_ELEMENTS,
  },
  {
    label: 'Product Images',
    name: CreateTemplateStep.PREVIEW_IMAGES,
  },
  {
    label: 'Preview',
    name: CreateTemplateStep.PREVIEW,
  },
];

export default STEPS;
