import { Icon } from '@chakra-ui/react';

const IconUp = () => (
  <Icon
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1179_19043)">
      <path
        d="M12 10.5L8 6.5L4 10.5"
        stroke="#6A6866"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1179_19043">
        <rect width="16" height="16" fill="white" transform="translate(16 16.5) rotate(-180)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconUp;
