import { Button as ChakraButton } from '@chakra-ui/react';

const BUTTON_SIZE = 40;

export const GraphicsButton = (props) => (
  <ChakraButton
    bg="#FFFFFF"
    h={`${BUTTON_SIZE}px`}
    flexShrink={0}
    padding="0"
    w={`${BUTTON_SIZE}px`}
    borderRadius="8px"
    minWidth="auto"
    {...props}
  />
);
