import { Icon } from '@chakra-ui/react';

const IconIncrease = () => (
  <Icon width="9px" height="10px" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.928711 5L7.35728 5" stroke="#646A73" strokeWidth="1.60714" strokeLinecap="round" />
    <path
      d="M4.14258 8.21411L4.14258 1.78554"
      stroke="#646A73"
      strokeWidth="1.60714"
      strokeLinecap="round"
    />
  </Icon>
);

export default IconIncrease;
