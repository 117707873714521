import { Icon } from '@chakra-ui/react';

const IconTemplateLibrary = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.36 17.5904H10V16.0904H13.86V6.79039H17.3V4.40039H10V2.90039H18.8V8.29039H15.36V17.5904Z"
      fill="black"
    />
    <path
      d="M10 17.5904H4.64001V8.29039H1.20001V2.90039H10V4.40039H2.70001V6.79039H6.14001V16.0904H10V17.5904Z"
      fill="black"
    />
    <path
      d="M10 7.25977C8.34 7.25977 7 5.90977 7 4.25977V3.25977H13.01V4.25977C13.01 5.91977 11.66 7.25977 10.01 7.25977H10ZM8 4.25977C8 5.35977 8.9 6.25977 10 6.25977C11.1 6.25977 12 5.35977 12 4.25977H8Z"
      fill="black"
    />
  </Icon>
);

export default IconTemplateLibrary;
