import { Icon } from '@chakra-ui/react';

const IconMagic = ({ color = null, ...rest }) => (
  <Icon
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.47098 4.48002L10.608 5.42731L7.47098 6.3746L6.52369 9.51167L5.5764 6.3746L2.43933 5.42731L5.5764 4.48002L6.52369 1.34296L7.47098 4.48002Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3543 6.60207L18.8125 2.69281L17.2707 6.60207L13.3614 8.14386L17.2707 9.68565L18.8125 13.5949L20.3543 9.68565L24.2635 8.14386L20.3543 6.60207ZM20.9928 8.14386L19.4292 7.52717L18.8125 5.96354L18.1958 7.52717L16.6322 8.14386L18.1958 8.76055L18.8125 10.3242L19.4292 8.76055L20.9928 8.14386Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4536 13.2578L10.8981 6.7784L8.3427 13.2578L1.86328 15.8133L8.3427 18.3687L10.8981 24.8481L13.4536 18.3687L19.933 15.8133L13.4536 13.2578ZM15.0269 15.8133L12.0659 14.6455L10.8981 11.6845L9.73035 14.6455L6.76937 15.8133L9.73035 16.9811L10.8981 19.942L12.0659 16.9811L15.0269 15.8133Z"
      fill={color || 'white'}
    />
  </Icon>
);

export default IconMagic;
