import { useState } from 'react';

import { VStack } from '@chakra-ui/react';
import { isArray } from 'lodash';

import { useHistory, useLocation } from 'react-router-dom';

import { setPassword } from '@/api/auth';
import FormInput from '@/components/form/FormInput';
import { StorageKeys } from '@/constants';

import Button from '../components/ButtonCTA';
import AuthContainer from '../components/AuthContainer';
import ErrorMessage from '../components/ErrorMessage';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const history = useHistory();

  const { search } = useLocation();

  const [error, setError] = useState('');
  const [waiting, setWaiting] = useState(false);

  const searchParams = new URLSearchParams(search);

  const token = searchParams.get('token');

  const handleError = (error) => {
    const { response } = error;

    let message = response?.data?.message || 'Error resetting password';

    if (isArray(message)) {
      message = message.join(', ');
    }

    setError(message);
  };

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords have to match');

      return;
    }

    setWaiting(true);

    setPassword(newPassword, token)
      .then(({ access_token: accessToken }) => {
        setWaiting(false);

        localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken);

        history.push('/');
      })
      .catch((error) => {
        handleError(error);

        setWaiting(false);
      });
  };

  return (
    <AuthContainer title="Reset Password" subtitle="Set a new password">
      <VStack mb="20px" mt="20px" spacing="24px" w="350px" pt="32px">
        <FormInput
          isPassword
          name="Password"
          placeholder="Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <FormInput
          isPassword
          name="Confirm Password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error ? <ErrorMessage message={error} /> : null}
        <Button isLoading={waiting} onClick={handleSubmit} w="100%">
          Reset Password
        </Button>
      </VStack>
    </AuthContainer>
  );
}

export default ResetPassword;
