import { Icon } from '@chakra-ui/react';

export const IconDelete = () => (
  <Icon
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.0589" cy="10.6218" r="8.94115" fill="white" stroke="#064AC4" strokeWidth="2" />
    <path d="M14.0033 7.19971L6.56231 14.6116" stroke="#064AC4" strokeLinecap="round" />
    <path d="M13.8463 14.2269L6.94567 7.32632" stroke="#064AC4" strokeLinecap="round" />
  </Icon>
);
