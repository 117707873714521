import { Show } from '@chakra-ui/react';
import AppContainer from '@/layouts/AppContainer';
import ButtonBack from '@/components/navbar/ButtonBack';
import { useHistory } from 'react-router-dom';
import CollectionPage from './CollectionPage';
import { useMe } from '@/api/auth';

export default function HomeSignedIn() {
  const history = useHistory();

  const { data: me } = useMe();

  return (
    <AppContainer
      contentContainerProps={{ padding: 0 }}
      rightSideNavbarContent={
        <Show above="md">
          <ButtonBack onClick={() => history.goBack()} />
        </Show>
      }
    >
      <CollectionPage user={me} />
    </AppContainer>
  );
}
