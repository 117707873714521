import { Icon } from '@chakra-ui/react';

const IconCart = ({ color = null, ...rest }) => (
  <Icon
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09033 6.71008H19.7922L17.3627 16.3351H6.50654L4.09033 6.71008ZM6.20573 8.36008L7.79353 14.6851H16.0774L17.6739 8.36008H6.20573Z"
      fill={color || 'white'}
    />
    <path
      d="M15.95 20.3391C16.6183 20.3391 17.16 19.7973 17.16 19.1291C17.16 18.4608 16.6183 17.9191 15.95 17.9191C15.2817 17.9191 14.74 18.4608 14.74 19.1291C14.74 19.7973 15.2817 20.3391 15.95 20.3391Z"
      fill={color || 'white'}
    />
    <path
      d="M7.78788 20.2181C8.45615 20.2181 8.99788 19.6764 8.99788 19.0081C8.99788 18.3398 8.45615 17.7981 7.78788 17.7981C7.11962 17.7981 6.57788 18.3398 6.57788 19.0081C6.57788 19.6764 7.11962 20.2181 7.78788 20.2181Z"
      fill={color || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.30005H4.94615L5.94867 7.33617L4.34733 7.73393L3.65585 4.95005H0V3.30005Z"
      fill={color || 'white'}
    />
  </Icon>
);

export default IconCart;
