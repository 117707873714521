import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';

import { Design } from '@/lib';

import { getDesigns } from '@/api/designs';
import RemixCard from '@/components/remix/RemixCard';
import { DesignVote, Template, User } from '../types';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../ui/LoadingSpinner';
import Checkbox from '../ui/checkbox';
import { useDebouncedValue } from '@/hooks/useDebouncedValue';

interface RemixesListProps {
  template: Template;
  onVote: (designId: string) => void;
  onAddToCart?: (design: Design) => void;
  maxItems?: number;
  votingForDesign: string;
  designVotes: { [designId: string]: DesignVote[] };
  search?: string;
  votedForDesign: string;
  user?: User;
}

interface MyDesign extends Design {
  isMyDesign: true;
}

export default function RemixesList({
  onVote,
  search,
  maxItems,
  votingForDesign,
  template,
  designVotes,
  votedForDesign,
  user,
}: RemixesListProps) {
  const [designs, setDesigns] = useState<Design[]>([]);
  const [hasAllItems, setHasAllItems] = useState(false);

  const [isLoadingNextPage, setLoadingNextPage] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowingMyDesigns, setIsShowingMyDesigns] = useState(false);
  const [myDesigns, setMyDesigns] = useState<MyDesign[]>([]);

  const observer = useRef<IntersectionObserver>(null);

  const debouncedSearchTerm = useDebouncedValue(search, 500);

  const itemsPerPage = 8;

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    const loadMyDesigns = async () => {
      const myDesigns = await getDesigns({
        isPublished: true,
        templateId: template.id,
        userId: user.id,
        take: 1000 * 1000,
        skip: 0,
      });

      setMyDesigns(myDesigns.map((design) => ({ ...design, isMyDesign: true })));
    };

    loadMyDesigns();
  }, [user?.id]);

  useEffect(() => {
    if (!votedForDesign) {
      return;
    }

    setDesigns(
      designs.map((design) =>
        design.id !== votedForDesign ? design : { ...design, numVotes: design.numVotes + 1 }
      )
    );
  }, [votedForDesign]);

  useEffect(() => {
    setCurrentPage(1);

    setHasAllItems(false);

    setLoadingNextPage(true);

    getDesigns({
      isPublished: true,
      templateId: template.id,
      take: maxItems || itemsPerPage,
      skip: 0,
      search: debouncedSearchTerm,
    }).then((designs) => {
      setDesigns(designs);

      if (maxItems || designs.length < itemsPerPage) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [debouncedSearchTerm, setCurrentPage]);

  useEffect(() => {
    if (!template?.id) {
      return;
    }

    setLoadingNextPage(true);

    getDesigns({
      isPublished: true,
      templateId: template.id,
      take: maxItems || itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
    }).then((designs) => {
      setDesigns((oldDesigns) => (maxItems ? designs : [...oldDesigns, ...designs]));

      if (maxItems || designs.length < itemsPerPage) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [currentPage, maxItems, template?.id]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingNextPage || hasAllItems) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingNextPage, hasAllItems]
  );

  const handleShowMyDesigns = (isChecked) => {
    setIsShowingMyDesigns(isChecked);
  };

  return (
    <Box>
      {user && !maxItems ? (
        <HStack mb="32px" spacing="24px">
          <Text color="secondaryDarkGray.600" textStyle="bodySmall">
            All items
          </Text>
          <Checkbox
            isChecked={isShowingMyDesigns}
            onChange={(e) => handleShowMyDesigns(e.target.checked)}
          >
            My merch ({myDesigns.length})
          </Checkbox>
        </HStack>
      ) : null}
      <HStack align="center" justify="center" spacing="12px" wrap="wrap" w="100%">
        {[...designs, ...myDesigns].map((design, index) => {
          const { isMyDesign } = design as MyDesign;

          return (
            <Box
              key={design.id + index}
              display={
                (isMyDesign && isShowingMyDesigns) || (!isMyDesign && !isShowingMyDesigns)
                  ? 'block'
                  : 'none'
              }
              ref={index === designs.length - 1 ? lastElementRef : null}
              w={{ base: 'calc(50% - 6px)', '2sm': '184px', lg: '185px' }}
            >
              <RemixCard
                design={design}
                votingForDesign={votingForDesign}
                hasVoted={!isEmpty(designVotes)}
                onVote={isEmpty(designVotes) ? () => onVote(design.id) : null}
                height={{ base: 340, lg: 312 }}
              />
            </Box>
          );
        })}
      </HStack>
      {isLoadingNextPage ? <LoadingSpinner /> : null}
    </Box>
  );
}
