import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  borderColor: 'borderSecondary',
  borderRadius: '5px', // remove the border radius
  color: 'black.500',
  fontSize: 'sm',
  pl: '12px',
  resize: 'none',
  _placeholder: {
    color: 'secondaryDarkGray.600',
  },
});

export const textAreaStyles = defineStyleConfig({
  variants: { outline },
});
