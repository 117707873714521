import { ablo } from './ablo-wrapper';

export const removeBackgroundByUrl = async (imageUrl: string): Promise<string> => {
  const response = await ablo().removeBackground.byUrl(imageUrl);

  return response.imageUrl;
};

export const removeBackgroundByFile = async (imageFile: string): Promise<string> => {
  const response = await ablo().removeBackground.byFile(imageFile);

  return response.imageUrl;
};
