import {
  Button,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import IconInfo from './IconInfo';

import { differenceInMinutes } from 'date-fns';
import { useState } from 'react';
import { useMe } from '@/api/auth';

import CoinBlue from '@/assets/CoinBlue.png';

const CreditsPill = () => {
  const { data: me } = useMe();
  const [timeLabel, setTimeLabel] = useState('');

  const { isOpen, onToggle, onClose } = useDisclosure();

  const { client } = me;

  const { credits, subscriptionStatus } = client;

  const handleTogglePopover = () => {
    const midnight = new Date();
    midnight.setUTCHours(24, 1, 0, 0);

    const totalMinutes = differenceInMinutes(midnight, new Date());
    let hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0 && minutes === 0) {
      hours = 24;
    }

    const hourLabel = hours ? `${hours} hour${hours === 1 ? '' : 's'} ` : '';
    const timeLabel = minutes
      ? `${hourLabel}${minutes} minute${minutes === 1 ? '' : 's'}`
      : hourLabel;

    setTimeLabel(timeLabel);

    onToggle();
  };

  const isFreePlanUser = subscriptionStatus && ['active', 'in_house'].includes(subscriptionStatus);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <HStack spacing="6px">
      <HStack border="1px solid #CBD5E0" borderRadius="12px" padding="5px 10px" spacing="5px">
        <Image src={CoinBlue} width={30} />
        <Text color="brand.600" fontWeight={600}>
          {credits}
        </Text>
      </HStack>
      {isFreePlanUser ? (
        <Popover
          closeOnBlur={true}
          isOpen={isOpen}
          onClose={onClose}
          placement={isMobile ? 'bottom' : 'right'}
        >
          <PopoverTrigger>
            <Button bg="transparent" onClick={handleTogglePopover} p={0}>
              <IconInfo />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <Text>Credits will reset in {timeLabel}</Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : null}
    </HStack>
  );
};

export default CreditsPill;
