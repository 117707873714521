import { Icon } from '@chakra-ui/react';

const IconAblo = (props) => (
  <Icon
    width="41px"
    height="26px"
    viewBox="0 0 49 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9761 0.272461H2.43251L0 30.2625H4.89432L5.55129 22.5227H7.90125L8.60072 30.2625H13.3661L10.9761 0.272461ZM6.62101 19.2639C5.69441 19.2639 4.94317 18.3693 4.94317 17.266C4.94317 16.1626 5.69441 15.2681 6.62101 15.2681C7.54761 15.2681 8.29885 16.1626 8.29885 17.266C8.29885 18.3693 7.54761 19.2639 6.62101 19.2639Z"
      fill="black"
    />
    <path
      d="M13.3667 30.2625V0.272461H20.1929C22.0246 0.272461 23.3576 0.80349 24.1923 1.86555C25.0271 2.9276 25.4443 4.68006 25.4443 7.12232V8.43448C25.4443 9.84319 25.2318 10.9849 24.8073 11.8597C24.3828 12.7345 23.7361 13.308 22.8681 13.5808C24.0033 13.9216 24.7643 14.7196 25.1507 15.9747C25.5371 17.2299 25.7305 18.7608 25.7305 20.5673C25.7305 22.5099 25.5776 24.2083 25.2723 25.6624C24.967 27.1165 24.4136 28.2471 23.6125 29.0533C22.811 29.86 21.6709 30.2631 20.1924 30.2631H13.3662L13.3667 30.2625ZM18.2894 11.5695H19.3195C19.787 11.5695 20.0874 11.3537 20.2212 10.9221C20.3545 10.4905 20.4215 9.97348 20.4215 9.37149V6.35515C20.4215 5.38965 20.0639 4.90689 19.3483 4.90689H18.2894V11.5695ZM18.79 24.9801C20.0971 24.9801 20.7507 24.242 20.7507 22.7647V19.0161C20.7507 18.164 20.6432 17.4911 20.4288 16.9967C20.2144 16.5023 19.8109 16.2557 19.2194 16.2557H18.2894V24.9458C18.4989 24.9685 18.666 24.9801 18.79 24.9801Z"
      fill="black"
    />
    <path d="M25.7305 30.2625V0.272461H30.7679V25.1505H35.948V30.2625H25.7305Z" fill="black" />
    <path
      d="M42.1585 30.5356C40.1455 30.5356 38.6069 29.8115 37.5435 28.3632C36.4797 26.915 35.9478 24.8217 35.9478 22.0839V7.83864C35.9478 5.27132 36.4748 3.32286 37.5289 1.99383C38.583 0.664804 40.126 0 42.1585 0C44.1909 0 45.7335 0.664804 46.788 1.99383C47.8421 3.32286 48.3692 5.27132 48.3692 7.83864V22.0839C48.3692 24.8217 47.8372 26.915 46.7734 28.3632C45.7095 29.8115 44.1714 30.5356 42.1585 30.5356ZM42.2014 25.0148C42.9644 25.0148 43.3464 24.1348 43.3464 22.3736V7.73627C43.3464 6.25951 42.9742 5.52084 42.2303 5.52084C41.3906 5.52084 40.971 6.27638 40.971 7.78687V22.4073C40.971 23.3391 41.0663 24.0062 41.2573 24.4093C41.4478 24.8129 41.7628 25.0142 42.2019 25.0142L42.2014 25.0148Z"
      fill="black"
    />
  </Icon>
);

export default IconAblo;
