import { Icon } from '@chakra-ui/react';

const IconDown = () => (
  <Icon
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1179_17997)">
      <path
        d="M4 6.5L8 10.5L12 6.5"
        stroke="#6A6866"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1179_17997">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </Icon>
);

export default IconDown;
