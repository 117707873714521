import { Icon } from '@chakra-ui/react';

const SIZE = 10;

const IconAdd = () => (
  <Icon
    width={`${SIZE}px`}
    height={`${SIZE}px`}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.570312" y1="5" x2="9.57031" y2="5" stroke="#212529" />
    <line x1="5.07031" y1="0.5" x2="5.07031" y2="9.5" stroke="#212529" />
  </Icon>
);

export default IconAdd;
