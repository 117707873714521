import { Box } from '@chakra-ui/react';

type ToolbarContainerProps = {
  children: React.ReactNode;
};

const FadeBox = (props) => (
  <Box
    position="absolute"
    top={0}
    h="100%"
    background="linear-gradient(270deg, #FFF 38.64%, rgba(255, 255, 255, 0.00) 106.82%);"
    {...props}
    zIndex={0}
  />
);

const ToolbarContainer = ({ children, ...rest }: ToolbarContainerProps) => (
  <Box
    bg="#FFFFFF"
    boxShadow="0px 1px 2px 0px #0000000F"
    id="object-edit-tools"
    onClick={(e) => {
      e.stopPropagation();
    }}
    position="relative"
    {...rest}
  >
    {children}
    <FadeBox left={0} w="12px" />
    <FadeBox right={0} w="20px" />
  </Box>
);

export default ToolbarContainer;
