import { Flex, Show } from '@chakra-ui/react';

import { Redirect, Route, Switch } from 'react-router-dom';

import routes from './routes';

export default function Auth() {
  return (
    <Flex h="100vh" w="100%">
      <Switch>
        {routes.map((route, key) => {
          const { path, component } = route;

          return <Route path={`/auth${path}`} component={component} key={key} />;
        })}
        <Redirect from="/" to="/auth/signin" />
      </Switch>
      <Show above="md">
        <video
          autoPlay
          style={{
            height: '100vh',
          }}
          loop
          muted
          playsInline
          src="https://uploads-ssl.webflow.com/64a578c6af7743f274e283e2/64a5794d3dba19099746487a_ABLO_FP_v05_vertical-transcode.mp4"
        />
      </Show>
    </Flex>
  );
}
