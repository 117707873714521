import { fabric } from 'fabric-spacerunners';
import QRCodeSVG from 'qrcode-svg';

export const QR_CODE_TYPE = 'QRCode';

export const QRCode = fabric.util.createClass(fabric.Object, {
  type: QR_CODE_TYPE,
  text: '',
  size: 400,
  strokeWidth: 0,

  initialize(options = {}) {
    this.callSuper('initialize', options);
    this._createPathData();
  },

  _createPathData() {
    const qr = new QRCodeSVG({
      content: this.text || ' ',
      padding: 0,
      width: this.size,
      height: this.size,
      color: this.stroke,
      background: this.fill,
      ecl: 'M',
      join: true,
    });

    const svg = qr.svg();

    const match = svg.match(/<path[^>]*?d=(["'])?((?:.(?!\1|>))*.?)\1?/);
    const path = match ? match[2] : '';

    this.path = fabric.util.makePathSimpler(fabric.util.parsePath(path));

    return this;
  },

  _set(key, value) {
    this.callSuper('_set', key, value);

    if (['text', 'stroke'].includes(key)) {
      this._createPathData();
    }

    return this;
  },

  _renderQRCode(ctx) {
    let current = 0;
    let i = 0;
    let x = 0;
    let y = 0;

    const w2 = this.width / 2;
    const h2 = this.height / 2;

    ctx.beginPath();
    for (i = 0; i < this.path.length; i++) {
      current = this.path[i];
      x = current[1];
      y = current[2];

      switch (current[0]) {
        case 'M':
          ctx.moveTo(x - w2, y - h2);
          break;
        case 'L':
          ctx.lineTo(x - w2, y - h2);
          break;
        case 'Z':
          ctx.closePath();
          break;
        default:
          break;
      }
    }

    ctx.save();
    ctx.fillStyle = this.stroke;
    ctx.fill();
    ctx.restore();
  },

  _renderBackground(ctx) {
    const w = this.width;
    const h = this.height;
    const x = -(w / 2);
    const y = -(h / 2);
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y + h);
    ctx.lineTo(x, y + h);
    ctx.lineTo(x, y);
    ctx.closePath();
    ctx.save();
    ctx.fillStyle = this.fill;
    ctx.fill();
    ctx.restore();
  },

  _render(ctx) {
    this._renderBackground(ctx);
    this._renderQRCode(ctx);
  },

  toObject(propertiesToInclude = []) {
    return this.callSuper(
      'toObject',
      ['text', 'type', 'size', 'stroke'].concat(propertiesToInclude)
    );
  },
});

fabric.QRCode = QRCode;

fabric.QRCode.fromObject = function (object, callback, forceAsync) {
  return fabric.Object._fromObject(QR_CODE_TYPE, object, callback, forceAsync, 'text');
};
