import { Icon } from '@chakra-ui/react';

const CreateDesign = () => (
  <Icon
    width={{ base: '40px', md: '54px' }}
    height={{ base: '40px', md: '54px' }}
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="54" height="54" rx="12" fill="#E4ECFF" />
    <path
      d="M22.175 34.7493C21.1224 34.7493 20.219 34.483 19.4648 33.9503C18.7148 33.4134 18.1373 32.6399 17.7325 31.63C17.3319 30.6158 17.1317 29.3949 17.1317 27.9673C17.1359 26.5398 17.3383 25.3253 17.7389 24.3239C18.1437 23.3182 18.7211 22.5511 19.4711 22.0227C20.2254 21.4943 21.1267 21.2301 22.175 21.2301C23.2233 21.2301 24.1246 21.4943 24.8788 22.0227C25.6331 22.5511 26.2105 23.3182 26.6111 24.3239C27.0159 25.3295 27.2183 26.544 27.2183 27.9673C27.2183 29.3991 27.0159 30.6222 26.6111 31.6364C26.2105 32.6463 25.6331 33.4176 24.8788 33.9503C24.1288 34.483 23.2275 34.7493 22.175 34.7493ZM22.175 32.7486C22.9932 32.7486 23.6388 32.3459 24.1118 31.5405C24.589 30.7308 24.8277 29.5398 24.8277 27.9673C24.8277 26.9276 24.719 26.054 24.5017 25.3466C24.2844 24.6392 23.9775 24.1065 23.5812 23.7486C23.1849 23.3864 22.7162 23.2053 22.175 23.2053C21.3611 23.2053 20.7176 23.6101 20.2446 24.4197C19.7716 25.2251 19.5329 26.4077 19.5287 27.9673C19.5244 29.0114 19.6288 29.8892 19.8419 30.6009C20.0592 31.3125 20.366 31.8494 20.7623 32.2116C21.1586 32.5696 21.6295 32.7486 22.175 32.7486ZM34.6666 21.4091V34.5H32.2952V23.7166H32.2185L29.1567 25.6726V23.4993L32.4102 21.4091H34.6666Z"
      fill="#064AC4"
    />
  </Icon>
);

export default CreateDesign;
