import { getFormattedAmount } from '../utils';

export const INVOICE_COLUMNS = [
  {
    name: 'Invoice',
    property: 'invoice',
  },
  {
    name: 'Billing date',
    property: 'billingDate',
  },
  {
    name: 'Plan',
    property: 'plan',
  },
  {
    name: 'Amount Paid',
    property: 'amountPaid',
    formatter: (row) => getFormattedAmount(row.currency, row.amountPaid),
  },
  {
    name: 'Amount Due',
    property: 'amountDue',
    formatter: (row) => getFormattedAmount(row.currency, row.amountDue),
  },
];
