import { Icon } from '@chakra-ui/react';

const IconVote = (props) => (
  <Icon
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8059 13.4094L12.5426 2.35937L2.2793 13.4094L7.87094 13.4094L7.87094 22.2175L17.3945 22.2175L17.3945 13.4094L22.8059 13.4094Z"
      fill="black"
    />
  </Icon>
);

export default IconVote;
