import {
  Center,
  HStack,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
} from '@chakra-ui/react';

import Button from '../buttons/ButtonCTA';

const IconCheckCircle = () => (
  <Icon
    width="43px"
    height="43px"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5008 38.7001C26.0625 38.7001 30.4374 36.8879 33.663 33.6623C36.8886 30.4367 38.7008 26.0618 38.7008 21.5C38.7008 16.9383 36.8886 12.5634 33.663 9.33781C30.4374 6.11219 26.0625 4.30005 21.5008 4.30005C16.9391 4.30005 12.5642 6.11219 9.33854 9.33781C6.11292 12.5634 4.30078 16.9383 4.30078 21.5C4.30078 26.0618 6.11292 30.4367 9.33854 33.6623C12.5642 36.8879 16.9391 38.7001 21.5008 38.7001ZM29.4708 18.7201C29.8625 18.3146 30.0792 17.7715 30.0743 17.2078C30.0694 16.6441 29.8433 16.1048 29.4446 15.7062C29.046 15.3076 28.5068 15.0814 27.943 15.0765C27.3793 15.0717 26.8362 15.2884 26.4307 15.68L19.3508 22.76L16.5708 19.98C16.1653 19.5884 15.6222 19.3717 15.0585 19.3766C14.4948 19.3814 13.9555 19.6076 13.5569 20.0062C13.1583 20.4048 12.9322 20.9441 12.9273 21.5078C12.9224 22.0715 13.1391 22.6146 13.5307 23.0201L17.8307 27.3201C18.2339 27.7232 18.7807 27.9496 19.3508 27.9496C19.9209 27.9496 20.4676 27.7232 20.8708 27.3201L29.4708 18.7201Z"
      fill="#064AC4"
    />
  </Icon>
);

type Props = {
  onClose: () => void;
};

const PlanUpdatedModal = ({ onClose }: Props) => {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="20px" maxWidth="772px" maxHeight="426px">
        <ModalBody padding={0}>
          <Center
            bg="linear-gradient(105deg, #CEEDFF 16.17%, rgba(255, 255, 255, 0.00) 87.53%)"
            borderTopLeftRadius={20}
            h="203px"
          >
            <HStack>
              <IconCheckCircle />
              <Text as="b" color="black.600" fontSize="32px">
                Awesome
              </Text>
            </HStack>
          </Center>
          <Flex align="center" direction="column" padding="16px 22px 69px 22px">
            <Text color="black.500" fontSize="sm" mb="38px" textAlign="center" w="466px">
              You have successfully updated your subscription and your current plan will be
              reflected now
            </Text>
            <Button onClick={onClose} w="135px" fontSize="xs">
              Back To Dashboard
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlanUpdatedModal;
