import AppContainer from '@/layouts/AppContainer';
import ProductDetails from './components/ProductDetails';
import { useMe } from '@/api/auth';

const ProductDetailsPage = () => {
  const { data: me } = useMe();

  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      <ProductDetails me={me} />
    </AppContainer>
  );
};

export default ProductDetailsPage;
