import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ReactNode } from 'react';
import IconCloseModal from '../icons/IconCloseModal';

interface ModalContainerProps {
  children: ReactNode;
  onClose: () => void;
  width?: { base: number | string; md: number | string };
}

const ModalContainer = ({ onClose, children, width }: ModalContainerProps) => (
  <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
    <ModalOverlay background-color="rgba(27, 52, 97, 0.3)" background-blend-mode="multiply" />
    <ModalContent
      alignSelf="center"
      borderRadius={{ base: '14px', md: '24px' }}
      p={0}
      overflow="auto"
      maxH={{ base: 'none', md: 'calc(100vh - 80px)' }}
      h={{ base: 'calc(100vh - 100px)', md: 'auto' }}
      maxW={{ base: 'calc(100% - 16px)', md: '580px' }}
      width={width}
    >
      <ModalCloseButton top={{ base: '8px', md: '20px' }} right={{ base: '7px', md: '24px' }}>
        <IconCloseModal />
      </ModalCloseButton>
      <ModalBody padding={{ base: '20px 16px', md: '28px 32px' }}>{children}</ModalBody>
    </ModalContent>
  </Modal>
);

export default ModalContainer;
