import { HStack, Text, VStack } from '@chakra-ui/react';

import AlertWarning from './icons/AlertWarning';

const NoReturnsBanner = () => (
  <HStack align="flex-start" bg="#FEEBCB" p="12px 16px" w="100%">
    <AlertWarning />
    <VStack align="flex-start" spacing={0}>
      <Text color="#C05621" fontWeight={700} textStyle="caption">
        No Returns for any Customized Items
      </Text>
      <Text color="#2D3748" textStyle="overline">
        Orders made with ABLO are unique and cannot be returned
      </Text>
    </VStack>
  </HStack>
);

export default NoReturnsBanner;
