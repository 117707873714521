import { Icon } from '@chakra-ui/react';

const IconGraz = () => (
  <Icon
    width={{ base: '99px', md: '150px' }}
    height={{ base: '32px', md: '48px' }}
    viewBox="0 0 150 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="48" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.3678 20.1042H58.2508C58.2508 20.1042 57.788 15.9775 52.9853 15.1742C48.5372 14.4286 46.4935 16.2128 45.4813 17.9358C44.8152 19.0697 43.6643 25.5015 45.4607 29.5345L49.0623 28.672C48.6997 28.2683 48.4213 27.7535 48.2427 27.139C47.8123 25.6442 46.9371 19.0608 50.6314 18.3908C51.7843 18.1819 53.5166 18.5169 54.3678 20.1042ZM49.521 29.0907C50.3769 29.726 51.5499 29.8948 52.9155 29.5056C53.8578 29.237 54.3382 28.585 54.5769 27.9074L58.2318 27.0521C57.6468 32.4605 53.9289 33.6227 49.2333 32.727C47.5427 32.4041 46.4122 31.3716 45.6815 29.9892L49.521 29.0907ZM58.2507 26.4716L54.7299 27.3148C54.8641 26.549 54.7609 25.8891 54.7609 25.8891L51.7987 25.8243V22.7385L58.2616 22.6737L58.2507 26.4716ZM60.3063 26.5666V32.7234H63.9465V25.9131H66.2967L70.3974 32.7234H74.6362L70.074 25.9131C70.074 25.9131 72.025 25.2964 73.0888 23.5752L60.3063 26.5666ZM73.4591 22.8296L60.3063 25.9794V15.2403C60.3063 15.2403 67.2464 14.8801 69.5222 15.2403C73.3054 15.8406 73.8091 17.6764 73.761 21.2197C73.7528 21.8238 73.6402 22.3584 73.4591 22.8296ZM79.6766 22.0334L75.2964 32.7234H79.1061L80.188 30.029H87.0669L88.2042 32.7234H92.0139L86.9358 20.3346L83.5664 21.1231L85.9849 26.9061H81.3794L83.5584 21.125L79.6766 22.0334ZM86.6624 19.6677L79.9914 21.2652L82.4602 15.2403H84.8477L86.6624 19.6677ZM93.6809 18.756L101.007 18.7895L93.3845 29.8393V32.7234H105.5V29.6448H97.3769L105.165 18.7547V15.2483L105.112 16.0807L93.6809 18.756ZM105.165 15.2368L93.3845 18.0579V15.2367H105.165V15.2368ZM68.7383 22.4624H63.8551V18.1819C63.8551 18.1819 67.5205 17.8866 68.6939 18.1819C70.0163 18.5145 70.5368 19.7932 70.2134 21.0347C70.1267 21.3871 69.9423 21.7079 69.6814 21.9604C69.4205 22.2129 69.0937 22.3869 68.7383 22.4624Z"
      fill="black"
    />
  </Icon>
);

export default IconGraz;
