import { H5 } from '@/components/typography/Headings';
import {
  Box,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from '@chakra-ui/react';

export interface AbloSliderProps {
  defaultValue: number;
  min: number;
  max: number;
  step: number;
  margin: string;
  height: string;
  onChange: (val: number) => void;
  value: number;
  width: string;
  shouldIncludeBox?: boolean;
  label?: string;
}

export const AbloSlider = ({
  defaultValue,
  min = 0,
  max,
  step = 1,
  margin = '0',
  height = '2px',
  onChange,
  value,
  width = 'auto',
  shouldIncludeBox = true,
  label = undefined,
}) => {
  return (
    <VStack align="left" mt="16px">
      {label && (
        <H5 color="#1A1A1A" fontWeight={700}>
          {label}
        </H5>
      )}
      <HStack>
        <Slider
          defaultValue={defaultValue}
          min={min}
          max={max}
          step={step}
          margin={margin}
          height={height}
          onChange={onChange}
          value={value}
          width={width}
        >
          <SliderTrack bg="gray.200" height={height}>
            <SliderFilledTrack bg="brand.600" />
          </SliderTrack>
          <SliderThumb boxSize="20px" bg="white" border="2px solid #064AC4">
            <Box borderRadius="50%" bg="white" boxSize="10px" />
          </SliderThumb>
        </Slider>

        {shouldIncludeBox && (
          <Box
            border="1px solid var(--Gray-300, #CBD5E0)"
            borderRadius="6px"
            padding="8px"
            ml="8px"
          >
            <Text fontWeight="600" fontSize="14px">
              {value}
            </Text>
          </Box>
        )}
      </HStack>
    </VStack>
  );
};
