import IconArrowRight from '@/lib/components/icons/IconArrowRight';
import { Button, Flex, Text } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

interface SubNavbarProps {
  title: string;
}

const SubNavbar = ({ title }: SubNavbarProps) => {
  const history = useHistory();

  return (
    <Flex
      alignItems="center"
      bg="#FFFFFF"
      borderBottom="1px solid #E7E7E7"
      height="56px"
      justify="center"
      p="18px 12px"
      position="relative"
    >
      <Button
        h="100%"
        onClick={() => history.goBack()}
        padding={0}
        position="absolute"
        left="2px"
        variant="ghost"
      >
        <IconArrowRight transform="rotate(180deg)" />
      </Button>
      <Text textStyle="button">{title}</Text>
    </Flex>
  );
};

export default SubNavbar;
