import { useState } from 'react';

import { Flex, Link, Text, VStack } from '@chakra-ui/react';

import { GoogleLogin } from '@react-oauth/google';

import { googleLogin, login } from '@/api/auth';

import FormInput from '../components/FormInput';
import Button from '../components/ButtonCTA';
import ErrorMessage from '../components/ErrorMessage';
import { handleSignIn } from '../utils';
import { Event } from '../../../analytics/events';
import OrLine from '../components/OrLine';

interface SignInFormProps {
  onSignedIn: () => void;
  onGoToSignUp: () => void;
}

function SignIn({ onGoToSignUp, onSignedIn }: SignInFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleError = (error) => {
    const { response } = error;

    let message;

    if (response.status === 401) {
      message =
        response?.data?.message ||
        'Your email or password are incorrect. Please try again! If you’re still having trouble contact ablo@spacerunners.com to request for assistance';
    } else {
      message = 'Error signing in';
    }

    setError(message);
  };

  const handleSubmit = () => {
    setWaiting(true);

    login(email, password)
      .then(({ access_token: accessToken, user }) => {
        handleSignIn(Event.LOGGED_IN, user, accessToken, false);

        onSignedIn();
      })
      .catch((error) => {
        handleError(error);

        setWaiting(false);
      });
  };

  return (
    <VStack mb="20px" mt="20px" spacing="24px" maxW="350px">
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          googleLogin(credentialResponse.credential)
            .then(({ access_token: accessToken, user }) => {
              handleSignIn(Event.LOGGED_IN, user, accessToken, true);

              onSignedIn();
            })
            .catch(handleError);
        }}
        width="340px"
      />
      <OrLine />
      <FormInput
        autoComplete="new-password"
        name="Email Address"
        type="email"
        placeholder="Example@mail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormInput
        autoComplete="new-password"
        isPassword
        name="Password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error ? <ErrorMessage message={error} /> : null}
      <Flex justify="flex-end" w="100%">
        <Link color="brand.600" fontSize="sm" fontWeight={500} href="/auth/forgot-password">
          Forgot password?
        </Link>
      </Flex>
      <Button isLoading={waiting} onClick={handleSubmit} w="100%">
        Sign In
      </Button>
      <Flex align="center" w="100%">
        <Text color="#6C757D" fontSize="sm" fontWeight={400}>
          Not registered yet?
        </Text>
        <Link color="brand.600" fontSize="sm" fontWeight={400} onClick={onGoToSignUp} ml="4px">
          Create an account
        </Link>
      </Flex>
    </VStack>
  );
}

export default SignIn;
