import {
  Button as ChakraButton,
  Box,
  Center,
  Hide,
  Text,
  Stack,
  Show,
  useToast,
  HStack,
  Flex,
  Popover,
  PopoverTrigger,
  useDisclosure,
  PopoverContent,
  PopoverBody,
  useBreakpointValue,
} from '@chakra-ui/react';

import CreatorAvatar from './components/CreatorAvatar';
import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import Button from '@/components/button';
import IconShare from '@/components/icons/IconShare';
import { DesignVote } from '@/components/types';
import { isEmpty } from 'lodash';
import RemixPreviewImage from '../demo/components/remix/RemixPreviewImage';
import IconCopy from '../demo/pages/remix-submitted/icons/IconCopy';
import VoteButton from '@/components/remix/VoteButton';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { H3, H4 } from '@/components/typography/Headings';

const getShareLink = (designId) => `${window.location.origin}/designs/${designId}`;

const getShareContent = (designId) => ({
  title: 'Check out this awesome remix I created on Ablo.ai',
  text: 'You can design on top of my design on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
  url: getShareLink(designId),
});

interface RemixDetailsProps {
  designId: string;
  hasVotingEnded?: boolean;
  isVoting: boolean;
  onBack?: () => void;
  onVote: (designId: string) => void;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign?: string;
}

export default function RemixDetails({
  designId,
  designVotes = {},
  hasVotingEnded,
  isVoting,
  onVote,
  votedForDesign,
}: RemixDetailsProps) {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const toast = useToast();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const hasVoted = !isEmpty(designVotes);

  const hasVotedForDesign = designVotes[designId]?.length;

  const canShare = navigator.share && navigator.canShare(getShareContent(designId));

  const shareLink = getShareLink(designId);

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!votedForDesign || votedForDesign !== designId || !design) {
      return;
    }

    setDesign({ ...design, numVotes: design.numVotes + 1 });
  }, [votedForDesign]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);

      toast({
        title: 'Link copied to clipboard',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error copying link to clipboard',
        description: error.message,
        status: 'success',
      });
    }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Check out this awesome design I created on Ablo.ai',
        text: 'You can design on top of my design on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
        url: shareLink,
      });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error?.message,
        status: 'error',
      });
    }
  };

  const { description, name, numVotes, user } = design || {};

  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isLoading) {
    return (
      <Center bg="transparent" h="100%">
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Box bg="#FFFFFF" borderRadius="12px" h="100%" overflow="auto">
      <Stack
        align="center"
        direction={{ base: 'column', md: 'row' }}
        alignItems="flex-start"
        h="100%"
        p={0}
      >
        {hasVotedForDesign ? (
          <Hide above="md">
            <Box p={{ base: '28px 16px 16px 16px', md: 0 }}>
              <H4>Congrats! Your vote is submitted!</H4>
            </Box>
          </Hide>
        ) : null}
        <Center
          bg="#EAEAEA"
          borderRadius={{ base: 0, md: '12px' }}
          h="100%"
          w={{ base: '100%', md: '528px' }}
        >
          <RemixPreviewImage design={design} />
        </Center>
        <Box
          bg="#FFFFFF"
          h="100%"
          p={{ base: '9px 16px 80px 16px', md: '20px' }}
          w={{ base: '100%', md: 'calc(100% - 536px)' }}
        >
          <Show above="md">
            <H3 mb="34px">
              {hasVotedForDesign
                ? 'Congrats! Your vote is submitted!'
                : hasVoted
                ? ''
                : 'Vote to win free merch!'}
            </H3>
          </Show>
          <Text as="b" mb="8px" textStyle="subtitle">
            {name}
          </Text>
          <CreatorAvatar user={user} />
          <Text mt="12px" textStyle="body">
            {description}
          </Text>
          <Box h="1px" bg="#CBD5E0" mb="12px" mt="6px" w="100%" />
          <Flex justify="space-between">
            <HStack spacing="2px">
              <Text color="#959CA4" textStyle="caption">
                Total votes:
              </Text>
              <Text as="b" textStyle="caption">
                {numVotes}
              </Text>
            </HStack>
            <Popover
              closeOnBlur={true}
              isOpen={isOpen}
              onClose={onClose}
              placement={isMobile ? 'bottom' : 'right'}
            >
              <PopoverTrigger>
                <ChakraButton onClick={() => onToggle()} variant="link">
                  How does voting work?
                </ChakraButton>
              </PopoverTrigger>
              <PopoverContent
                boxShadow="0px 4px 20px 0px rgba(0, 52, 139, 0.15)"
                borderRadius="14px"
              >
                <PopoverBody p="18px 27px">
                  <Text fontWeight={500} textStyle="caption">
                    Win Free Merch, if your voted design wins. One vote per collection. Ticket can't
                    be transferred.
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
          <VoteButton
            hasVoted={!isEmpty(designVotes)}
            hasVotingEnded={hasVotingEnded}
            isLoading={isVoting}
            m="12px 0"
            maxW="416px"
            onClick={() => onVote(design.id)}
            w="100%"
            h="50px"
          />
          {canShare ? (
            <Hide above="lg">
              <Button secondary w="100%" onClick={handleShare}>
                <IconShare color="#000000" mr="8px" />
                Share
              </Button>
            </Hide>
          ) : null}
          <Show above="lg">
            <ChakraButton
              color="brand.600"
              leftIcon={<IconCopy />}
              variant="ghost"
              onClick={handleCopyLink}
              w="100%"
            >
              Copy Link
            </ChakraButton>
          </Show>
        </Box>
      </Stack>
    </Box>
  );
}
