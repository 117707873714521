import { Icon } from '@chakra-ui/react';

const IconCustomArtStyleActive = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3551 9.99982C18.3551 14.6143 14.6143 18.3551 9.99982 18.3551C5.38532 18.3551 1.64453 14.6143 1.64453 9.99982C1.64453 5.38532 5.38532 1.64453 9.99982 1.64453C14.6143 1.64453 18.3551 5.38532 18.3551 9.99982ZM9.25006 14.4445V10.75H5.55566V9.25H9.25006V5.55566H10.7501V9.25H14.4445V10.75H10.7501V14.4445H9.25006Z"
      fill="black"
    />
  </Icon>
);

export default IconCustomArtStyleActive;
