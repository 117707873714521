import { useState } from 'react';

import { Alert, AlertIcon, Box, Text, VStack } from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';

import { resetPassword } from '@/api/auth';
import FormInput from '@/components/form/FormInput';

import Button from '../components/ButtonCTA';
import AuthContainer from '../components/AuthContainer';
import ErrorMessage from '../components/ErrorMessage';
import IconBack from '@/lib/components/icons/IconBack';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  const [error, setError] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = () => {
    setWaiting(true);
    setSuccess(false);

    resetPassword(email)
      .then(() => {
        setWaiting(false);
        setSuccess(true);
      })
      .catch(() => {
        setError('Error resetting password');

        setWaiting(false);
      });
  };

  return (
    <AuthContainer
      title="Forgot Password"
      subtitle="No worries, we’ll send you reset instructions."
    >
      <VStack mb="20px" mt="20px" spacing="24px" maxW="350px" pt="32px">
        {success ? (
          <Alert height="60px" status="success" textAlign="left" w="350px">
            <AlertIcon />
            We have sent a verification email with instructions. Check your inbox.
          </Alert>
        ) : null}
        <FormInput
          name="Email Address"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error ? <ErrorMessage message={error} /> : null}
        <Box>
          <Button isLoading={waiting} onClick={handleSubmit} w="100%">
            Reset password
          </Button>
          <Button
            bg="transparent"
            borderRadius="14px"
            border="1px solid #CBD5E0"
            fontSize="sm"
            h="50px"
            mt="20px"
            outlined
            onClick={() => history.push('/auth/signin')}
            padding="13px 50px"
            w="100%"
          >
            <IconBack />
            <Text color="#171923" fontWeight={600} ml="4px">
              Back to Login
            </Text>
          </Button>
        </Box>
      </VStack>
    </AuthContainer>
  );
}

export default ForgotPassword;
