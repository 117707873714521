import { Button, HStack, Hide, Link, Show, Text, VStack } from '@chakra-ui/react';

import FormField from '../FormField';
import QRCodeInstructionsModal from './qr-code-instructions-modal';
import { useState } from 'react';

interface QRCodeFormProps {
  link: string;
  onChange: (link: string) => void;
  onNext: () => void;
}

const QRCodeForm = ({ link, onChange, onNext }: QRCodeFormProps) => {
  const [isQRCodeInstructionsModalVisible, setQRCodeInstructionsModalVisible] = useState(false);

  return (
    <VStack align="flex-start" padding="14px" spacing="12px">
      <Show above="md">
        <VStack>
          <Text as="b" color="black.600" fontSize="md">
            Create your unique story
          </Text>
        </VStack>
      </Show>
      <HStack align="flex-start" w="100%">
        <FormField
          isRequired
          error={
            link?.length >= 50 && (
              <>
                URL is too long. Please use a service like{' '}
                <Link href="http://tinyurl.com" isExternal>
                  tinyurl.com
                </Link>{' '}
                to shorten it.
              </>
            )
          }
          name="QR code link (required)"
          onInfoClick={() => setQRCodeInstructionsModalVisible(true)}
          placeholder="QR code link (Less than 50 characters) (Required)"
          value={link}
          onChange={onChange}
        />
        <Hide above="md">
          <Button
            bg="brand.600"
            color="#FFFFFF"
            fontSize="14px"
            fontWeight={400}
            onClick={onNext}
            borderRadius="6px"
            p="6px 20px"
            w="100px"
          >
            Next
          </Button>
        </Hide>
      </HStack>
      {isQRCodeInstructionsModalVisible ? (
        <QRCodeInstructionsModal onClose={() => setQRCodeInstructionsModalVisible(false)} />
      ) : null}
    </VStack>
  );
};

export default QRCodeForm;
