import { identifyUser, trackEvent } from '../../analytics';
import { Event } from '../../analytics/events';
import { User } from '../../components/types';
import { StorageKeys } from '../../constants';

export const handleSignIn = (
  event: Event,
  user: User,
  accessToken: string,
  isFromGoogle = false
) => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken);

  // Use oldest active API key
  const key =
    user.client.apiKeys
      .filter((apiKey) => apiKey.isActive && !apiKey.isDeleted)
      .sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      })[0]?.key || user.client.apiKeys[0].key;

  localStorage.setItem(StorageKeys.API_KEY, key);

  identifyUser(user);
  trackEvent(event, { isFromGoogle });
};
