import { Icon } from '@chakra-ui/react';

export const IconCompress = () => (
  <Icon
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5669 7.02947L19.9104 0.769287L21.3153 2.19282L14.9915 8.43352L18.5613 8.44772L18.5534 10.4477L11.5534 10.4199L11.5813 3.41992L13.5813 3.42788L13.5669 7.02947Z"
      fill="black"
    />
    <path
      d="M6.97873 13.5429L3.4088 13.5457L3.40723 11.5457L10.4072 11.5402L10.4127 18.5402L8.41271 18.5417L8.40989 14.9402L2.09616 21.2306L0.68457 19.8138L6.97873 13.5429Z"
      fill="black"
    />
  </Icon>
);
