import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import CreateOrEditTemplate from './create-template';
import TemplateList from './TemplateList';

import Button from '@/components/button';
import AppContainer from '@/layouts/AppContainer';

export default function TemplateLibrary() {
  const history = useHistory();

  const location = useLocation();

  const handleAddNewTemplate = () => history.push('/template-library/new');

  const { pathname } = location;

  const subPage = pathname.split('/')[2];

  return (
    <AppContainer
      rightSideNavbarContent={
        subPage ? null : <Button onClick={handleAddNewTemplate}>+ Add New Template</Button>
      }
    >
      <Switch>
        <Route path="/template-library/new" component={CreateOrEditTemplate} />
        <Route path="/template-library/edit/:id" component={CreateOrEditTemplate} />
        <Route path="/template-library" component={TemplateList} />
      </Switch>
    </AppContainer>
  );
}
