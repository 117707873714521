import axios from 'axios';

import { useQuery } from '@tanstack/react-query';
import { ablo } from './ablo-wrapper';
import { ICreateCustomStyleRequest } from '@space-runners/ablo-ts-sdk';

export interface CreateCustomStyleRequest {
  name: string;
  coverImage: File;
  trainingData: Blob;
}

const entity = 'styles';

const CUSTOM = 'custom';

const getCustomStyles = async () => {
  const response = await ablo().styles.getCustom();

  return response;
};

export const useCustomStyles = () => useQuery([entity, CUSTOM], () => getCustomStyles());

export const createCustomStyle = (params: ICreateCustomStyleRequest) =>
  axios
    .post(`/${entity}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
