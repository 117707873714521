import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useMe } from '../../api/auth';
import IconTableMenu from './icons/IconTableMenu';
import { MenuItem } from './MenuItem';

interface TableActionMenuProps {
  onDelete: () => void;
  onEdit: () => void;
}

export const TableActionMenu = ({ onDelete, onEdit }: TableActionMenuProps) => {
  const { data: me } = useMe();

  const isAdmin = me.roles.find((r) => ['admin', 'super_admin'].includes(r.name));

  return (
    <Menu placement="bottom">
      <MenuButton
        as={Button}
        bg="transparent"
        border="none"
        h="15px"
        rightIcon={<IconTableMenu />}
        minWidth="none"
        onClick={(e) => e.stopPropagation()}
        p={0}
        _focus={{
          bg: 'transparent',
        }}
        _hover={{
          bg: 'transparent',
        }}
        _active={{
          bg: 'transparent',
        }}
      />
      <MenuList
        bg="#FFFFFF"
        border="1px solid"
        borderColor="secondaryGray.300"
        minWidth="none"
        p={0}
        w="124px"
      >
        {isAdmin && onEdit && <MenuItem onClick={onEdit}>Edit</MenuItem>}
        {isAdmin && onDelete && <MenuItem onClick={onDelete}>Delete</MenuItem>}
      </MenuList>
    </Menu>
  );
};
