import { Flex, Hide, Show, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

import CreateYourStyleVideo from '../components/CreateYourStyleVideo';
import { H2 } from '@/components/typography/Headings';
import Button from '@/components/button';

interface CraftDesignsSectionProps {
  onAction: () => void;
}

const CraftDesignsSection = ({ onAction }: CraftDesignsSectionProps) => {
  const isMobile = useBreakpointValue({ base: true, '2sm': false });

  return (
    <Flex
      bg="#282C36"
      borderRadius={{ base: 0, '2sm': '24px' }}
      justify="space-between"
      p={{ base: '26px 15px 40px 15px', '2sm': '51px 65px', lg: '64px 78px' }}
      w="100%"
    >
      <VStack
        alignItems={{ base: 'flex-start', '2sm': 'center', lg: 'flex-start' }}
        justify="center"
        spacing={0}
        w={{ base: '100%', lg: '395px' }}
      >
        <H2 color="#FFFFFF" mb={{ base: '16px', md: '28px' }}>
          Craft unique designs
        </H2>
        <Hide above="lg">
          <CreateYourStyleVideo width={isMobile ? 'auto' : '640px'} />
        </Hide>
        <Text
          color="#FFFFFF"
          textStyle="body"
          fontWeight={600}
          mb="4px"
          mt={{ base: '22px', lg: 0 }}
        >
          Unlock your creativity with Ablo!
        </Text>
        <Text
          color="#FFFFFF"
          maxW={{ base: 'auto', '2sm': '507px', lg: 'auto' }}
          mb={{ base: '22px', md: '24px' }}
          opacity="50%"
          textAlign={{ base: 'left', '2sm': 'center', lg: 'left' }}
          textStyle="body"
        >
          Remix a collection with top brands or talented creators, or start from scratch and craft
          your own unique designs. Whether you’re blending iconic styles or inventing something
          entirely new, Ablo gives you the tools to bring your vision to life.{' '}
        </Text>
        <Text color="#FFFFFF" fontWeight={700} mb={{ base: '30px', md: '40px' }} textStyle="body">
          Your style, your rules!
        </Text>
        <Button onClick={onAction} w={{ base: '100%', '2sm': '379px', lg: '200px' }}>
          Start Designing
        </Button>
      </VStack>
      <Show above="lg">
        <CreateYourStyleVideo width="440px" />
      </Show>
    </Flex>
  );
};

export default CraftDesignsSection;
