import { Icon } from '@chakra-ui/react';

const IconChevronDown = ({ color = null, ...rest }) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.75 9.75L12 14.25L16.25 9.75"
      stroke={color || '#B7B7B7'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconChevronDown;
