import { HStack, VStack, useToast } from '@chakra-ui/react';

import { useUpdateClient } from '@/api/clients';
import Button from '@/components/button';
import FormInput from '@/components/form/FormInput';
import { Client, User } from '@/components/types';

import FormCard from './FormCard';
import { useEffect, useState } from 'react';

type AccountSettingsProps = {
  me: User;
};

export default function AccountSettings({ me }: AccountSettingsProps) {
  const [client, setClient] = useState<Partial<Client>>(null);
  const [isSaving, setIsSaving] = useState(false);

  const { updateClient } = useUpdateClient();

  const toast = useToast();

  const { id, name, address, domain } = client || {};

  const handleUpdate = (updates: Partial<Client>) => {
    setClient({ ...client, ...updates });
  };

  const handleSave = () => {
    setIsSaving(true);

    updateClient(
      { id, name, address, domain },
      {
        onSuccess: () => {
          toast({
            title: 'Changes saved',
            status: 'success',
          });

          setIsSaving(false);
        },
        onError: () => {
          toast({
            title: 'Error updating client',
            status: 'error',
          });

          setIsSaving(false);
        },
      }
    );
  };

  useEffect(() => {
    setClient(me?.client);
  }, [me]);

  return (
    <FormCard description="Here you can change your company information" title="Company Info">
      <VStack align="flex-start" spacing="35px" w="100%">
        <HStack spacing="38px" w="100%">
          <FormInput
            isOptional
            name="Name"
            value={name || ''}
            onChange={(e) => handleUpdate({ name: e.target.value })}
          />
          <FormInput
            isOptional
            name="Address"
            value={address || ''}
            onChange={(e) => handleUpdate({ address: e.target.value })}
          />
        </HStack>
        <FormInput
          name="Domain"
          value={domain || ''}
          onChange={(e) => handleUpdate({ domain: e.target.value })}
        />
        <Button isLoading={isSaving} onClick={handleSave}>
          Save changes
        </Button>
      </VStack>
    </FormCard>
  );
}
