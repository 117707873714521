import { Box, Card as CardBase, CardBody, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { chunk } from 'lodash';

import { useCurrencies } from '@/api/currencies';
import { useSizes } from '@/api/sizes';
import { Template, TemplateColor, TemplateSide } from '@/components/types';

import FormContainer from '../components/FormContainer';
import { getScalingFactor } from '../utils';
import { useEffect, useState } from 'react';

import { getTemplateSideWithPreviewImage } from '@/lib/utils/template-preview';
import Checkbox from '@/components/ui/checkbox';

const IMAGE_WIDTH = 300;

const getProperties = (template, currencies = [], sizes = []) => {
  const { currencyId, fabric, fit, madeIn, name, price, sizeIds = [] } = template;

  return [
    {
      name: 'Template Name',
      value: name,
    },
    {
      name: 'Price',
      value: `${currencies.find(({ id }) => id === currencyId)?.name || ''} ${price || ''}`,
    },
    {
      name: 'Made',
      value: madeIn,
    },
    {
      name: 'Fabric',
      value: fabric,
    },
    {
      name: 'Fit',
      value: fit,
    },
    {
      name: 'Sizes',
      value: sizeIds.map((id) => sizes.find((size) => size.id === id)?.name).join(', '),
    },
  ];
};

type CardProps = {
  children: React.ReactNode;
  title: string;
  w?: string;
};

const Card = ({ children, title, ...rest }: CardProps) => (
  <CardBase
    bg="#FFFFFF"
    borderRadius="16px"
    boxShadow="0px 18px 40px 0px rgba(112, 144, 176, 0.12)"
    w="345px"
    {...rest}
  >
    <CardBody p="12px 22px">
      <Text color="secondaryGray.600" fontSize="sm">
        {title}
      </Text>
      {children}
    </CardBody>
  </CardBase>
);

type PreviewProps = {
  onNext: () => void;
  colorVariants: TemplateColor[];
  sides: TemplateSide[];
  template: Template;
  waiting: boolean;
};

const Preview = ({
  colorVariants = [],
  sides: originalSides = [],
  onNext,
  template,
  waiting,
}: PreviewProps) => {
  const { data: currencies } = useCurrencies();
  const { data: sizes } = useSizes();

  const [selectedSideIndex, setSelectedSideIndex] = useState(0);
  const [sides, setSides] = useState<TemplateSide[]>(originalSides);

  const { previewImages = [] } = colorVariants[0] || {};

  useEffect(() => {
    const colors = colorVariants.map((color) => ({
      ...color,
      images: Object.keys(color.images).map((key) => {
        const colorImage = color.images[key];

        if (colorImage?.id && colorImage?.url) {
          return colorImage;
        }

        return {
          url: colorImage,
          templateSideId: key,
        };
      }),
    }));

    Promise.all(
      originalSides.map((side) =>
        getTemplateSideWithPreviewImage(
          {
            colors,
            sides,
          },
          side
        )
      )
    ).then((sidesWithPreviewImages) => setSides(sidesWithPreviewImages));
  }, []);

  const properties = getProperties(template, currencies, sizes);

  const chunks = chunk(properties, 2);

  const selectedSide = sides[selectedSideIndex];

  const { left, top, width, height } = selectedSide || {};

  const scalingFactor = getScalingFactor(IMAGE_WIDTH);

  const images = colorVariants[0]?.images;

  return (
    <FormContainer
      description="Here are the details of the template"
      nextDisabled={false}
      nextLabel="Save"
      onNext={onNext}
      title="Preview"
      waiting={waiting}
    >
      <VStack mt="24px" spacing="20px" w="700px">
        <HStack align="flex-start" w="100%" justify="space-between">
          <VStack alignItems="flex-start" spacing="5px">
            <Box position="relative">
              <Image
                src={
                  selectedSide?.previewImage ||
                  (template.id
                    ? images[selectedSide.id]?.image || images[selectedSide.id]?.url
                    : images && images[selectedSide?.name]) ||
                  null
                }
                w={`${IMAGE_WIDTH}px`}
              />
              <Box
                border="1px dashed"
                borderColor="gray.600"
                left={`${left * scalingFactor}px`}
                top={`${top * scalingFactor}px`}
                position="absolute"
                w={`${width * scalingFactor}px`}
                h={`${height * scalingFactor}px`}
              />
            </Box>
            <HStack>
              {sides.map((side, index) => (
                <Box
                  as="b"
                  border="1px dashed #E0E5F2"
                  cursor="pointer"
                  key={index}
                  onClick={() => setSelectedSideIndex(index)}
                >
                  <Image
                    src={
                      side?.previewImage ||
                      (template.id
                        ? images[side.id]?.image || `${images[side.id]?.url}?t=${Date.now()}`
                        : images[side.name])
                    }
                    w="82px"
                  />
                </Box>
              ))}
            </HStack>
          </VStack>
          <VStack spacing="24px">
            {chunk(previewImages, 4).map((row, index) => (
              <HStack justify="flex-start" key={index} w="100%">
                {row.map((previewImage) => (
                  <Box border="1px dashed #E0E5F2" borderRadius="6px" p="7px 13px">
                    <Image src={previewImage.url || previewImage.image} w="56px" />
                  </Box>
                ))}
              </HStack>
            ))}
            <Card title="Colors">
              <VStack align="flex-start" mt="12px" spacing="14px">
                {colorVariants.map(({ hex }) => (
                  <HStack spacing="14px" key={hex}>
                    <Box bg={hex} w="22px" h="22px" borderRadius="50%" />
                    <Text color="black.700" fontWeight={400} fontSize="md">
                      {hex}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </Card>
            <Card title="Sides">
              <VStack align="flex-start" mt="12px" spacing="14px">
                {sides.map(({ hasArea, name }) => {
                  return (
                    <HStack spacing="22px" key={name}>
                      <Text color="black.700" fontWeight={400} fontSize="md" width="60px">
                        {name}
                      </Text>
                      <Checkbox isChecked={hasArea}>Design Area</Checkbox>
                    </HStack>
                  );
                })}
              </VStack>
            </Card>
          </VStack>
        </HStack>
        {chunks.map((chunk, index) => (
          <HStack key={index} w="100%" spacing="24px">
            {chunk.map(({ name, value }, index) => (
              <Card key={index} title={name}>
                <Text color="black.700">{value || '-'}</Text>
              </Card>
            ))}
          </HStack>
        ))}
        <Card title="Description" w="100%">
          <Text color="black.700">{template.description}</Text>
        </Card>
      </VStack>
    </FormContainer>
  );
};

export default Preview;
