import { fabric } from 'fabric-spacerunners';

import { GARMENT_IMAGE_DESKTOP_WIDTH, GARMENT_IMAGE_MOBILE_WIDTH } from './drawingAreas';
import { scaleObjectTops } from './canvas-scaling';

const CANVAS_MAX_WIDTH = 2048;

const PERCENTAGE_OF_CONTAINER_HEIGHT = 0.7;

const DRAWING_AREA_ID = 'drawing-area';

export const initCanvas = (templateSide, isMobile: boolean, oldCanvas?) => {
  const canvasContainerElement = document.getElementById('#editor-container');

  const {
    left,
    top,
    width: artboardCssWidth,
    height: artboardCssHeight,
    name: sideName,
  } = templateSide;

  const { clientWidth: canvasCssWidth, clientHeight: canvasCssHeight } = canvasContainerElement;

  const aspectRatio = canvasCssWidth / canvasCssHeight;

  const width = aspectRatio > 1 ? CANVAS_MAX_WIDTH : CANVAS_MAX_WIDTH * aspectRatio;

  const height = width / aspectRatio;

  let canvas = oldCanvas;

  if (!canvas) {
    canvas = new fabric.Canvas(`canvas-${sideName}`, {
      controlsAboveOverlay: true,
      width,
      height,
      selection: false,
      disablePinchZoomOnObjects: true,
      renderOnAddRemove: true,
      preserveObjectStacking: true,
      enableRetinaScaling: false,
    });

    fabric.Object.prototype.activeOn = isMobile ? null : 'down';
  } else {
    canvas.setWidth(width);
    canvas.setHeight(height);
  }

  canvas.setDimensions(
    {
      height: `${canvasCssHeight}px`,
      width: `${canvasCssWidth}px`,
    },
    { cssOnly: true }
  );

  const scaleToFillContainer = isMobile
    ? 1
    : (canvasCssHeight * PERCENTAGE_OF_CONTAINER_HEIGHT * aspectRatio) /
      GARMENT_IMAGE_DESKTOP_WIDTH;

  const templateImageCssWidth = isMobile ? GARMENT_IMAGE_MOBILE_WIDTH : GARMENT_IMAGE_DESKTOP_WIDTH;

  const backgroundTemplateWidth =
    scaleToFillContainer * width * (templateImageCssWidth / canvasCssWidth);

  const artboardAspectRatio = artboardCssWidth / artboardCssHeight;

  const clipPathWidth = (artboardCssWidth / GARMENT_IMAGE_DESKTOP_WIDTH) * backgroundTemplateWidth;

  const clipPathHeight = clipPathWidth / artboardAspectRatio;

  const templateBasedTop =
    canvas.height / 2 -
    backgroundTemplateWidth / 2 +
    (top / GARMENT_IMAGE_DESKTOP_WIDTH) * backgroundTemplateWidth;

  const naturalTop = canvas.height / 2 - clipPathHeight / 2;

  const clipPath = new fabric.Rect({
    height: clipPathHeight,
    selectable: false,
    strokeWidth: 0,
    width: clipPathWidth,
    left:
      canvas.width / 2 -
      backgroundTemplateWidth / 2 +
      (left / GARMENT_IMAGE_DESKTOP_WIDTH) * backgroundTemplateWidth,
    templateBasedTop,
    naturalTop,
    top: naturalTop,
  });

  canvas.clipPath = clipPath;

  return canvas;
};

export const setBackgroundImage = (canvas, img, isMobile) => {
  const { clipPath, width, height, wrapperEl } = canvas;

  const { clientHeight, clientWidth } = wrapperEl;

  const aspectRatio = clientWidth / clientHeight;

  const imageWidth =
    (canvas.width *
      (isMobile
        ? GARMENT_IMAGE_MOBILE_WIDTH
        : clientHeight * PERCENTAGE_OF_CONTAINER_HEIGHT * aspectRatio)) /
    clientWidth;

  img.scaleToWidth(imageWidth);

  img.set({
    left: width / 2,
    top: height / 2,
    originX: 'center',
    originY: 'center',
    selectable: false,
    centeredScaling: true,
    erasable: false,
    excludeFromExport: true,
  });

  canvas.renderAll();

  const oldClipPath = { ...clipPath };

  canvas.clipPath.top = clipPath.templateBasedTop;

  scaleObjectTops(canvas, oldClipPath);

  canvas.setBackgroundImage(img).renderAll();

  img.clone((copy) => {
    const clipPath2 = new fabric.Rect({
      height: clipPath.height - 2,
      selectable: false,
      stroke: 'transparent',
      strokeWidth: 0,
      width: clipPath.width - 2,
      left: clipPath.left + 1,
      top: clipPath.top + 1,
      inverted: true,
      absolutePositioned: true,
      excludeFromExport: true,
    });

    copy.set({
      clipPath: clipPath2,
    });
    canvas.setOverlayImage(copy).renderAll();
  });
};

export const setBackgroundImageFromUrl = (canvas, imageUrl, isMobile?) => {
  if (!imageUrl) {
    canvas.setOverlayImage(null);
    canvas.setBackgroundImage(null);

    const { clipPath } = canvas;

    const oldClipPath = { ...clipPath };

    canvas.clipPath.top = clipPath.naturalTop;

    scaleObjectTops(canvas, oldClipPath);

    addDrawingArea(canvas);

    return;
  }

  fabric.Image.fromURL(
    imageUrl + '?v=' + new Date().getTime(), // Hack to get around CORS issue
    (img) => {
      setBackgroundImage(canvas, img, isMobile);
    }
  );
};

export const removeDrawingArea = (canvas) => {
  const drawingAreaElements = canvas._objects.filter(({ id }) => id === DRAWING_AREA_ID);

  drawingAreaElements.forEach((element) => {
    canvas.remove(element);
  });
};

export const addDrawingArea = (canvas) => {
  const { clipPath } = canvas;

  removeDrawingArea(canvas);

  const commonProperties = {
    id: DRAWING_AREA_ID,
    excludeFromExport: true,
    stroke: 'rgba(136, 136, 136, 0.5)',
    strokeWidth: 2,
    selectable: false,
  };

  const horizontalGridLine = new fabric.Line(
    [
      clipPath.left,
      clipPath.top + clipPath.height / 2,
      clipPath.left + clipPath.width,
      clipPath.top + clipPath.height / 2,
    ],
    { ...commonProperties }
  );

  const verticalGridLine = new fabric.Line(
    [
      clipPath.left + clipPath.width / 2,
      clipPath.top,
      clipPath.left + clipPath.width / 2,
      clipPath.top + clipPath.height,
    ],
    { ...commonProperties }
  );

  clipPath.clone((obj) => {
    obj.set({
      ...commonProperties,
      backgroundColor: 'transparent',
      left: clipPath.left,
      top: clipPath.top,
      fill: 'transparent',
      perPixelTargetFind: true,
      selectable: false,
      height: clipPath.height - 2,
      width: clipPath.width - 2,
    });

    canvas.add(horizontalGridLine);
    canvas.add(verticalGridLine);

    canvas.add(obj).renderAll();
    canvas.sendToBack(obj).renderAll();
  });
};
